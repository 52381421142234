import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import DeleteIcon from "@material-ui/icons/Delete";
import {IconButton} from "@material-ui/core";
import {deleteNotification} from "../../store/notification/actions";
import {createLoadingSelector} from "../../selectors/loading";
import {createErrorSelector} from "../../selectors/error";
import {DELETE_NOTIFICATION} from "../../store/notification/actionTypes";

interface NotificationDeleteProps {
    notification: OverviewNotification,
}

const NotificationDelete: FC<NotificationDeleteProps> = ({
    notification
}) => {
    const dispatch = useDispatch();

    const loadingSelector = useSelector(createLoadingSelector([DELETE_NOTIFICATION]));
    const errorSelector = useSelector(createErrorSelector([DELETE_NOTIFICATION]));

    const [successMsg, setSuccessMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (submitted) {
            setSuccessMsg(!loadingSelector && errorSelector.length === 0);
            setErrorMsg(!loadingSelector && errorSelector.length > 0);
        }
    }, [loadingSelector, errorSelector, submitted]);

    function handleClick() {

        setSubmitted(true);

        const notificationTemp = {
            id: notification.id,
        }

        dispatch(deleteNotification(notificationTemp));
    }

    return (
        <>
            <IconButton
                aria-label="delete"
                onClick={() => handleClick()}
                disabled={isLoading}
            >
                <DeleteIcon/>
            </IconButton>
            {successMsg ? <SweetAlert
                title="Good job!"
                success
                showConfirm={true}
                showCancel={false}
                confirmBtnBsStyle="yellow"
                onConfirm={() => {
                    window.location.reload();
                }}
            >
                Notification deleted successfully.
            </SweetAlert> : null}
            {errorMsg ? <SweetAlert
                title="Request could not be submitted!"
                warning
                showConfirm={true}
                showCancel={false}
                confirmBtnBsStyle="yellow"
                onConfirm={() => {
                    setErrorMsg(false);
                }}
            >
                There was an error while submitting your request. Please try again or get in contact with our
                team.
            </SweetAlert> : null}
        </>
    );
}

export default NotificationDelete;
