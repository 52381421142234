import React, {useState} from "react";
import {getLoggedInUser} from "../../helpers/fakebackend_helper";
import {Button, Popover, PopoverBody, PopoverHeader, Tooltip} from "reactstrap";
import Moment from "react-moment";

const PrintheadStatus = (props) => {

    const [tooltip, setTooltip] = useState(false);

    const print_head = props.print_head;
    const hasTooltip = props.hasTooltip;
    const printer_type = props.printer_type;

    return (
        <React.Fragment>
            {hasTooltip &&
            <Tooltip placement="right" isOpen={tooltip} target={"TooltipRight-" + print_head.id} toggle={() => {
                setTooltip(!tooltip)
            }}>
                Print Head in guarantee till <Moment format="DD-MM-YYYY">{print_head.head_guarantee_date}</Moment>
            </Tooltip>
            }
            {print_head.head_guarantee_state === 1 ?
                <span
                    onMouseEnter={() => setTooltip(true)}
                    className="badge badge-soft-success font-size-14"
                    id={hasTooltip && "TooltipRight-" + print_head.id}><i
                    className="bx bx-check"></i></span>
                :
                <span
                    onMouseEnter={() => setTooltip(true)}
                    className="badge badge-soft-danger font-size-14"
                    id={hasTooltip && "TooltipRight-" + print_head.id}><i
                    className="bx bx-x"></i></span>
            }
            {printer_type && ['gt341', 'gt361', 'gt381', 'gt-3', 'gtx600'].includes(printer_type) &&
                <span className="badge badge-light font-size-14 ml-1">{print_head.ph_position}</span>
            }
            <span
                className="ml-2 px-1 font-weight-bold">{print_head.serial_no}</span>
        </React.Fragment>
    );
}

export default PrintheadStatus;
