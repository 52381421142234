import { RESET_API_FETCH_ERRORS } from './actionTypes';

const Error = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type);

  if (type === RESET_API_FETCH_ERRORS) {
    return {
      ...state,
      [action.payload]: null,
    };
  }

  // not a *_REQUEST / *_SUCCESS / *_ERROR actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  return {
    ...state,
    // Store whether an error had just happen after a request
    // each error will be the error payload object if an error has occured. Otherwise, it will be null.
    // e.g.:
    // - action.payload when GET_TODOS_ERROR;
    // - null when GET_TODOS_SUCCESS / GET_TODOS_REQUEST;
    // status will be the error of the occured error. It will be null if there is no error
    [requestName]: requestState === 'ERROR' ? action.payload : null,
  };
};

export default Error;
