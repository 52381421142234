import React, {FC, useMemo} from 'react';
import MUIDataTable, {MUIDataTableOptions} from 'mui-datatables';
import {LoadingIndicator} from '../../LoadingIndicator';
import {MuiToolbarThemeWithHover} from '../../../helpers/themes/MuiThemes';
import {MuiThemeProvider, Typography} from '@material-ui/core';
import PrinterType from "../../../pages/Printers/printerType";
import Moment from "react-moment";
import NotificationEdit from "../../../pages/Notifications/notification-edit";
import NotificationDelete from "../../../pages/Notifications/notification-delete";

interface NotificationListProps {
    isLoading: boolean,
    notifications: OverviewNotification[]
}

export const NotificationList: FC<NotificationListProps> = ({
                                                                isLoading,
                                                                notifications
                                                            }) => {

    const columns = [
        {
            label: "ID",
            name: "id",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Date Created",
            name: "created_at",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Subject",
            name: "subject",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Message",
            name: "message",
            options: {
                filter: false,
                sort: false,
            }
        },
        // {
        //     label: "Role",
        //     name: "role",
        //     options: {
        //         filter: true,
        //         sort: true,
        //     }
        // },
        // {
        //     label: "Country",
        //     name: "country",
        //     options: {
        //         filter: true,
        //         sort: true,
        //     }
        // },
        {
            label: "Printer Type",
            name: "printer_type",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Actions",
            name: "actions",
            options: {
                filter: false,
                sort: false,
            }
        }
    ];

    const dataList = useMemo(
        () => ({
            columns: [...columns],
            rows: [...notifications.map(
                notification => ({
                    id: notification.id || '',
                    created_at: <Moment format="DD-MM-YYYY">{notification.created_at}</Moment> || '',
                    subject: notification.subject || '',
                    message: <div className="nofification-text" dangerouslySetInnerHTML={{__html: notification.message}}></div> || '',
                    role: notification.role || '',
                    country: notification.role || '',
                    printer_type: <PrinterType printer_type={notification.printer_type}/> || '',
                    actions:
                        <>
                            <NotificationEdit notification={notification}/>
                            <NotificationDelete notification={notification}/>
                        </> || '',
                })
            )]
        }), [notifications, columns],
    );

    const options: MUIDataTableOptions = {
        download: false,
        filter: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        rowsPerPage: 50,
        rowsPerPageOptions: [],
        selectableRows: 'none',
        // onRowClick: (rowData) => history.push('/notification/' + rowData[0]),
        textLabels: {
            body: {
                noMatch: isLoading ? <LoadingIndicator fontSize={24}/> : "Sorry, no matching records found"
            }
        }
    }

    return (
        <MuiThemeProvider theme={MuiToolbarThemeWithHover}>
            <MUIDataTable
                title={!isLoading ? 'Notifications' : <Typography variant="h6">Dealers <LoadingIndicator/></Typography>}
                data={dataList.rows}
                columns={dataList.columns}
                options={options}
            />
        </MuiThemeProvider>
    );
}
