// @flow
import {all, call, fork, takeEvery, put} from "redux-saga/effects";
import { getOvertakesFromBackend } from '../../api/overtakes';
import { getOvertakesError, getOvertakesSuccess } from './actions';
import { GET_OVERTAKES_REQUEST } from './actionTypes';

/**
 * Retrieving Overtakes
 * @param {*} param0
 */
function* getOvertakes({payload: user}) {
    try {
        const data = yield call(getOvertakesFromBackend);
        yield put(getOvertakesSuccess(data));
    } catch (error) {
        yield put(getOvertakesError(error));
    }
}

/**
 * Watchers
 */
export function* watchGetOvertakes() {
    // @ts-ignore @TODO fix ts-ignore - shouldn't be used!
    yield takeEvery(GET_OVERTAKES_REQUEST, getOvertakes);
}

function* OvertakesSaga() {
    yield all([
        fork(watchGetOvertakes),
    ]);
}

export default OvertakesSaga;
