import React from "react";
import {getLoggedInUser} from "../../helpers/fakebackend_helper";

const ClaimStatusOld = (props) => {

    const claim = props.claim;
    const fontsize = props.fontsize ? props.fontsize : '';

    const authUser = getLoggedInUser();

    return (
        <React.Fragment>
            {claim.status === 1 ? <div
                className={"badge badge-pill badge-soft-success "+fontsize}>Open</div> : ''}
            {claim.status === 2 ? <div
                className={"badge badge-pill badge-soft-warning "+fontsize}>In Progress</div> : ''}
            {claim.status === 3 && (claim.final_check_status === 1) ?
                <div
                    className={"badge badge-pill badge-soft-success "+fontsize}>Guarantee granted</div> : ''}
            {claim.status === 3 && claim.final_check_status === 2 && (authUser.user.role === "manager" || authUser.user.role === "staff" || authUser.user.role === "sales") ?
                <div
                    className={"badge badge-pill badge-soft-success "+fontsize}>Internal granted</div> : ''}
            {claim.status === 3 && claim.final_check_status === 2 && authUser.user.role === "engineer" ?
                <div
                    className={"badge badge-pill badge-soft-success "+fontsize}>Guarantee granted</div> : ''}
            {claim.status === 3 && claim.final_check_status === 3 ?
                <div
                    className={"badge badge-pill badge-soft-danger "+fontsize}>Guarantee not granted</div> : ''}
            {(authUser.user.role === "manager" || authUser.user.role === "staff" || authUser.user.role === "sales") && claim.internal_check_status !== 0 ?
                <div className={"badge badge-pill badge-soft-success "+fontsize}>Internal checked</div> : ''}
        </React.Fragment>
    );
}

export default ClaimStatusOld;
