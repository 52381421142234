import React, {useState, useEffect} from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button, Label,
} from "reactstrap";
import {connect} from "react-redux";
import {withRouter, Link, useHistory} from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";

import {AvForm, AvField} from 'availity-reactstrap-validation';
import '@availity/reactstrap-validation-select/styles.scss';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import ListCountries from "../../components/Lists/ListCountries";
import {apiError} from "../../store/auth/login/actions";
import {createNotification} from "../../store/notification/actions";
import ListRoles from "../../components/Lists/ListRoles";
import ListPrinterTypes from "../../components/Lists/ListPrinterTypes";
import {Editor} from "react-draft-wysiwyg";
import {EditorState} from 'draft-js';
import {convertToHTML} from 'draft-convert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const NotificationAdd = (props) => {

    const [errorMsg, setErrorMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedPrinterType, setSelectedPrinterType] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const history = useHistory();

    useEffect(() => {
        setIsLoading(false);
        if (props.data && props.data.success) {
            setSuccessMsg(true);
        } else if (props.data && props.data.error) {
            setErrorMsg(props.data.error);
        }
    }, [props.data]);

    function handleSelectRole(selectedRole) {
        setSelectedRole(selectedRole.value);
    }

    function handleSelectCountry(selectedCountry) {
        setSelectedCountry(selectedCountry.id);
    }

    function handleSelectPrinterType(selectedPrinterType) {
        setSelectedPrinterType(selectedPrinterType.value);
    }

    function handleEditorStateChange(editorState) {
        setEditorState(editorState);
    }

    function handleValidSubmit(event, values) {

        // setIsLoading(true);

        const notification = {
            subject: values.subject,
            message: convertToHTML(editorState.getCurrentContent()),
            // role: selectedRole,
            // country: selectedCountry,
            printer_type: selectedPrinterType,
        }
        props.createNotification(notification);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {/* Render Breadcrumb */}
                    <Breadcrumbs parent="/notifications" title="Notifications" breadcrumbItem="Add Notification"/>

                    <Row>
                        <Col xs="12">
                            <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}>
                                {errorMsg ?
                                    <SweetAlert
                                        title="Error!"
                                        danger
                                        showConfirm={true}
                                        showCancel={false}
                                        confirmBtnBsStyle="danger"
                                        closeOnConfirm={true}
                                        onConfirm={() => {
                                            setErrorMsg(false);
                                        }}
                                    >
                                        {errorMsg}
                                    </SweetAlert> : ''
                                }
                                {successMsg ? <SweetAlert
                                    title="Good job!"
                                    success
                                    showConfirm={true}
                                    showCancel={false}
                                    confirmBtnBsStyle="yellow"
                                    onConfirm={() => {
                                        history.push('/notifications');
                                    }}
                                >
                                    The notification has been added!
                                </SweetAlert> : null}
                                <Card>
                                    <CardBody>
                                        <CardTitle>Notification Informations</CardTitle>
                                        <CardSubtitle className="mb-3">Fill all information below</CardSubtitle>
                                        <Row>
                                            {/*<Col md="3">*/}
                                            {/*    <Label htmlFor="country"*/}
                                            {/*           className="col-form-label">Filter By Role</Label>*/}
                                            {/*    <ListRoles onChange={(role) => {*/}
                                            {/*        handleSelectRole(role);*/}
                                            {/*    }} required={false}/>*/}
                                            {/*</Col>*/}
                                            {/*<Col md="3">*/}
                                            {/*    <Label htmlFor="country"*/}
                                            {/*           className="col-form-label">Filter By Country</Label>*/}
                                            {/*    <ListCountries onChange={(country) => {*/}
                                            {/*        handleSelectCountry(country);*/}
                                            {/*    }} required={false}/>*/}
                                            {/*</Col>*/}
                                            <Col md="3">
                                                <Label htmlFor="country"
                                                       className="col-form-label">Filter By Printer Type</Label>
                                                <ListPrinterTypes onChange={(printerType) => {
                                                    handleSelectPrinterType(printerType);
                                                }} required={false}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <AvField type="text" name="subject" label="Subject" required/>
                                            </Col>
                                            <Col md="12">
                                                <Editor
                                                    editorState={editorState}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={(e) => handleEditorStateChange(e)}
                                                    toolbar={{options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'embedded', 'image', 'remove', 'history']}}
                                                />
                                                {/*<AvField rows="5" type="textarea" name="message" label="Message" required/>*/}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>

                                <Row className="my-4">
                                    <Col sm="6">
                                        <Link to="/notifications"
                                              className="btn d-none d-sm-inline-block btn-link">
                                            <i className="mdi mdi-arrow-left mr-1"></i> Back to Notifications Overview
                                        </Link>
                                    </Col>
                                    <Col sm="6">
                                        <div className="text-sm-right">
                                            {isLoading ?
                                                <Button className="btn btn-yellow" type="submit" disabled={true}>
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i> Submit
                                                    Notification
                                                </Button> :
                                                <Button className="btn btn-yellow" type="submit">
                                                    <i className="mdi mdi-plus-thick mr-1"></i> Submit Notification
                                                </Button>
                                            }
                                        </div>
                                    </Col>
                                </Row>

                            </AvForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}


const mapStatetoProps = state => {
    const {data} = state.Notification;
    state.Notification = {};
    return {data};
};

export default withRouter(connect(mapStatetoProps, {createNotification, apiError})(NotificationAdd));