// @flow
import {
	DISPATCHES_RECEIVED,
	DISPATCH_CONFIRMED,
} from "./actionTypes";

const initialState = {

}

const Dispatches = (state = initialState, action) => {
	switch (action.type) {
		case DISPATCHES_RECEIVED:
			return action.payload
		case DISPATCH_CONFIRMED:
			return {
				data: action.payload
			};

		default:
			return state;
	}
};

export default Dispatches;
