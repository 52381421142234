import {all} from 'redux-saga/effects';

//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import ProfileSaga from './auth/profile/saga';
import ResetSaga from "./auth/resetpwd/saga";
import LayoutSaga from './layout/saga';
import ClaimsSaga from './claims/saga';
import ClaimSaga from './claim/saga';
import PrintersSaga from './printers/saga';
import PrinterSaga from './printer/saga';
import CustomersSaga from './customers/saga';
import CustomerSaga from './customer/saga';
import DispatchesSaga from './dispatches/saga';
import DealersSaga from './dealers/saga';
import DealerSaga from './dealer/saga';
import EngineersSaga from './engineers/saga';
import EngineerSaga from './engineer/saga';
import ServicesSaga from "./services/saga";
import OvertakesSaga from './overtakes/saga';
import NotificationsSaga from './notifications/saga';
import NotificationSaga from './notification/saga';
import UserNotificationsSaga from './user_notifications/saga';

export default function* rootSaga() {
    yield all([
        //public
        AccountSaga(),
        AuthSaga(),
        ProfileSaga(),
        ForgetSaga(),
        ResetSaga(),
        LayoutSaga(),
        ClaimsSaga(),
        ClaimSaga(),
        PrintersSaga(),
        PrinterSaga(),
        CustomersSaga(),
        CustomerSaga(),
        DispatchesSaga(),
        DealersSaga(),
        DealerSaga(),
        EngineersSaga(),
        EngineerSaga(),
        ServicesSaga(),
        OvertakesSaga(),
        NotificationsSaga(),
        NotificationSaga(),
        UserNotificationsSaga()
    ])
}
