import React, {useState, useEffect} from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button, Label,
} from "reactstrap";
import {connect} from "react-redux";
import {createEngineer} from "../../store/engineer/actions";
import {withRouter, Link, useHistory} from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";

// availity-reactstrap-validation
import {AvForm, AvField} from 'availity-reactstrap-validation';
import '@availity/reactstrap-validation-select/styles.scss';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {apiError} from "../../store/auth/login/actions";
import ListDealers from "../../components/Lists/ListDealers";

const EngineerAdd = (props) => {

    const [errorMsg, setErrorMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDealer, setSelectedDealer] = useState("");

    const history = useHistory();

    useEffect(() => {
        setIsLoading(false);
        if (props.data && props.data.success) {
            setSuccessMsg(true);
        } else if (props.data && props.data.error) {
            setErrorMsg(props.data.error);
        }
    }, [props.data]);

    function handleValidSubmit(event, values) {

        setIsLoading(true);

        const member = {
            firstname: values.firstname,
            lastname: values.lastname,
            position: values.position,
            dealer_id: selectedDealer.id,
            email: values.email,
            phone: values.phone,
        }
        props.createEngineer(member);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {/* Render Breadcrumb */}
                    <Breadcrumbs parent="/members" title="Members" breadcrumbItem="Add Member"/>

                    <Row>
                        <Col xs="12">
                            <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}>
                                {errorMsg ?
                                    <SweetAlert
                                        title="Error!"
                                        danger
                                        showConfirm={true}
                                        showCancel={false}
                                        confirmBtnBsStyle="danger"
                                        closeOnConfirm={true}
                                        onConfirm={() => {
                                            setErrorMsg(false);
                                        }}
                                    >
                                        {errorMsg}
                                    </SweetAlert> : ''
                                }
                                {successMsg ? <SweetAlert
                                    title="Good job!"
                                    success
                                    showConfirm={true}
                                    showCancel={false}
                                    confirmBtnBsStyle="yellow"
                                    onConfirm={() => {
                                        history.push('/members');
                                    }}
                                >
                                    The member has been added!
                                </SweetAlert> : null}
                                <Card>
                                    <CardBody>
                                        <CardTitle>Member Informations</CardTitle>
                                        <CardSubtitle className="mb-3">Fill all information below</CardSubtitle>
                                        <Row>
                                            <Col md="3">
                                                <AvField
                                                    name="firstname"
                                                    className="form-control"
                                                    placeholder="Firstname *"
                                                    label="Firstname"
                                                    type="text"
                                                    validate={{required: {value: true}}}
                                                    errorMessage="Please provide a valid firstname."
                                                />
                                            </Col>
                                            <Col md="3">
                                                <AvField
                                                    name="lastname"
                                                    className="form-control"
                                                    placeholder="Lastname *"
                                                    label="Lastname"
                                                    type="text"
                                                    validate={{required: {value: true}}}
                                                    errorMessage="Please provide a valid lastname."
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="3">
                                                <AvField
                                                    name="position"
                                                    className="form-control"
                                                    placeholder="Position *"
                                                    label="Position"
                                                    type="text"
                                                    validate={{required: {value: true}}}
                                                    errorMessage="Please provide a valid position."
                                                />
                                            </Col>
                                            <Col md="3">
                                                <Label htmlFor="dealer"
                                                       className="col-form-label">Dealer</Label>
                                                <ListDealers onChange={(selectedDealer) => {
                                                    setSelectedDealer(selectedDealer);
                                                }}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <AvField
                                                    name="email"
                                                    value=""
                                                    className="form-control"
                                                    placeholder="Email Address*"
                                                    label="Email"
                                                    type="text"
                                                    validate={{required: {value: true}}}
                                                    errorMessage="Please provide a valid email address."
                                                />
                                            </Col>
                                            <Col md="3">
                                                <AvField
                                                    name="phone"
                                                    value=""
                                                    className="form-control"
                                                    placeholder="Phone *"
                                                    label="Phone"
                                                    type="text"
                                                    validate={{required: {value: true}}}
                                                    errorMessage="Please provide a valid phone number."
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>

                                <Row className="my-4">
                                    <Col sm="6">
                                        <Link to="/members"
                                              className="btn d-none d-sm-inline-block btn-link">
                                            <i className="mdi mdi-arrow-left mr-1"></i> Back to Members Overview
                                        </Link>
                                    </Col>
                                    <Col sm="6">
                                        <div className="text-sm-right">
                                            {isLoading ?
                                                <Button className="btn btn-yellow" type="submit" disabled={true}>
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i> Submit
                                                    Member
                                                </Button> :
                                                <Button className="btn btn-yellow" type="submit">
                                                    <i className="mdi mdi-plus-thick mr-1"></i> Submit Member
                                                </Button>
                                            }
                                        </div>
                                    </Col>
                                </Row>

                            </AvForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}


const mapStatetoProps = state => {
    const {data} = state.Engineer;
    state.Engineer = {};
    return {data};
};

export default withRouter(connect(mapStatetoProps, {createEngineer, apiError})(EngineerAdd));