import React, {Component} from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Table, Button, CardTitle
} from "reactstrap";
import {Link} from "react-router-dom";
import Moment from 'react-moment';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
    getPrinter
} from '../../store/actions';
import {connect} from "react-redux";
import {MDBDataTable} from "mdbreact";
import PrinterType from "./printerType";
import {getLoggedInUser} from "../../helpers/fakebackend_helper";
import PrinterHistory from "./printer-history";
import ModalPrintheadExchange from "../../components/Modals/modal-printheadexchange";
import PrintheadStatus from "./printhead-status";
import ModalPrinterAddFile from "../../components/Modals/modal-printeraddfile";
import ClaimsTableOld from "../Claims/claims-table_old";

class PrinterShow extends Component {

    componentDidMount() {
        // const printerId = this.props.location.state.printerId;
        const printerSerial = window.location.pathname.split("/").pop();
        this.props.getPrinter(printerSerial);
    }

    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    render() {

        const authUser = getLoggedInUser();

        const printer = this.props.data && this.props.data.id ? this.props.data : {
            customer: {
                id: 0,
                name: "",
                street: "",
                street_no: "",
                country: "",
                city: "",
                post_code: "",
            },
            dealer: {
                name: ""
            },
            engineer_name: "",
            id: "",
            printer_serial_no: "",
            printer_type: "",
            files: [],
            print_heads: [],
            services: [],
        };


        if (this.props.data) {
            if (this.props.data.files) {
                this.props.data.files.forEach(element => {
                    if (element.extension === "pdf") {
                        element["iconClass"] = 'mdi-file-pdf';
                    } else if (element.extension === "jpg") {
                        element["iconClass"] = 'mdi-file-image';
                    } else {
                        element["iconClass"] = 'mdi-file';
                    }
                });
            }
            if (this.props.data.print_heads) {
                this.props.data.print_heads.forEach(element => {
                    element.serial_no_label =
                        <PrintheadStatus printer_type={printer.printer_type} print_head={element} hasTooltip={true}/>;
                    element.installed_label = element.installed === 1 ?
                        <span className="badge badge-pill badge-soft-success">Installed</span> :
                        <span className="badge badge-pill badge-soft-danger">Not installed</span>;
                    element.installation_date = <Moment format="DD-MM-YYYY">{element.installation_date}</Moment>
                });
            }
            if (this.props.data.services) {
                this.props.data.services.sort((a, b) => a.date_of_visit < b.date_of_visit ? 1 : -1);
                this.props.data.services.forEach(element => {
                    element.date_of_visit_label = <Moment format="DD-MM-YYYY">{element.date_of_visit}</Moment>
                    element.engineer = element.engineer.firstname + " " + element.engineer.lastname
                });
            }
        }

        const data_printheads = this.props.data ? {
            columns: [
                {
                    label: "Serial",
                    field: "serial_no_label",
                    width: 84
                },
                {
                    label: "Colour",
                    field: "color",
                    width: 104
                },
                {
                    label: "Position",
                    field: "ph_position",
                    width: 136
                },
                {
                    label: "Installation",
                    field: "installation_date",
                    sort: "desc",
                    width: 109
                },
                {
                    label: "Installed",
                    field: "installed_label",
                    width: 126
                }
            ],
            rows: this.props.data.print_heads
        } : {};

        const data_services = this.props.data ? {
            columns: [
                {
                    label: "Service",
                    field: "service_type",
                    width: 84
                },
                {
                    label: "Date of service",
                    field: "date_of_visit_label",
                    width: 104
                },
                {
                    label: "Number of prints",
                    field: "number_of_prints",
                    width: 136
                },
                {
                    label: "Engineer",
                    field: "engineer",
                    width: 109
                }
            ],
            rows: this.props.data.services
        } : {};

        return (
            <React.Fragment>
                <Row className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        <Breadcrumbs parent="/printers" title="Printer" breadcrumbItem={printer.printer_serial_no}/>
                        <Row>
                            <Col xl="4">
                                <Card>
                                    <CardBody>

                                        <div className="d-flex mb-3">
                                            <div className="avatar-sm mr-3">
                                                <span
                                                    className="avatar-title rounded-circle bg-primary text-white font-size-20">
                                                    <i className="bx bxs-printer"/>
                                                </span>
                                            </div>
                                            <div className="mb-2">
                                                <div className="d-flex mb-1">
                                                    {
                                                        printer.guarantee_state === 1 ?
                                                            <span className="badge badge-success font-size-14 pt-1"><i
                                                                className="bx bx-check"/> </span> :
                                                            <span className="badge badge-danger font-size-14 pt-1"><i
                                                                className="bx bx-x"/> </span>
                                                    }
                                                    <span
                                                        className="ml-2 text-truncate border rounded px-1">
                                                        <PrinterType printer_type={printer.printer_type}/></span>
                                                </div>
                                                <div className="d-flex mb-1">
                                                    <span
                                                        className="badge badge-dark font-size-14 text-yellow align-self-center">SN</span>
                                                    <span
                                                        className="ml-2 text-truncate font-size-18 text-yellow font-weight-bold">{printer.printer_serial_no}</span>
                                                </div>
                                                {printer.ink_supply_serial &&
                                                <div className="d-flex mb-1">
                                                    <span
                                                        className="badge badge-dark font-size-14 text-yellow align-self-center">ISN</span>
                                                    <span
                                                        className="ml-2 text-truncate font-size-18 text-yellow font-weight-bold">{printer.ink_supply_serial}</span>
                                                </div>
                                                }
                                                <div className="d-flex mb-3">
                                                    <i className='bx bx-power-off bx-xs mr-2'/>
                                                    <span className="text-truncate font-size-12"><Moment
                                                        format="DD-MM-YYYY">{printer.installation_date}</Moment></span>
                                                </div>
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col sm="6">
                                                            <div className="d-flex mb-1">
                                                                <div className="badge badge-dark text-yellow"> PH
                                                                    Colour
                                                                </div>
                                                            </div>
                                                            {
                                                                printer.print_heads && printer.print_heads.filter(print_head => print_head.installed === 1 && print_head.color === "color").map((print_head, key) =>
                                                                    <div key={key} className="d-flex mb-1">
                                                                        <PrintheadStatus
                                                                            printer_type={printer.printer_type}
                                                                            print_head={print_head}/>
                                                                    </div>
                                                                )
                                                            }
                                                        </Col>
                                                        <Col sm="6">
                                                            <div className="d-flex mb-1">
                                                                <div className="badge badge-dark text-yellow"> PH
                                                                    White
                                                                </div>
                                                            </div>
                                                            {
                                                                printer.print_heads && printer.print_heads.filter(print_head => print_head.installed === 1 && print_head.color === "white").map((print_head, key) =>
                                                                    <div key={key} className="d-flex mb-1">
                                                                        <PrintheadStatus
                                                                            printer_type={printer.printer_type}
                                                                            print_head={print_head}/>
                                                                    </div>
                                                                )
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardBody className="border-top">
                                        <div className="d-flex">
                                            <div className="avatar-sm mr-3"/>
                                            <div className="mr-3">
                                                <i
                                                    className="bx bx-user-circle font-size-24"/>
                                            </div>
                                            <div className="text-truncate">
                                                <p className="mb-1 font-size-18 font-bold">{printer.dealer.name}</p>
                                                <p className="mb-0">{printer.engineer && printer.engineer.firstname + ' ' + printer.engineer.lastname}</p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">
                                            Quick navigation
                                        </CardTitle>
                                        <Row>
                                            <div className="col-lg-6">
                                                <ModalPrintheadExchange printer={printer}/>
                                            </div>
                                            {['gtx', 'gtxpro', 'gtxprobulk'].includes(printer.printer_type) && authUser.user && ['manager', 'staff', 'engineer'].includes(authUser.user.role) &&
                                            <div className="col-lg-6">
                                                <Link
                                                    to={"/add-service?id=" + printer.id}>
                                                    <div className="text-white-50 card bg-yellow card-quicknav">
                                                        <div className="card-body">
                                                            <div className="mb-4 text-primary card-title">
                                                                Add<br/> Maintenance
                                                                Service
                                                            </div>
                                                            <i className="mdi mdi-arrow-right text-primary"/>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>}
                                            <div className="col-lg-6">
                                                <Link
                                                    to={"/add-claim"}>
                                                    <div className="text-white-50 card bg-yellow card-quicknav">
                                                        <div className="card-body">
                                                            <div className="mb-4 text-primary card-title">
                                                                Add a new <br/> Claim
                                                            </div>
                                                            <i className="mdi mdi-arrow-right text-primary"/>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>

                                <PrinterHistory printer={printer}/>

                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-4">File Manager</h4>
                                        <ModalPrinterAddFile printer={printer}/>
                                        <div className="table-responsive">
                                            <Table className="table table-centered table-nowrap">
                                                <thead className="thead-light">
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Date modified</th>
                                                    <th>Size</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    printer.files.map((file, key) =>
                                                        <tr key={"_file_" + key}>
                                                            <td>
                                                                <a href={file.link} target="_blank"
                                                                   rel="noopener noreferrer">
                                                                    <i className={"mdi " + file.iconClass + " font-size-16 align-middle text-primary mr-2"}/> {file.name.length > 5 ? `${file.name.substring(0, 10)}...` : file.name}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <Moment
                                                                    format="DD-MM-YYYY, HH:mm">{file.created_at}</Moment>
                                                            </td>
                                                            <td>
                                                                {this.formatBytes(file.size)}
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>

                                {/* @TODO delete this? */}
                                {/*<Card>*/}
                                {/*    <CardBody className="bg-yellow">*/}
                                {/*        <h4 className="card-title">Printer Overtake<br/>for {printer.printer_serial_no}*/}
                                {/*        </h4>*/}
                                {/*        <p>Please use the "Printer Overtake" function if the end customer or dealer of*/}
                                {/*            the printer changes</p>*/}
                                {/*        <button className="mr-1 waves-effect waves-light btn btn-dark">Overtake</button>*/}
                                {/*    </CardBody>*/}
                                {/*</Card>*/}
                            </Col>

                            <Col xl="8">
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-4">Information</h4>
                                        <Row>
                                            {printer.customer ?
                                                <Col lg="4">
                                                    <div className="border p-3 rounded full-height">
                                                        <div className="box-icon font-size-16">
                                                            <Link to={"/customer/" + printer.customer.id}>
                                                                <i className='bx bxs-user-circle'/>
                                                            </Link>
                                                        </div>
                                                        <div>
                                                            <h6>Customer</h6>
                                                            <h5>{printer.customer.name}</h5>
                                                            <p className="mb-2">{printer.customer.street} {printer.customer.street_no}<br/>{printer.customer.zip_code} {printer.customer.city}<br/>{printer.customer.post_code} {printer.customer.country}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Col> : ''
                                            }
                                            <Col lg="4">
                                                <div className="border p-3 rounded full-height">
                                                    <div>
                                                        <h6>Current Dealer</h6>
                                                        <h5>{printer.dealer.name}</h5>
                                                        <p>{printer.dealer.street} {printer.dealer.street_no}<br/>{printer.dealer.zip_code} {printer.dealer.city}<br/>{printer.dealer.country}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col lg="4">
                                                <div className="border p-3 rounded full-height">
                                                    <div>
                                                        <h6>Number of Prints</h6>
                                                        {printer.number_of_prints > 0 ? <React.Fragment>
                                                                <h5>{printer.number_of_prints} Prints</h5>
                                                                <p>Until <Moment
                                                                    format="DD-MM-YYYY">{printer.number_of_prints_changed}</Moment>
                                                                </p>
                                                            </React.Fragment> :
                                                            <React.Fragment>No data yet</React.Fragment>
                                                        }
                                                    </div>
                                                    <div className="mt-2">Machine undamaged: <span
                                                        className="badge badge-pill badge-soft-dark mr-1">{printer.is_undamaged === "1" ? "Yes" : "No"}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            {/*: ''}*/}
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Print Head Overview</h4>
                                        <MDBDataTable fixed striped noBottomColumns info={false} displayEntries={false}
                                                      barReverse responsive bordered
                                                      data={data_printheads} className="mt-5"/>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        {['manager', 'staff', 'engineer'].includes(authUser.user.role) &&
                                        <React.Fragment>
                                            <div className="text-sm-right">
                                                <Link to={{
                                                    pathname: "/add-claim",
                                                }}>
                                                    <Button type="button" color="yellow"
                                                            className="btn-rounded waves-effect waves-light mr-2"><i
                                                        className="mdi mdi-plus mr-1"/>Add New Claim</Button>
                                                </Link>
                                            </div>
                                        </React.Fragment>
                                        }
                                        {
                                            printer.claims && <ClaimsTableOld claims={printer.claims}/>
                                        }

                                        {/* @TODO delete this? */}
                                        {/*<ClaimsTable*/}
                                        {/*    claims={printer?.claims || []}*/}
                                        {/*/>*/}
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Services Overview</h4>
                                        {['gtx', 'gtxpro', 'gtxprobulk'].includes(printer.printer_type) ?
                                            <div className="text-sm-right">
                                                <Link to={"/add-service?id=" + printer.id}>
                                                    <Button type="button" color="yellow"
                                                            className="btn-rounded waves-effect waves-light mb-2 mr-2"><i
                                                        className="mdi mdi-plus mr-1"/>Add Maintenance
                                                        Service</Button>
                                                </Link>
                                            </div> : ''
                                        }
                                        <MDBDataTable fixed striped noBottomColumns info={false} displayEntries={false}
                                                      barReverse responsive bordered
                                                      data={data_services} className="mt-5"/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
        const {data} = state.Printer;
        return {data};
    }
;

export default connect(mapStatetoProps,
    {
        getPrinter
    }
)(PrinterShow);
