// @flow
import {
	GET_CLAIMS_SUCCESS,
} from "./actionTypes";

const initialState = {

}

const Claims = (state = initialState, action) => {
	switch (action.type) {
		case GET_CLAIMS_SUCCESS:
			return action.payload

		default:
			return state;
	}
};

export default Claims;
