// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {
    GET_SERVICES_REQUEST,
} from "./actionTypes";
import { getServicesFromBackend } from '../../api/services';
import { getServicesError, getServicesSuccess } from './actions';

/**
 * Retrieving Services
 * @param {*} param0
 */
function* getServices({payload: user}) {
    try {
        const data = yield call(getServicesFromBackend);
        yield put(getServicesSuccess(data));
    } catch (error) {
        yield put(getServicesError(error));
    }
}

/**
 * Watchers
 */
export function* watchGetServices() {
    yield takeEvery(GET_SERVICES_REQUEST, getServices);
}

function* ServicesSaga() {
    yield all([
        fork(watchGetServices),
    ]);
}

export default ServicesSaga;
