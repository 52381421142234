import React, { FC } from "react";
import { MDBBadge } from 'mdbreact';
import { getLoggedInUser } from '../../../helpers/fakebackend_helper';
import { ROLE, RoleNameMap } from '../../Role/constants';
import { CLAIM_INTERNAL_CHECK_STATUS, CLAIM_STATUS } from '../constants';

interface ClaimInternalStatusProps {
    internal_check_status: CLAIM_INTERNAL_CHECK_STATUS,
}

const ClaimInternalStatus: FC<ClaimInternalStatusProps> = (
    {
        internal_check_status,
    }) => {

    return (
        <>
            {internal_check_status === CLAIM_INTERNAL_CHECK_STATUS.GRANTED ?
                <MDBBadge pill color={'soft-success'}>
                    Guarantee granted
                </MDBBadge> : ''
            }
            {internal_check_status === CLAIM_INTERNAL_CHECK_STATUS.INTERNAL_GRANTED ?
                <MDBBadge pill color={'soft-warning'}>
                    Internal granted
                </MDBBadge> : ''
            }
            {internal_check_status === CLAIM_INTERNAL_CHECK_STATUS.NOT_GRANTED ?
                <MDBBadge pill color={'soft-danger'}>
                    Guarantee not granted
                </MDBBadge> : ''
            }
        </>
    );
}

export default ClaimInternalStatus;
