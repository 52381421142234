import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
    getDealer,
} from '../../store/actions';
import { ClaimsTable } from "../Claims/claims-table";
import { useLocation } from 'react-router-dom';
import { getDealerByIdSelector } from '../../selectors/dealer';
import { createLoadingSelector } from '../../selectors/loading';
import { GET_DEALER } from '../../store/dealer/actionTypes';
import { DealerCard } from '../../components/Dealer/DealerCard';
import { PrintersTable } from '../Printers/printers-table';
import ClaimsTableOld from "../../pages/Claims/claims-table_old";

const DealerShow: FC = () => {

    const location = useLocation();
    const dispatch = useDispatch();

    const dealerId = location.pathname.split("/").pop() || 0;

    const dealerSelector = useSelector(state => getDealerByIdSelector(state, +dealerId));

    const dealerLoadingSelector = useSelector(createLoadingSelector([GET_DEALER]));

    const [dealerLoading, setDealerLoading] = useState(true);
    const [dealer, setDealer] = useState<FullDealer>();

    useEffect(() => {
        dispatch(getDealer(+dealerId));
    }, [dispatch]);

    useEffect(() => {
        setDealer(dealerSelector);
    }, [dealerSelector]);

    useEffect(() => {
        setDealerLoading(dealerLoadingSelector);
    }, [dealerLoadingSelector]);

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs parent="/dealers" title="Dealer" breadcrumbItem={dealer && dealer.name}/>
                <Row>
                    <Col xl="4">
                        <DealerCard
                            dealer={dealer}
                            isLoading={dealerLoading}
                        />
                    </Col>

                    <Col xl="8">
                        <Card>
                            <CardBody>
                                <PrintersTable
                                    printers={dealer?.printer || []}
                                    isLoading={dealerLoading}
                                />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                {
                                    dealer && dealer.claims && <ClaimsTableOld claims={dealer.claims}/>
                                }
                                {/*<ClaimsTable*/}
                                {/*    claims={dealer?.claims || []}*/}
                                {/*    isLoading={dealerLoading}*/}
                                {/*/>*/}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default DealerShow;
