import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardTitle, Button, Row, Col} from "reactstrap";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getClaims} from "../../store/claims/actions";
import {getLoggedInUser} from "../../helpers/fakebackend_helper";
import LatestClaimsTable from "./LatestClaimsTable";
import LatestClaimsTableShort from "./LatestClaimsTableShort";

const LatestClaims = (props) => {

    const [claims, setClaims] = useState([]);

    const authUser = getLoggedInUser();

    useEffect(() => {
        props.getClaims();
    }, [])

    useEffect(() => {
        if (props.data) {
            setClaims(props.data);
        }
    }, [props.data]);

    return (
        <React.Fragment>

            <Row className="mb-4">
                {['manager', 'staff'].includes(authUser.user.role) &&
                <Col>
                    <Card className="h-100 mb-0">
                        <CardBody>
                            <CardTitle className="text-yellow mb-4">
                                Last Claims open
                            </CardTitle>
                            <LatestClaimsTableShort claims={claims.filter(claim => claim.status === 1).slice(0, 5)} />
                        </CardBody>
                    </Card>
                </Col>}
                {['manager', 'staff'].includes(authUser.user.role) &&
                <Col>
                    <Card className="h-100 mb-0">
                        <CardBody>
                            <CardTitle className="text-yellow mb-4">
                                Waiting for internal check
                            </CardTitle>
                            <LatestClaimsTableShort claims={claims.filter(claim => claim.status === 2 && claim.internal_check_status === 0).slice(0, 5)} />
                        </CardBody>
                    </Card>
                </Col>}
                {['manager'].includes(authUser.user.role) &&
                <Col>
                    <Card className="h-100 mb-0">
                        <CardBody>
                            <CardTitle className="text-yellow mb-4">
                                Waiting for final check
                            </CardTitle>
                            <LatestClaimsTableShort claims={claims.filter(claim => claim.status === 2 && claim.internal_check_status !== 0  && claim.final_check_status === 0).slice(0, 5)} />
                        </CardBody>
                    </Card>
                </Col>
                }
            </Row>
            { authUser.user.is_guarantee_user === 1 &&
            <Card>
                <CardBody>
                    <CardTitle className="text-yellow mb-2">
                        Open CN/FOC
                    </CardTitle>
                    <div className="text-sm-right mb-2">
                        <Link to={{
                            pathname: "/claims",
                        }}>
                            <Button type="button" color="yellow"
                                    className="btn-rounded waves-effect waves-light mb-2 mr-2">Show All Claims</Button>
                        </Link>
                    </div>
                    <LatestClaimsTable claims={claims.filter(claim => claim.status === 3 && (claim.final_check_status === 1 || claim.final_check_status === 2) && (!claim.parts_order_no || !claim.r_value)).slice(0, 10)} />
                </CardBody>
            </Card>
            }
            <Card>
                <CardBody>
                    <CardTitle className="text-yellow mb-2">
                        Last Claims
                    </CardTitle>
                    <div className="text-sm-right mb-2">
                        <Link to={{
                            pathname: "/claims",
                        }}>
                            <Button type="button" color="yellow"
                                    className="btn-rounded waves-effect waves-light mb-2 mr-2">Show All Claims</Button>
                        </Link>
                    </div>
                    <LatestClaimsTable claims={claims.slice(0, 10)} />
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {data} = state.Claims;
    return {data};
};

export default connect(mapStatetoProps, {
    getClaims
})(LatestClaims);