import {
	GET_DISPATCHES,
	DISPATCHES_RECEIVED,
	CONFIRM_DISPATCH,
	DISPATCH_CONFIRMED
} from "./actionTypes";

export const getDispatches = () => ({
	type: GET_DISPATCHES,
	payload: null
});

export const dispatchesReceived = (data) => {
	return{
		type: DISPATCHES_RECEIVED,
		payload: data
	}
};

export const confirmDispatch = (dispatch) => ({
	type: CONFIRM_DISPATCH,
	payload: dispatch
});

export const dispatchConfirmed = (dispatch) => {
	return {
		type: DISPATCH_CONFIRMED,
		payload: dispatch
	}
};





