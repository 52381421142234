import React, {useEffect, useState} from "react";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import axios from "axios";
import {Alert, Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import DownloadFile from "./downloadFile";

const DownloadArea = () => {

    const [downloads, setDownloads] = useState(null);
    const [items, setItems] = useState(null);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [loading, setIsLoading] = useState(true);

    const categoryId = window.location.pathname.split("/").pop();

    const history = useHistory();

    useEffect(() => {
        if (downloads === null) {
            getDownloads();
        }
    }, []);

    function getDownloads() {
        const url = process.env.REACT_APP_APIURL + 'downloads';
        const authToken = authHeader();

        return axios.get(url, {headers: authToken}).then(response => {
            setIsLoading(false);
            setItems(response.data.data);
            if (categoryId > 0) {
                response.data.data.forEach(function (item) {
                    let category = item.categories.items.find(category => category.id == categoryId);
                    if (category) {
                        setDownloads(item.downloads);
                        setCurrentFolder(item.folder);
                        setCurrentCategory(category);
                    }
                })
            }

        }).catch(error => {
            // setIsLoading(false);
        });
    }

    return (
        <React.Fragment>
            <Row className="page-content">
                <Container fluid>

                    <Row>
                        {items ? <Col xl="3">
                            {items.filter(item => item.folder.id === 24438).map((item, itemIndex) =>
                                <Card key={"item-" + itemIndex}>
                                    <CardBody>
                                        <CardTitle className="text-yellow mb-2">
                                            {item.folder.name}
                                        </CardTitle>
                                        {item.categories && item.categories.items.map((category, categoryIndex) => <div
                                            className={"pb-2 mb-2 w-75 cursor-pointer border-bottom border-light border-bottom-1" + (currentCategory && (currentCategory.id === category.id) ? " font-weight-bold" : "")}
                                            key={"category-" + categoryIndex}
                                            onClick={() => {
                                                setDownloads(item.downloads);
                                                setCurrentFolder(item.folder);
                                                setCurrentCategory({
                                                    ...category
                                                });
                                                history.push('/downloads/' + category.id);
                                            }}
                                        >
                                            {category.name}
                                        </div>)}
                                    </CardBody>
                                </Card>)}
                            <Card>
                                <CardBody className="pb-0">
                                    {items.filter(item => item.folder.id !== 24438).map((item, itemIndex) =>
                                        <div
                                            className="mb-4"
                                            key={"item-" + itemIndex}>
                                            <CardTitle className="text-yellow mb-2">
                                                {item.folder.name}
                                            </CardTitle>
                                            {item.categories && item.categories.items.map((category, categoryIndex) =>
                                                <div
                                                    className={"pb-2 mb-2 w-75 cursor-pointer border-bottom border-light border-bottom-1" + (currentCategory && (currentCategory.id === category.id) ? " font-weight-bold" : "")}
                                                    key={"category-" + categoryIndex}
                                                    onClick={() => {
                                                        setDownloads(item.downloads);
                                                        setCurrentFolder(item.folder);
                                                        setCurrentCategory({
                                                            ...category
                                                        });
                                                        history.push('/downloads/' + category.id);
                                                    }}
                                                >
                                                    {category.name}
                                                </div>)}
                                        </div>)}
                                </CardBody>
                            </Card>
                        </Col> : ''
                        }

                        <Col xl="9">
                            <Card>
                                <CardBody>
                                    <CardTitle className="font-size-24 text-yellow mb-4">
                                        {currentCategory ? currentCategory.name : "Driver/Software"}<br/>
                                    </CardTitle>
                                    <p>All important drivers, firmwares and tools for Brother and Non-Brother
                                        products.</p>
                                    {currentFolder && currentFolder.id === 24438 &&
                                    <Alert color="danger">Please note the provided software-tools and manuals are for
                                        dealer use only. Do not share them with customers.</Alert>}
                                </CardBody>
                            </Card>
                            {loading && <div className="d-flex mb-4">
                                <div><i className="bx bx-loader bx-spin mr-1"></i></div>
                                <div>Loading...</div>
                            </div>}
                            {currentCategory && currentCategory.children.map((subCategory, subCategoryIndex) =>
                                <Card
                                    key={"subcategory-" + subCategoryIndex}>
                                    <CardBody>
                                        <CardTitle className="text-yellow mb-4">{subCategory.name}</CardTitle>
                                        {downloads && downloads.filter(download => download.parent_id == subCategory.id).map((download, index) =>
                                            <DownloadFile
                                                className={index > 0 ? "mt-2 pt-4 border-top border-light border-top-1" : ""}
                                                key={"download-" + index}
                                                download={download}
                                                index={index}
                                            />
                                        )}
                                        {subCategory.children && subCategory.children.map((subsubCategory, subsubCategoryIndex) =>
                                            <div className="mt-2 mb-2">
                                                <div className="mb-4 font-weight-bold">{subsubCategory.name}</div>
                                                {downloads && downloads.filter(download => download.parent_id == subsubCategory.id).map((download, index) =>
                                                    <DownloadFile
                                                        className={index > 0 ? "mt-2 pt-4 border-top border-light border-top-1" : ""}
                                                        key={"download-" + index}
                                                        download={download}
                                                        index={index}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>
                            )}
                            {currentCategory && currentCategory.children.length === 0 &&
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-yellow mb-4">{currentCategory.name}</CardTitle>
                                    {downloads && downloads.filter(download => download.parent_id == currentCategory.id).map((download, index) =>
                                        <DownloadFile
                                            className={index > 0 ? "mt-2 pt-4 border-top border-light border-top-1" : ""}
                                            key={"download-" + index}
                                            download={download}
                                            index={index}
                                        />
                                    )}
                                    {downloads.length === 0 && <p>No files available</p>}
                                </CardBody>
                            </Card>
                            }
                        </Col>
                    </Row>
                </Container>
            </Row>
        </React.Fragment>
    );
}

export default DownloadArea;