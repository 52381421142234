import {
	GET_CLAIMS_ERROR,
	GET_CLAIMS_REQUEST,
	GET_CLAIMS_SUCCESS
} from './actionTypes';


export const getClaims = () => ({
	type: GET_CLAIMS_REQUEST
});

export const getClaimsSuccess = (data) => {
	return{
		type: GET_CLAIMS_SUCCESS,
		payload: data
	}
};

export const getClaimsError = (error) => {
	return{
		type: GET_CLAIMS_ERROR,
		payload: error
	}
};





