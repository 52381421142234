import {
	CREATE_ENGINEER_ERROR,
	CREATE_ENGINEER_REQUEST,
	CREATE_ENGINEER_SUCCESS, GET_ENGINEER_ERROR,
	GET_ENGINEER_REQUEST,
	GET_ENGINEER_SUCCESS
} from './actionTypes';


export const getEngineer = (engineerId) => ({
	type: GET_ENGINEER_REQUEST,
	payload: engineerId
});

export const getEngineerSuccess = (data) => {
	return{
		type: GET_ENGINEER_SUCCESS,
		payload: data
	}
};

export const getEngineerError = (error) => {
	return{
		type: GET_ENGINEER_ERROR,
		payload: error
	}
};

export const createEngineer = (engineer) => ({
	type: CREATE_ENGINEER_REQUEST,
	payload: engineer
});

export const createEngineerSuccess = (engineer) => {
	return {
		type: CREATE_ENGINEER_SUCCESS,
		payload: engineer
	}
};

export const createEngineerError = (error) => {
	return {
		type: CREATE_ENGINEER_ERROR,
		payload: error
	}
};





