import React from 'react';

import {Row, Col, CardBody, Card, Alert, Container, Button} from "reactstrap";

// Redux
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';

// availity-reactstrap-validation
import {AvForm, AvField} from 'availity-reactstrap-validation';

// actions
import {loginUser, apiError} from '../../store/actions';

// import images
import logoImg from "../../assets/images/logo-brother.svg";

const Login = (props) => {

    // handleValidSubmit
    function handleValidSubmit(event, values) {
        props.loginUser(values, props.history);
    }

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10}>
                            <Card className="overflow-hidden">
                                <div className="bg-dark mb-4 p-4">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="px-4">
                                                <h3 className="text-yellow mb-0">Brother TECNET</h3>
                                                <p className="text-white">BIMH Dealer Service Platform</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-center text-right">
                                            <div className="px-4">
                                                <img src={logoImg} alt="" className="img-fluid"/>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody>
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <div className="p-4">
                                                <h5 className="mb-3">Just one more step away</h5>
                                                <p>The next level begins with Brother TECNET. This platform has been developed for certified dealers of Brother Internationale Industriemaschinen GmbH in order to optimize processes and further increase the service for our end customers.</p>
                                                <p className="font-size-11 mt-5"><strong>A Brother Digital
                                                    Product</strong><br/>
                                                    Brother Internationale Industriemaschinen GmbH<br/>
                                                    Düsseldorfer Straße 7-9<br/>
                                                    46446 Emmerich am Rhein</p>
                                            </div>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <div className="p-4">
                                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                                    handleValidSubmit(e, v)
                                                }}>

                                                    {props.error ? <Alert color="danger">Login is incorrect or not
                                                        activated.</Alert> : ''}

                                                    <div className="form-group font-size-13">
                                                        <AvField name="email" label="E-Mail address"
                                                                 className="form-control"
                                                                 placeholder="Enter E-Mail address" type="email"
                                                                 required/>
                                                    </div>

                                                    <div className="form-group font-size-13">
                                                        <AvField name="password" label="Password" type="password"
                                                                 required placeholder="Enter password"/>
                                                    </div>

                                                    {/*<div className="custom-control custom-checkbox">*/}
                                                    {/*    <input type="checkbox" className="custom-control-input"*/}
                                                    {/*           id="customControlInline"/>*/}
                                                    {/*    <label className="custom-control-label" htmlFor="customControlInline">Remember*/}
                                                    {/*        me</label>*/}
                                                    {/*</div>*/}

                                                    <div className="mt-3">
                                                        {props.loading ?
                                                            <Button
                                                                className="btn btn-dark btn-block waves-effect waves-light"
                                                                type="submit" disabled={true}>
                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i> Log
                                                                In
                                                            </Button> :
                                                            <Button
                                                                className="btn btn-dark btn-block waves-effect waves-light"
                                                                type="submit">
                                                                Log In
                                                            </Button>
                                                        }
                                                    </div>

                                                    <div className="mt-4 font-size-13">
                                                        <Link to="/forgot-password"><i
                                                            className="mdi mdi-lock mr-1 mdi-24px text-yellow"></i> Forgot
                                                            your password?</Link>
                                                    </div>
                                                </AvForm>
                                            </div>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center font-size-13">
                                <p>Don't have an account ? <Link to="register"
                                                                 className="font-weight-bold text-yellow"> Signup
                                    now </Link></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {error, loading} = state.Login;
    return {error, loading};
}

export default withRouter(connect(mapStatetoProps, {loginUser, apiError})(Login));

