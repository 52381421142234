import React, { FC, useMemo } from 'react';
import { MDBBadge } from 'mdbreact';
import { MuiToolbarThemeWithHover } from '../../../helpers/themes/MuiThemes';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { MuiThemeProvider, Typography } from '@material-ui/core';
import { LoadingIndicator } from '../../LoadingIndicator';
import { DataList } from '../../../helpers/Table/TableAction';
import { useHistory } from 'react-router-dom';

interface EngineerListProps {
    isLoading: boolean,
    engineers: Engineer[]
}

export const EngineerList: FC<EngineerListProps> = ({
    isLoading,
    engineers
}) => {

    const history = useHistory();

    const columns = [
        {
            label: "ID",
            name: "id",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Name",
            name: "name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Rolle",
            name: "role_name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Dealer",
            name: "dealer_name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Status",
            name: "activation",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (activation) =>
                    <MDBBadge
                        pill
                        color={activation === "1" ? 'soft-success' : 'soft-warning'}
                    >
                        {activation !== "1" ? 'Unconfirmed' : 'Confirmed'}
                    </MDBBadge>
            }
        },
    ];
    
    const dataList: DataList = useMemo(
    () => ({
        columns: [...columns],
        rows: [...engineers.map(
            engineer => ({
                id: engineer.id,
                name: engineer.firstname + ' ' + engineer.lastname,
                dealer_name: engineer?.dealer?.name,
                role_name: engineer?.role?.name,
                activation: engineer.activation,
            })
        )]
    }), [engineers, columns],
  );
    
    const options: MUIDataTableOptions = {
        download: false,
        filter: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        rowsPerPage: 50,
        rowsPerPageOptions: [],
        selectableRows: 'none',
        onRowClick: (rowData) => history.push('/member/' + rowData[0]),
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LoadingIndicator fontSize={24}/> : "Sorry, no matching records found"
            }
        }
    }
    
    return (
        <MuiThemeProvider theme={MuiToolbarThemeWithHover}>
            <MUIDataTable
                title={!isLoading ? 'Members' : <Typography variant="h6">Members <LoadingIndicator /></Typography>}
                data={dataList.rows}
                columns={dataList.columns}
                options={options}
            />
        </MuiThemeProvider>
    );
}
