// @flow
import {
	PRINTER_RECEIVED,
	PRINTER_CREATED,
} from "./actionTypes";

const initialState = {

}

const Printer = (state = initialState, action) => {
	switch (action.type) {
		case PRINTER_RECEIVED:
			return action.payload
		case PRINTER_CREATED:
			return {
				data: action.payload
			};

		default:
			return state;
	}
};

export default Printer;
