// @flow
import {
	GET_SERVICES_SUCCESS,
} from "./actionTypes";

const initialState = {

}

const Services = (state = initialState, action) => {
	switch (action.type) {
		case GET_SERVICES_SUCCESS:
			return action.payload

		default:
			return state;
	}
};

export default Services;
