// This is just for the Loading or Error Selector
export const GET_USER_NOTIFICATIONS = "GET_USER_NOTIFICATIONS";

// Use these for Actions
export const GET_USER_NOTIFICATIONS_REQUEST = "GET_USER_NOTIFICATIONS_REQUEST";
export const GET_USER_NOTIFICATIONS_SUCCESS = "GET_USER_NOTIFICATIONS_SUCCESS";
export const GET_USER_NOTIFICATIONS_ERROR = "GET_USER_NOTIFICATIONS_ERROR";
export const SET_USER_NOTIFICATIONS_SEEN = "SET_USER_NOTIFICATIONS_SEEN";
export const SET_USER_NOTIFICATIONS_SEEN_SUCCESS = "SET_USER_NOTIFICATIONS_SEEN_SUCCESS";
export const SET_USER_NOTIFICATIONS_SEEN_ERROR = "SET_USER_NOTIFICATIONS_SEEN_ERROR";
