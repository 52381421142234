import React, {useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import axios from "axios";
import {Card, CardBody, CardTitle} from "reactstrap";
import {Link} from "react-router-dom";
import {getLoggedInUser} from "../../helpers/fakebackend_helper";

const PrinterInstalledChart = (props) => {

    const authUser = getLoggedInUser();

    const [isLoading, setIsLoading] = useState(true);
    const [printerReports, setPrinterReports] = useState([]);
    const [printerCategories, setPrinterCategories] = useState([]);
    const [year, setYear] = useState(2021);

    const reportNav = [
        {title: "2018", linkto: "#", value: 2018},
        {title: "2019", linkto: "#", value: 2019},
        {title: "2020", linkto: "#", value: 2020},
        {title: "2021", linkto: "#", value: 2021},
        {title: "2022", linkto: "#", value: 2022},
        {title: "all", linkto: "#", value: '*'}
    ];

    useEffect(() => {
        getPrinterReports(year);
    }, []);

    function getPrinterReports(year) {
        const url = process.env.REACT_APP_APIURL + 'reports/printers?year=' + year;
        const authToken = authHeader();

        return axios.get(url, {headers: authToken}).then(response => {
            setIsLoading(false);
            setPrinterReports(response.data.reports);
            setPrinterCategories(response.data.categories);
        }).catch(error => {
            setIsLoading(false);
        });
    }

    let data = {
        options: {
            chart: {
                height: 359,
                type: "bar",
                stacked: !0,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            plotOptions: {
                bar: {
                    horizontal: !1,
                    columnWidth: "50%",
                    // endingShape: "rounded"
                }
            },
            dataLabels: {
                enabled: true
            },
            xaxis: {
                categories: printerCategories
            },
            yaxis:
                {
                    labels: {
                        formatter: function (val) {
                            return val.toFixed(0);
                        }
                    }
                }
            ,
            colors: authUser.user.can_see_gtx600 ? ["#141414", "#BEB54A", "#34C38F", "#ADD8E6", "#BF664B"] : ["#141414", "#BEB54A", "#34C38F", "#BF664B"],
            legend: {
                position: "bottom"
            },
            fill: {
                opacity: 1
            },
        },
        series: printerReports
    }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CardTitle className="text-yellow mb-4 float-sm-left">
                        Printer installed
                    </CardTitle>
                    <div className="float-sm-right">
                        <ul className="nav nav-pills">
                            {
                                reportNav.map((item, key) =>
                                    <li className="nav-item ml-1" key={"_li_" + key}>
                                        <Link className={year === item.value ? "nav-link active" : "nav-link"}
                                              to={item.linkto} onClick={() => {
                                            setYear(item.value)
                                            getPrinterReports(item.value);
                                        }}>
                                            {item.title}
                                        </Link>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                    <div className="clearfix"></div>
                    {printerReports ?
                        <ReactApexChart options={data.options} series={data.series} type="bar" height="359"/> : ''}
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default PrinterInstalledChart;
