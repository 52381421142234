import React from 'react';
import {Row, Col, Alert, Card, CardBody, Container, Button} from "reactstrap";

// Redux
import {connect} from "react-redux";
import {withRouter, Link, useLocation} from "react-router-dom";

// availity-reactstrap-validation
import {AvForm, AvField} from "availity-reactstrap-validation";

// action
import {userResetPassword} from "../../store/actions";

// import images
import queryString from 'query-string';
import logoImg from "../../assets/images/logo-brother.svg";

const ResetPasswordPage = (props) => {

    const location = useLocation();
    const params = queryString.parse(location.search)

    function handleValidSubmit(event, values) {
        props.userResetPassword({...values,token: params.token}, props.history);
    }

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={6}>
                            <Card className="overflow-hidden">
                                <div className="bg-dark mb-4 p-4">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="px-4">
                                                <h3 className="text-yellow mb-0">Brother TECNET</h3>
                                                <p className="text-white">BIMH Dealer Service Platform</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-center text-right">
                                            <div className="px-4">
                                                <img src={logoImg} alt="" className="img-fluid"/>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody>
                                    <div className="p-2">

                                        {props.resetError ? (
                                            <Alert color="danger" style={{marginTop: "13px"}}>
                                                {props.resetError}
                                            </Alert>
                                        ) : null}
                                        {props.resetSuccessMsg ? (
                                            <Alert color="success" style={{marginTop: "13px"}}>
                                                {props.resetSuccessMsg}
                                            </Alert>
                                        ) : null}

                                        <AvForm
                                            className="form-horizontal mt-4"
                                            onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                                        >

                                            <Row>
                                                <Col md="6">
                                                    <div className="form-group">
                                                        <AvField
                                                            name="password"
                                                            label="Password"
                                                            type="password"
                                                            required
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: 'Please enter a password'
                                                                },
                                                                minLength: {
                                                                    value: 6,
                                                                    errorMessage: 'Your name must be between 6 and 16 characters'
                                                                },
                                                                maxLength: {
                                                                    value: 16,
                                                                    errorMessage: 'Your name must be between 6 and 16 characters'
                                                                }
                                                            }}
                                                            placeholder="Enter Password"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="form-group">
                                                        <AvField
                                                            name="password_confirmation"
                                                            label="Confirm Password"
                                                            type="password"
                                                            required
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: 'Please enter a password'
                                                                },
                                                                minLength: {
                                                                    value: 6,
                                                                    errorMessage: 'Your name must be between 6 and 16 characters'
                                                                },
                                                                maxLength: {
                                                                    value: 16,
                                                                    errorMessage: 'Your name must be between 6 and 16 characters'
                                                                }
                                                            }}
                                                            placeholder="Confirm Password"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <Col className="text-right">
                                                    {props.loading ?
                                                        <Button
                                                            className="btn btn-dark btn-block waves-effect waves-light"
                                                            type="submit" disabled={true}>
                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i> Reset
                                                        </Button> :
                                                        <Button
                                                            className="btn btn-dark btn-block waves-effect waves-light"
                                                            type="submit">
                                                            Reset
                                                        </Button>
                                                    }
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center font-size-13">
                                <p>
                                    Already have an account ?{" "}
                                    <Link
                                        to="/login"
                                        className="font-weight-bold text-yellow"
                                    >
                                        {" "}
                                        Login
                                    </Link>{" "}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {resetError, resetSuccessMsg, loading} = state.ResetPassword;
    return {resetError, resetSuccessMsg, loading};
};

export default withRouter(
    connect(mapStatetoProps, {userResetPassword})(ResetPasswordPage)
);
