import React from "react";
import {Alert, Col, FormGroup, Label, Row} from "reactstrap";
import {AvField} from "availity-reactstrap-validation";
import Printhead from "./printhead";

const Gt381 = (props) => {

    return (
        <React.Fragment>
            <FormGroup className="mb-4">
                <Label className="col-form-label">Print Head Serials</Label>
                <Alert color="danger" role="alert">
                    Please note: the following print head serial numbers are the original print heads that are installed with the installation
                </Alert>
                <table style={{width: "100%"}}>
                    <tbody>
                    <tr>
                        <td>
                            <Row className="mb-2 px-2">
                                <Label md="2"
                                       className="col-form-label">PH C
                                    Serial*</Label>
                                <Col md="4">
                                    <Printhead example="AGT1234" name="print_heads[gt381][0][serial]" placeholder="PH C Serial *"/>
                                    <AvField
                                        name="print_heads[gt381][0][position]"
                                        value="c"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gt381][0][color]"
                                        value="color"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gt381][0][vrate]"
                                        value=""
                                        type="text"
                                        hidden={true}
                                    />
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Row className="mb-2 px-2">
                                <Label md="2"
                                       className="col-form-label">PH M
                                    Serial*</Label>
                                <Col md="4">
                                    <Printhead example="AGT1234" name="print_heads[gt381][1][serial]" placeholder="PH M Serial *"/>
                                    <AvField
                                        name="print_heads[gt381][1][position]"
                                        value="m"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gt381][1][color]"
                                        value="color"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gt381][1][vrate]"
                                        value=""
                                        type="text"
                                        hidden={true}
                                    />
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Row className="mb-2 px-2">
                                <Label md="2"
                                       className="col-form-label">PH Y
                                    Serial*</Label>
                                <Col md="4">
                                    <Printhead example="AGT1234" name="print_heads[gt381][2][serial]" placeholder="PH Y Serial *"/>
                                    <AvField
                                        name="print_heads[gt381][2][position]"
                                        value="y"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gt381][2][color]"
                                        value="color"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gt381][2][vrate]"
                                        value=""
                                        type="text"
                                        hidden={true}
                                    />
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Row className="mb-2 px-2">
                                <Label md="2"
                                       className="col-form-label">PH K
                                    Serial*</Label>
                                <Col md="4">
                                    <Printhead example="AGT1234" name="print_heads[gt381][3][serial]" placeholder="PH K Serial *"/>
                                    <AvField
                                        name="print_heads[gt381][3][position]"
                                        value="k"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gt381][3][color]"
                                        value="color"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gt381][3][vrate]"
                                        value=""
                                        type="text"
                                        hidden={true}
                                    />
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Row className="mb-2 px-2">
                                <Label md="2"
                                       className="col-form-label">PH White
                                    Serial*</Label>
                                <Col md="4">
                                    <Printhead example="AGT1234" name="print_heads[gt381][4][serial]" placeholder="PH White Serial *"/>
                                    <AvField
                                        name="print_heads[gt381][4][position]"
                                        value="5"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gt381][4][color]"
                                        value="white"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gt381][4][vrate]"
                                        value=""
                                        type="text"
                                        hidden={true}
                                    />
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Row className="mb-2 px-2">
                                <Label md="2"
                                       className="col-form-label">PH White
                                    Serial*</Label>
                                <Col md="4">
                                    <Printhead example="AGT1234" name="print_heads[gt381][5][serial]" placeholder="PH White Serial *"/>
                                    <AvField
                                        name="print_heads[gt381][5][position]"
                                        value="6"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gt381][5][color]"
                                        value="white"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gt381][5][vrate]"
                                        value=""
                                        type="text"
                                        hidden={true}
                                    />
                                </Col>
                            </Row>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Row className="mb-2 px-2">
                                <Label md="2"
                                       className="col-form-label">PH White
                                    Serial*</Label>
                                <Col md="4">
                                    <Printhead example="AGT1234" name="print_heads[gt381][6][serial]" placeholder="PH White Serial *"/>
                                    <AvField
                                        name="print_heads[gt381][6][position]"
                                        value="7"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gt381][6][color]"
                                        value="white"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gt381][6][vrate]"
                                        value=""
                                        type="text"
                                        hidden={true}
                                    />
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Row className="mb-2 px-2">
                                <Label md="2"
                                       className="col-form-label">PH White
                                    Serial*</Label>
                                <Col md="4">
                                    <Printhead example="AGT1234" name="print_heads[gt381][7][serial]" placeholder="PH White Serial *"/>
                                    <AvField
                                        name="print_heads[gt381][7][position]"
                                        value="8"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gt381][7][color]"
                                        value="white"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gt381][7][vrate]"
                                        value=""
                                        type="text"
                                        hidden={true}
                                    />
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </FormGroup>
        </React.Fragment>
    );
}

export default Gt381;
