import React, {useEffect, useState} from 'react';
import {Row, Col, CardBody, Card, Container, Label, Button} from "reactstrap";

// availity-reactstrap-validation
import {AvForm, AvField} from "availity-reactstrap-validation";

// action
import {registerUser, registerUserFailed} from "../../store/actions";

// Redux
import {connect} from "react-redux";
import {Link, useHistory} from "react-router-dom";

// import images
import logoImg from "../../assets/images/logo-brother.svg";
// import logoImg from "../../assets/images/logo.svg";
import ListDealers from "../../components/Lists/ListDealers";
import SweetAlert from "react-bootstrap-sweetalert";
import AvSelect from "../../components/Common/AvSelect";

const positionList = [
    {
        options: [
            {label: "Engineer", value: "Engineer"},
            {label: "Sales", value: "Sales"},
        ]
    }
];

const Register = (props) => {

    const [selectedDealer, setSelectedDealer] = useState(null);
    const [errorMsg, setErrorMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();

    // handleValidSubmit
    function handleValidSubmit(event, values) {

        setIsLoading(true);

        const user = {
            firstname: values.firstname,
            lastname: values.lastname,
            position: values.position.value,
            dealer_id: selectedDealer.id,
            email: values.email,
            phone: values.phone,
            password: values.password,
            password_confirmation: values.password_confirmation
        }
        props.registerUser(user);
    }

    function handleSelectDealer(selectedDealer) {
        setSelectedDealer(selectedDealer);
    }

    useEffect(() => {
        props.registerUserFailed("");
    });

    useEffect(() => {
        if (!props.registrationError && props.user) {
            setSuccessMsg(true);
        } else if (props.registrationError) {
            setIsLoading(false);
            setErrorMsg(Object.values(props.registrationError.errors)[0]);
        }
    }, [props]);

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    {errorMsg ?
                        <SweetAlert
                            title="Error!"
                            danger
                            showConfirm={true}
                            showCancel={false}
                            confirmBtnBsStyle="danger"
                            closeOnConfirm={true}
                            onConfirm={() => {
                                setErrorMsg(false);
                            }}
                        >
                            {errorMsg}
                        </SweetAlert> : ''
                    }
                    {successMsg ? <SweetAlert
                        title="Registration successful!"
                        success
                        showConfirm={true}
                        showCancel={false}
                        confirmBtnBsStyle="yellow"
                        onConfirm={() => {
                            history.push('/login');
                        }}
                    >
                        Thank you for your registration. Your registration will be checked and approved.
                    </SweetAlert> : ''
                    }
                    <Row className="justify-content-center">
                        <Col md={10}>
                            <Card className="overflow-hidden">
                                <div className="bg-dark mb-4 p-4">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="px-4">
                                                <h3 className="text-yellow mb-0">Brother TECNET</h3>
                                                <p className="text-white">BIMH Dealer Service Platform</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-center text-right">
                                            <div className="px-4">
                                                <img src={logoImg} alt="" className="img-fluid"/>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody>
                                    <AvForm
                                        className="form-horizontal"
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v)
                                        }}
                                    >
                                        {/*{props.user ? (*/}
                                        {/*    <Alert color="success">*/}
                                        {/*        Register User Successfully*/}
                                        {/*    </Alert>*/}
                                        {/*) : null}*/}
                                        {/*{props.registrationError ? (*/}
                                        {/*    <Alert color="danger">*/}
                                        {/*        {props.registrationError}*/}
                                        {/*    </Alert>*/}
                                        {/*) : null}*/}

                                        <Row>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <AvField
                                                        name="firstname"
                                                        label="Firstname"
                                                        type="text"
                                                        required
                                                        placeholder="Enter firstname"
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: 'Please enter your firstname'
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <AvField
                                                        name="lastname"
                                                        label="Lastname"
                                                        type="text"
                                                        required
                                                        placeholder="Enter lastname"
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: 'Please enter your lastname'
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <Label htmlFor="position"
                                                           className="col-form-label">Position</Label>
                                                    <AvSelect
                                                        name="position"
                                                        options={positionList}
                                                        className="form-control"
                                                        helpMessage="Select a position *"
                                                        raw={true}
                                                        validate={{required: {value: true}}}
                                                        errorMessage="Please choose a valid option."
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <Label htmlFor="company"
                                                           className="col-form-label">Company</Label>
                                                    <ListDealers onChange={(dealer) => {
                                                        handleSelectDealer(dealer);
                                                    }}/>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <AvField
                                                        name="email"
                                                        label="Business Email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        required
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: 'Please enter your email'
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <AvField
                                                        name="phone"
                                                        label="Phone number"
                                                        className="form-control"
                                                        placeholder="Enter phone number"
                                                        type="text"
                                                        required
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: 'Please enter your phone number'
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <AvField
                                                        name="password"
                                                        label="Password"
                                                        type="password"
                                                        required
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: 'Please enter a password'
                                                            },
                                                            minLength: {
                                                                value: 6,
                                                                errorMessage: 'Your name must be between 6 and 16 characters'
                                                            },
                                                            maxLength: {
                                                                value: 16,
                                                                errorMessage: 'Your name must be between 6 and 16 characters'
                                                            }
                                                        }}
                                                        placeholder="Enter Password"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <AvField
                                                        name="password_confirmation"
                                                        label="Confirm Password"
                                                        type="password"
                                                        required
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: 'Please enter a password'
                                                            },
                                                            minLength: {
                                                                value: 6,
                                                                errorMessage: 'Your name must be between 6 and 16 characters'
                                                            },
                                                            maxLength: {
                                                                value: 16,
                                                                errorMessage: 'Your name must be between 6 and 16 characters'
                                                            }
                                                        }}
                                                        placeholder="Confirm Password"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="mt-4">
                                            {isLoading ?
                                                <Button
                                                    className="btn btn-dark btn-block waves-effect waves-light"
                                                    type="submit" disabled={true}>
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i> Register
                                                </Button> :
                                                <Button
                                                    className="btn btn-dark btn-block waves-effect waves-light"
                                                    type="submit">
                                                    Register
                                                </Button>
                                            }
                                        </div>

                                        <div className="mt-4 text-center font-size-13">
                                            <p className="mb-0">
                                                By registering you agree to the {" "}
                                                <a href="https://www.brother-ism.com/terms-of-use.html"
                                                   className="font-weight-bold text-yellow">
                                                    Terms of Use
                                                </a>
                                            </p>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center font-size-13">
                                <p>
                                    Already have an account ?{" "}
                                    <Link
                                        to="/login"
                                        className="font-weight-bold text-yellow"
                                    >
                                        {" "}
                                        Login
                                    </Link>{" "}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {user, registrationError, loading} = state.Account;
    return {user, registrationError, loading};
};

export default connect(mapStatetoProps, {registerUser, registerUserFailed})(Register);
