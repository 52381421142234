import {
	CREATE_DEALER_ERROR,
	CREATE_DEALER_REQUEST,
	CREATE_DEALER_SUCCESS,
	EDIT_DEALER_ERROR,
	EDIT_DEALER_REQUEST,
	EDIT_DEALER_SUCCESS,
	GET_DEALER_ERROR,
	GET_DEALER_REQUEST,
	GET_DEALER_SUCCESS
} from "./actionTypes";

export const getDealer = (dealerId: number) => ({
	type: GET_DEALER_REQUEST,
	payload: dealerId
});

export const getDealerSuccess = (data) => {
	return{
		type: GET_DEALER_SUCCESS,
		payload: data
	}
};

export const getDealerError = (error) => {
	return{
		type: GET_DEALER_ERROR,
		payload: error
	}
};

export const createDealer = (dealer: CreateAPIDealer) => ({
	type: CREATE_DEALER_REQUEST,
	payload: dealer
});

export const createDealerSuccess = (dealer: Dealer) => {
	return {
		type: CREATE_DEALER_SUCCESS,
		payload: dealer
	}
};

export const createDealerError = (error) => {
	return {
		type: CREATE_DEALER_ERROR,
		payload: error
	}
};

export const editDealer = (dealer: EditAPIDealer) => ({
	type: EDIT_DEALER_REQUEST,
	payload: dealer
})

export const editDealerSuccess = (dealer: Dealer) => {
	return {
		type: EDIT_DEALER_SUCCESS,
		payload: dealer
	}
};

export const editDealerError = (error) => {
	return {
		type: EDIT_DEALER_ERROR,
		payload: error
	}
};
