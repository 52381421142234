import React from "react";
import {AvField} from "availity-reactstrap-validation";

const Vrate = (props) => {

    return (
        <React.Fragment>
            <AvField
                name={props.name}
                className="inner form-control"
                placeholder={props.placeholder}
                type="text"
                validate={{required: {value: true}}}
                errorMessage="Please provide a valid V.Rating."
            />
        </React.Fragment>
    );
}

export default Vrate;
