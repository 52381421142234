// @flow
import {
	CREATE_DEALER_SUCCESS,
	GET_DEALER_SUCCESS
} from './actionTypes';

const initialState = {}

const Dealer = (state = initialState, action) => {
	switch (action.type) {
		case GET_DEALER_SUCCESS:
			return action.payload
		case CREATE_DEALER_SUCCESS:
			return {
				data: action.payload
			};

		default:
			return state;
	}
};

export default Dealer;
