import React, { FC, useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button
} from "reactstrap";
import {Link} from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {getLoggedInUser} from "../../helpers/fakebackend_helper";
import { getOvertakesSelector } from '../../selectors/overtake';
import { useDispatch, useSelector } from "react-redux";
import { getOvertakes } from '../../store/overtakes/actions';
import { OvertakeList } from '../../components/Overtakes/OvertakesList';
import { ROLE, RoleNameMap } from '../../components/Role/constants';
import { createLoadingSelector } from '../../selectors/loading';
import { GET_OVERTAKES } from '../../store/overtakes/actionTypes';

const OvertakesOverview: FC = () => {

    const overtakes = useSelector(getOvertakesSelector);
    const dispatch = useDispatch();

    const authUser = getLoggedInUser();
    const userRole = RoleNameMap.get(authUser.user.role) || ROLE.NONE;

    const overtakesLoadingSelector = useSelector(createLoadingSelector([GET_OVERTAKES]));
    const [overtakesLoading, setOvertakesLoading] = useState(true);

    useEffect(() => {
        setOvertakesLoading(overtakesLoadingSelector);
    }, [overtakesLoadingSelector]);

    useEffect(() => {
        dispatch(getOvertakes())
    }, [dispatch]);

    return (
        <div className="page-content">
            <Container fluid>

                <Breadcrumbs title="Printer Overtakes" breadcrumbItem="Overview"/>

                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                {[ROLE.BROTHER_MANAGER, ROLE.BROTHER_STAFF].includes(userRole) &&
                                <>
                                    <div className="text-sm-right">
                                        <Link to={{
                                            pathname: "/overtake-printer",
                                        }}>
                                            <Button type="button" color="yellow"
                                                    className="btn-rounded waves-effect waves-light mr-2">
                                                <i className="mdi mdi-plus mr-1"/>
                                                Overtake Printer
                                            </Button>
                                        </Link>
                                    </div>
                                </>
                                }
                                <OvertakeList
                                    overtakes={overtakes}
                                    isLoading={overtakesLoading}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default OvertakesOverview;
