import React, { FC } from 'react';
import { CountButton } from '../../Common/CountButton';

// @TODO add ordering?
export interface Filter {
    label: string,
    active?: boolean,
    method: (any) => void,
    itemCount: number
}

interface FilterGroupProps {
    filters: Filter[],
    isLoading?: boolean,
}

export const FilterGroup: FC<FilterGroupProps> = ({
    filters,
    isLoading
}) => {

    return (
        <div className="mt-2 mb-1 d-flex justify-content-between">
            {filters.map((filter, index) => (
                <CountButton
                    key={index}
                    active={filter.active}
                    count={filter.itemCount}
                    onClick={filter.method}
                    isLoading={isLoading && filters.every(filter => filter.itemCount === 0)}
                >
                    {filter.label}
                </CountButton>
            ))}
        </div>
    );
}
