import React from "react";
import {Alert, Col, FormGroup, Label, Row} from "reactstrap";
import {AvField} from "availity-reactstrap-validation";
import Printhead from "./printhead";

const Gt361 = (props) => {

        return (
            <React.Fragment>
                <FormGroup className="mb-4">
                    <Label className="col-form-label">Print Head Serials</Label>
                    <Alert color="danger" role="alert">
                        Please note: the following print head serial numbers are the original print heads that are installed with the installation
                    </Alert>
                    <table style={{width: "100%"}}>
                        <tbody>
                        <tr>
                            <td>
                                <Row className="mb-2 px-2">
                                    <Label md="2"
                                           className="col-form-label">PH C
                                        Serial*</Label>
                                    <Col md="4">
                                        <Printhead example="AGT1234" name="print_heads[gt361][0][serial]" placeholder="PH C Serial *" />
                                        <AvField
                                            name="print_heads[gt361][0][position]"
                                            value="c"
                                            type="text"
                                            hidden={true}
                                        />
                                        <AvField
                                            name="print_heads[gt361][0][color]"
                                            value="color"
                                            type="text"
                                            hidden={true}
                                        />
                                        <AvField
                                            name="print_heads[gt361][0][vrate]"
                                            value=""
                                            type="text"
                                            hidden={true}
                                        />
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Row className="mb-2 px-2">
                                    <Label md="2"
                                           className="col-form-label">PH M
                                        Serial*</Label>
                                    <Col md="4">
                                        <Printhead example="AGT1234" name="print_heads[gt361][1][serial]" placeholder="PH M Serial *" />
                                        <AvField
                                            name="print_heads[gt361][1][position]"
                                            value="m"
                                            type="text"
                                            hidden={true}
                                        />
                                        <AvField
                                            name="print_heads[gt361][1][color]"
                                            value="color"
                                            type="text"
                                            hidden={true}
                                        />
                                        <AvField
                                            name="print_heads[gt361][1][vrate]"
                                            value=""
                                            type="text"
                                            hidden={true}
                                        />
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Row className="mb-2 px-2">
                                    <Label md="2"
                                           className="col-form-label">PH Y
                                        Serial*</Label>
                                    <Col md="4">
                                        <Printhead example="AGT1234" name="print_heads[gt361][2][serial]" placeholder="PH Y Serial *" />
                                        <AvField
                                            name="print_heads[gt361][2][position]"
                                            value="y"
                                            type="text"
                                            hidden={true}
                                        />
                                        <AvField
                                            name="print_heads[gt361][2][color]"
                                            value="color"
                                            type="text"
                                            hidden={true}
                                        />
                                        <AvField
                                            name="print_heads[gt361][2][vrate]"
                                            value=""
                                            type="text"
                                            hidden={true}
                                        />
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Row className="mb-2 px-2">
                                    <Label md="2"
                                           className="col-form-label">PH K
                                        Serial*</Label>
                                    <Col md="4">
                                        <Printhead example="AGT1234" name="print_heads[gt361][3][serial]" placeholder="PH K Serial *" />
                                        <AvField
                                            name="print_heads[gt361][3][position]"
                                            value="k"
                                            type="text"
                                            hidden={true}
                                        />
                                        <AvField
                                            name="print_heads[gt361][3][color]"
                                            value="color"
                                            type="text"
                                            hidden={true}
                                        />
                                        <AvField
                                            name="print_heads[gt361][3][vrate]"
                                            value=""
                                            type="text"
                                            hidden={true}
                                        />
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Row className="mb-2 px-2">
                                    <Label md="2"
                                           className="col-form-label">PH White
                                        Serial*</Label>
                                    <Col md="4">
                                        <Printhead example="AGT1234" name="print_heads[gt361][4][serial]" placeholder="PH White Serial *" />
                                        <AvField
                                            name="print_heads[gt361][4][position]"
                                            value="w1"
                                            type="text"
                                            hidden={true}
                                        />
                                        <AvField
                                            name="print_heads[gt361][4][color]"
                                            value="white"
                                            type="text"
                                            hidden={true}
                                        />
                                        <AvField
                                            name="print_heads[gt361][4][vrate]"
                                            value=""
                                            type="text"
                                            hidden={true}
                                        />
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Row className="mb-2 px-2">
                                    <Label md="2"
                                           className="col-form-label">PH White
                                        Serial*</Label>
                                    <Col md="4">
                                        <Printhead example="AGT1234" name="print_heads[gt361][5][serial]" placeholder="PH White Serial *" />
                                        <AvField
                                            name="print_heads['gt361'][5][position]"
                                            value="w2"
                                            type="text"
                                            hidden={true}
                                        />
                                        <AvField
                                            name="print_heads[gt361][5][color]"
                                            value="white"
                                            type="text"
                                            hidden={true}
                                        />
                                        <AvField
                                            name="print_heads[gt361][5][vrate]"
                                            value=""
                                            type="text"
                                            hidden={true}
                                        />
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </FormGroup>
            </React.Fragment>
        );
}

export default Gt361;
