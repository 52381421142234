import React, {useState} from "react";

import {connect} from "react-redux";

import {Link} from "react-router-dom";

// Redux Store
import {showRightSidebarAction, toggleLeftmenu} from "../../store/actions";
// reactstrap

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../assets/images/logo.svg";

//i18n
import {withNamespaces} from 'react-i18next';
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";

const Header = (props) => {

    const [isSearch, setSearch] = useState(false);

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to="/" className="logo">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22"/>
                  </span>
                                <span className="logo-lg">
                    <img src={logo} alt="" height="17"/>
                  </span>
                            </Link>
                        </div>

                        <button
                            type="button"
                            className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                            data-toggle="collapse"
                            onClick={() => {
                                props.toggleLeftmenu(!props.leftMenu);
                            }}
                            data-target="#topnav-menu-content">
                            <i className="fa fa-fw fa-bars"></i>
                        </button>
                    </div>

                    <div className="d-flex">

                        <Link to={"/downloads"}>
                            <button
                                type="button"
                                className="btn btn-sm header-item waves-effect waves-light d-flex align-items-center">
                                <i className="mdi mdi-cloud-download font-size-16"></i>
                                <span className="font-size-13 ml-2">Software for Dealer</span>
                            </button>
                        </Link>

                        <Link to={"/helpcenter"}>
                            <button
                                type="button"
                                className="btn btn-sm font-size-13 header-item waves-effect waves-light d-flex align-items-center">
                                <i className="fa fa-fw fa-question-circle font-size-16"></i>
                                <span className="font-size-13 ml-2">Helpcenter</span>
                            </button>
                        </Link>

                        <NotificationDropdown />
                        <ProfileMenu/>

                        {/*<div className="dropdown d-inline-block">*/}
                        {/*  <button*/}
                        {/*    onClick={() => { props.showRightSidebarAction(!props.showRightSidebar); }}*/}
                        {/*    type="button"*/}
                        {/*    className="btn header-item noti-icon right-bar-toggle waves-effect" >*/}
                        {/*    <i className="bx bx-cog bx-spin"></i>*/}
                        {/*  </button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {layoutType, showRightSidebar, leftMenu} = state.Layout;
    return {layoutType, showRightSidebar, leftMenu};
};

export default connect(mapStatetoProps, {showRightSidebarAction, toggleLeftmenu})(withNamespaces()(Header));


