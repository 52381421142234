import React, { FC } from "react";
import { MDBBadge } from 'mdbreact';
import { getLoggedInUser } from '../../../helpers/fakebackend_helper';
import { ROLE, RoleNameMap } from '../../Role/constants';
import { CLAIM_FINAL_CHECK_STATUS, CLAIM_INTERNAL_CHECK_STATUS, CLAIM_STATUS } from '../constants';

interface ClaimStatusProps {
    status: CLAIM_STATUS,
    final_check_status: CLAIM_FINAL_CHECK_STATUS,
    internal_check_status: CLAIM_INTERNAL_CHECK_STATUS,
    fontsize?: string
}

const ClaimStatus: FC<ClaimStatusProps> = (
    {
        status,
        final_check_status,
        internal_check_status,
        fontsize
    }) => {

    const authUser = getLoggedInUser(); // @TODO get the user from selector
    const userRole = RoleNameMap.get(authUser.user.role);

    // @TODO maybe refactor to make it more readable?
    return (
        <>
            {status === CLAIM_STATUS.OPEN ?
                <MDBBadge pill color={'soft-success'} className={fontsize}>
                    Open
                </MDBBadge> : ''
            }
            {status === CLAIM_STATUS.IN_PROGRESS ?
                <MDBBadge pill color={'soft-warning'} className={fontsize}>
                    In Progress
                </MDBBadge> : ''
            }
            {status === CLAIM_STATUS.FINAL_CHECK && (final_check_status === CLAIM_FINAL_CHECK_STATUS.GRANTED) ?
                <MDBBadge pill color={'soft-success'} className={fontsize}>
                    Guarantee granted
                </MDBBadge> : ''
            }
            {status === CLAIM_STATUS.FINAL_CHECK && final_check_status === CLAIM_FINAL_CHECK_STATUS.INTERNAL_GRANTED && (userRole === ROLE.BROTHER_MANAGER || userRole === ROLE.BROTHER_STAFF || userRole === ROLE.BROTHER_SALES) ?
                <MDBBadge pill color={'soft-success'} className={fontsize}>
                    Internal granted
                </MDBBadge> : ''
            }
            {status === CLAIM_STATUS.FINAL_CHECK && final_check_status === CLAIM_FINAL_CHECK_STATUS.INTERNAL_GRANTED && userRole === ROLE.DEALER_ENGINEER ?
                <MDBBadge pill color={'soft-success'} className={fontsize}>
                    Guarantee granted
                </MDBBadge> : ''
            }
            {status === CLAIM_STATUS.FINAL_CHECK && final_check_status === CLAIM_FINAL_CHECK_STATUS.NOT_GRANTED ?
                <MDBBadge pill color={'soft-danger'} className={fontsize}>
                    Guarantee not granted
                </MDBBadge> : ''
            }
            {(userRole === ROLE.BROTHER_MANAGER || userRole === ROLE.BROTHER_STAFF || userRole === ROLE.BROTHER_SALES) && internal_check_status !== CLAIM_INTERNAL_CHECK_STATUS.NONE ?
                <MDBBadge pill color={'soft-success'} className={fontsize}>
                    Internal checked
                </MDBBadge> : ''
            }
        </>
    );
}

export default ClaimStatus;
