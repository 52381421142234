import {
	GET_USER_NOTIFICATIONS_ERROR,
	GET_USER_NOTIFICATIONS_REQUEST,
	GET_USER_NOTIFICATIONS_SUCCESS,
	SET_USER_NOTIFICATIONS_SEEN,
	SET_USER_NOTIFICATIONS_SEEN_SUCCESS,
	SET_USER_NOTIFICATIONS_SEEN_ERROR
} from "./actionTypes";

export const getUserNotifications = () => ({
	type: GET_USER_NOTIFICATIONS_REQUEST
});

export const getUserNotificationsSuccess = (data) => {
	return{
		type: GET_USER_NOTIFICATIONS_SUCCESS,
		payload: data
	}
};

export const getUserNotificationsError = (error) => {
	return{
		type: GET_USER_NOTIFICATIONS_ERROR,
		payload: error
	}
};

export const setUserNotificationsSeen = () => {
	return{
		type: SET_USER_NOTIFICATIONS_SEEN,
	}
};

export const setUserNotificationsSeenSuccess = (data) => {
	return{
		type: SET_USER_NOTIFICATIONS_SEEN_SUCCESS,
		payload: data
	}
};

export const setUserNotificationsSeenError = (error) => {
	return{
		type: SET_USER_NOTIFICATIONS_SEEN_ERROR,
		payload: error
	}
};
