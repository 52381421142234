import {
	GET_CUSTOMER_REQUEST,
	GET_CUSTOMER_SUCCESS,
	GET_CUSTOMER_ERROR,
	CREATE_CUSTOMER_REQUEST,
	CREATE_CUSTOMER_SUCCESS,
	CREATE_CUSTOMER_ERROR,
	EDIT_CUSTOMER_REQUEST,
	EDIT_CUSTOMER_SUCCESS,
	EDIT_CUSTOMER_ERROR,
} from "./actionTypes";

export const getCustomer = (customerId: number) => ({
	type: GET_CUSTOMER_REQUEST,
	payload: customerId
});

export const getCustomerSuccess = (data) => {
	return {
		type: GET_CUSTOMER_SUCCESS,
		payload: data
	}
};

export const getCustomerError = (error) => {
	return {
		type: GET_CUSTOMER_ERROR,
		payload: error
	}
};

export const createCustomer = (customer: CreateAPICustomer) => ({
	type: CREATE_CUSTOMER_REQUEST,
	payload: customer
});

export const createCustomerSuccess = (customer: Customer) => {
	return {
		type: CREATE_CUSTOMER_SUCCESS,
		payload: customer
	}
};

export const createCustomerError = (error) => {
	return {
		type: CREATE_CUSTOMER_ERROR,
		payload: error
	}
};

export const editCustomer = (customer: EditAPICustomer) => {
	return {
		type: EDIT_CUSTOMER_REQUEST,
		payload: customer
	}
};

export const editCustomerSuccess = (customer: Customer) => {
	return {
		type: EDIT_CUSTOMER_SUCCESS,
		payload: customer
	}
};

export const editCustomerError = (error) => {
	return {
		type: EDIT_CUSTOMER_ERROR,
		payload: error
	}
};
