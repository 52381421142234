import React, {useState} from "react";
import {AvField} from "availity-reactstrap-validation";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import axios from "axios";

const Printhead = (props) => {

    const [isValid, setIsValid] = useState(true);

    const example = props.example ? props.example : "ABC1234";
    const pattern = props.pattern ? props.pattern : '^[A-Z]{3}[0-9]+$';

    function validatePrintheadSerial(printheadSerial) {

        if (printheadSerial.length === 7) {

            const url = process.env.REACT_APP_APIURL + 'printhead/checkduplicate';
            const authToken = authHeader();

            const formData = new FormData();
            formData.append('serial', printheadSerial);

            return axios.post(url, formData, {headers: authToken}).then(response => {
                setIsValid(response.status === 201);
            }).catch(error => {
                setIsValid(false);
            });
        }
    }

    return (
        <React.Fragment>
            <AvField
                name={props.name}
                className="inner form-control"
                label={props.label}
                placeholder={props.placeholder}
                type="text"
                validate={{
                    required: {value: true},
                    pattern: {value: pattern},
                    minLength: {value: 7},
                    maxLength: {value: 7},
                    async: (value, ctx, input, cb) => {
                        cb(isValid);
                    }
                }}
                onChange={(e) => validatePrintheadSerial(e.target.value)}
                onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                errorMessage={!isValid ? 'This serial number already exists!' : 'Please provide a valid serial number'}
                helpMessage={"Example: " + example}
            />
        </React.Fragment>
    );
}

export default Printhead;
