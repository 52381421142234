import {
      CLAIM_FINAL_CHECK_STATUS,
      CLAIM_REPLACEMENT_TYPE,
      CLAIM_STATUS
} from '../components/Claim/constants';

/**
 * @description selector to get all claims.
 * @param state
 * @return {*}
 */
export const getClaimsSelector: (state: any) => Claim[] = state => (state?.Claims?.data ? state.Claims.data : []);

/**
 * Selector to get a list of open claims
 * @param {object} state Entire state.
 */
export const getOpenClaimsSelector: (state: any) => Claim[] =
  state => (state?.Claims?.data ? state.Claims.data : [])
      .filter(claim => claim && claim.status === CLAIM_STATUS.OPEN);

/**
 * Selector to get a list of in progress claims
 * @param {object} state Entire state.
 */
export const getInProgressClaimsSelector: (state: any) => Claim[] =
  state => ((state.Claims && state.Claims.data) ? state.Claims.data : [])
      .filter(claim => claim && claim.status === CLAIM_STATUS.IN_PROGRESS);

/**
 * Selector to get a list of claims with specific final check status granted
 * @param {object} state Entire state.
 */
export const getGuaranteeGrantedClaimsSelector: (state: any) => Claim[] =
  state => ((state.Claims && state.Claims.data) ? state.Claims.data : [])
      .filter(claim =>
          claim && claim.status === CLAIM_STATUS.FINAL_CHECK && claim.final_check_status === CLAIM_FINAL_CHECK_STATUS.GRANTED
      );

/**
 * Selector to get a list of claims with specific final check status internal granted
 * @param {object} state Entire state.
 */
export const getInternalGrantedClaimsSelector: (state: any) => Claim[] =
  state => ((state.Claims && state.Claims.data) ? state.Claims.data : [])
      .filter(claim =>
          claim && claim.status === CLAIM_STATUS.FINAL_CHECK && claim.final_check_status === CLAIM_FINAL_CHECK_STATUS.INTERNAL_GRANTED
      );

/**
 * Selector to get a list of claims with final check status granted and internal granted
 * @param {object} state Entire state.
 */
export const getAllGrantedClaimsSelector: (state: any) => Claim[] =
  state => ((state.Claims && state.Claims.data) ? state.Claims.data : [])
      .filter(claim =>
          claim && claim.status === CLAIM_STATUS.FINAL_CHECK && (claim.final_check_status === CLAIM_FINAL_CHECK_STATUS.GRANTED || claim.final_check_status === CLAIM_FINAL_CHECK_STATUS.INTERNAL_GRANTED)
      );

/**
 * Selector to get a list of claims with specific final check status not granted
 * @param {object} state Entire state.
 */
export const getNotGrantedClaimsSelector: (state: any) => Claim[] =
  state => ((state.Claims && state.Claims.data) ? state.Claims.data : [])
      .filter(claim =>
          claim && claim.status === CLAIM_STATUS.FINAL_CHECK && claim.final_check_status === CLAIM_FINAL_CHECK_STATUS.NOT_GRANTED
      );

/**
 * Selector to get a list of claims with replacement type print head
 * @param {object} state Entire state.
 */
export const getPrintHeadClaimsSelector: (state: any) => Claim[] =
  state => ((state.Claims && state.Claims.data) ? state.Claims.data : [])
      .filter(claim =>
          claim && claim.replacement_type === CLAIM_REPLACEMENT_TYPE.PRINT_HEAD
      );

/**
 * Selector to get a list of claims with replacement type print head
 * @param {object} state Entire state.
 */
export const getOtherPartClaimsSelector: (state: any) => Claim[] =
  state => ((state.Claims && state.Claims.data) ? state.Claims.data : [])
      .filter(claim =>
          claim && claim.replacement_type === CLAIM_REPLACEMENT_TYPE.OTHER_PART
      );
