// @flow
import {
	GET_CUSTOMER_SUCCESS,
	CREATE_CUSTOMER_SUCCESS,
} from "./actionTypes";

const initialState = {}

const Customer = (state = initialState, action) => {
	switch (action.type) {
		case GET_CUSTOMER_SUCCESS:
			return action.payload;
		case CREATE_CUSTOMER_SUCCESS:
			return {
				data: action.payload
			};

		default:
			return state;
	}
};

export default Customer;
