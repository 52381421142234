// This is just for the Loading or Error Selector
export const GET_ENGINEER = "GET_ENGINEER";

// Use these for Actions
export const GET_ENGINEER_REQUEST = "GET_ENGINEER_REQUEST";
export const GET_ENGINEER_SUCCESS = "GET_ENGINEER_SUCCESS";
export const GET_ENGINEER_ERROR = "GET_ENGINEER_ERROR";

// This is just for the Loading or Error Selector
export const CREATE_ENGINEER = "CREATE_ENGINEER";

// Use these for Actions
export const CREATE_ENGINEER_REQUEST = "CREATE_ENGINEER_REQUEST";
export const CREATE_ENGINEER_SUCCESS = "CREATE_ENGINEER_SUCCESS";
export const CREATE_ENGINEER_ERROR = "CREATE_ENGINEER_ERROR";
