import React from "react";
import {Route, Redirect, withRouter} from "react-router-dom";
import {getLoggedInUser} from "../../helpers/fakebackend_helper";
import DispatchOverview from "../../pages/Dispatch/dispatch-overview";
import SessionTimeout from "../../pages/Authentication/SessionTimeout";

const Authmiddleware = ({
                            component: Component,
                            layout: Layout,
                            requiredRoles,
                        }) => (
    <Route
        render={props => {
            const authUser = getLoggedInUser();

            if (!authUser || !authUser.user) {
                return (
                    <Redirect to={{pathname: "/login", state: {from: props.location}}}/>
                );
            }

            if (authUser && authUser.user && requiredRoles && requiredRoles.indexOf(authUser.user.role) === -1) {
                // role not authorised so redirect to dashboard
                return <Redirect to={{pathname: '/dashboard'}}/>
            }

            if (authUser && authUser.user && authUser.user.role === "dispatcher") {
                return (
                    <Layout>
                        <SessionTimeout/>
                        <DispatchOverview />
                    </Layout>
                )
            }

            return (
                <Layout>
                    <SessionTimeout/>
                    <Component {...props} />
                </Layout>
            );
        }}
    />
);

export default withRouter(Authmiddleware);

