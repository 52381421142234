import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Col,
    Button,
    Label,
    Row,
} from "reactstrap";
import { AvForm, AvField} from 'availity-reactstrap-validation';
import { createLoadingSelector } from '../../../selectors/loading';
import { createErrorSelector } from '../../../selectors/error';
import { EDIT_DEALER } from '../../../store/dealer/actionTypes';
import { useHistory } from 'react-router-dom';
import { editDealer } from '../../../store/dealer/actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import ListCountries from '../../Lists/ListCountries';

interface DealerFormProps {
    dealer: FullDealer,
}

export const DealerForm: FC<DealerFormProps> = (
    {
        dealer: {
            id,
            name,
            street,
            street_no,
            zip_code,
            city,
            country,
            phone,
            email,
            can_see_gtx600,
        }
    }) => {

    const dispatch = useDispatch();

    const loadingSelector = useSelector(createLoadingSelector([EDIT_DEALER]));
    const errorSelector = useSelector(createErrorSelector([EDIT_DEALER]));

    const [errorMsg, setErrorMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState({name: ""});
    const [submitted, setSubmitted] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (submitted) {
            setSuccessMsg(!loadingSelector && errorSelector.length === 0);
            setErrorMsg(!loadingSelector && errorSelector.length > 0);
        }
    }, [loadingSelector, errorSelector, submitted]);

    function handleSelectCountry(selectedCountry) {
        setSelectedCountry(selectedCountry);
    }

    function handleInvalidSubmit(event, values) {
        //    @TODO handle invalid submit
    }

    function handleValidSubmit(event, values) {
        setSubmitted(true);
        if (id) {
            const editedDealer: EditAPIDealer = {
                id: id,
                name: values.name,
                street: values.street,
                street_no: values.street_no,
                zip_code: values.zip_code,
                city: values.city,
                country: selectedCountry.name,
                phone: values.phone,
                email: values.email,
                can_see_gtx600: values.can_see_gtx600,
            }
            dispatch(editDealer(editedDealer));
        } else {
            handleInvalidSubmit(event, values);
        }
    }

    return (
        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
        }}>
            {errorMsg ?
                <SweetAlert
                    title="Error!"
                    danger
                    showConfirm={true}
                    showCancel={false}
                    confirmBtnBsStyle="danger"
                    // closeOnConfirm={true}
                    onConfirm={() => {
                        setErrorMsg(false);
                    }}
                >
                    {errorMsg}
                </SweetAlert> : ''
            }
            {successMsg ? <SweetAlert
                title="Good job!"
                success
                showConfirm={true}
                showCancel={false}
                confirmBtnBsStyle="yellow"
                onConfirm={() => {
                    history.push('/dealers');
                }}
            >
                The dealer has been updated!
            </SweetAlert> : null}
            <Row>
                <Col md="6">
                    <AvField type="checkbox" name="can_see_gtx600" label="GTX600" value={can_see_gtx600 ? true : false} />
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <AvField
                        name="name"
                        value={name || ""}
                        className="form-control"
                        placeholder="Name *"
                        label="Name"
                        type="text"
                        validate={{required: {value: true}}}
                        errorMessage="Please provide a valid name."
                    />
                </Col>
            </Row>
            <Row>
                <Col md="3">
                    <AvField
                        name="street"
                        value={street || ""}
                        className="form-control"
                        placeholder="Street *"
                        label="Street"
                        type="text"
                        validate={{required: {value: true}}}
                        errorMessage="Please provide a valid street."
                    />
                </Col>
                <Col md="2">
                    <AvField
                        name="street_no"
                        value={street_no || ""}
                        className="form-control"
                        placeholder="Street No *"
                        label="Street No"
                        type="text"
                        validate={{required: {value: true}}}
                        errorMessage="Please provide a valid street no."
                    />
                </Col>
                <Col md="2">
                    <AvField
                        name="zip_code"
                        value={zip_code || ""}
                        className="form-control"
                        placeholder="Zip Code *"
                        label="Zip Code"
                        type="text"
                        validate={{required: {value: true}}}
                        errorMessage="Please provide a valid zip code."
                    />
                </Col>
                <Col md="3">
                    <AvField
                        name="city"
                        value={city || ""}
                        className="form-control"
                        placeholder="City *"
                        label="City"
                        type="text"
                        validate={{required: {value: true}}}
                        errorMessage="Please provide a valid city."
                    />
                </Col>
                <Col md="3">
                    <Label htmlFor="country">Country</Label>
                    <ListCountries
                        initialSelect={country}
                        onChange={(country) => {
                            handleSelectCountry(country);
                        }}
                        required={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="3">
                    <AvField
                        name="phone"
                        value={phone || ""}
                        className="form-control"
                        placeholder="Phone *"
                        label="Phone"
                        type="text"
                        validate={{required: {value: true}}}
                        errorMessage="Please provide a valid phone number."
                    />
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <AvField
                        name="email"
                        value={email || ""}
                        className="form-control"
                        placeholder="Email Address*"
                        label="Email"
                        type="text"
                        validate={{required: {value: true}}}
                        errorMessage="Please provide a valid email address."
                    />
                </Col>
            </Row>

            <Button
                disabled={loadingSelector}
                type="submit"
                color="primary"
            >
                Save
            </Button>
        </AvForm>
    );
}
