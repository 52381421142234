import axios from "axios";
import authHeader from "../helpers/jwt-token-access/auth-token-header";

const getPrinterFromBackend = (printerSerial) => {
    const url = process.env.REACT_APP_APIURL + 'printer/' + printerSerial;
    const authToken = authHeader();

    return axios.get(url, {headers: authToken}).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

const createPrinterInBackend = (printer) => {
    const url = process.env.REACT_APP_APIURL + 'printer';
    const authToken = authHeader();
    const formData = new FormData();
    formData.append('file', printer.file);
    formData.append('data', JSON.stringify(printer));

    return axios.post(url, formData,{headers: authToken}).then(response => {
        // if (response.status === 422 || response.status === 500)
        //     return response.data;
        return response.data;
    }).catch(error => {
        // throw err[1];
        return error.response.data;
    });
}

// const createPrinterInBackend = async (printer) => {
//     try {
//         const authToken = authHeader();
//         const formData = new FormData();
//         // printer.files.forEach((file) => {
//         //     formData.append('file[]', file);
//         // });
//         formData.append('file', printer.file);
//         formData.append('data', JSON.stringify(printer));
//
//         const response = await fetch(process.env.REACT_APP_APIURL + 'printer', {
//             method: 'post',
//             headers: {
//                 'Accept': 'application/json, text/plain, */*',
//                 'Authorization': authToken.Authorization,
//             },
//             body: formData
//         });
//         return await response.json();
//     } catch (e) {
//         console.log(e);
//     }
// }

// const validatePrintheadSerial = (printheadSerial) => {
// // function validatePrintheadSerial(printheadSerial) {
//     if (printheadSerial.length === 7) {
//
//         const url = process.env.REACT_APP_APIURL + 'printhead/checkduplicate';
//         const authToken = authHeader();
//
//         const formData = new FormData();
//         formData.append('serial', printheadSerial);
//
//         return axios.post(url, formData,{headers: authToken}).then(response => {
//             return response.status === 201;
//         }).catch(error => {
//             return false;
//         });
//     }
// }

export {getPrinterFromBackend, createPrinterInBackend}