import axios from 'axios';
import authHeader from "../helpers/jwt-token-access/auth-token-header";

const getUserNotificationsFromBackend = () => {
    const url = process.env.REACT_APP_APIURL + 'user_notifications';
    const authToken = authHeader();

    return axios.get(url, {headers: authToken}).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

const setUserNotificationsSeenInBackend = () => {
    const url = process.env.REACT_APP_APIURL + 'user_notifications/seen';
    const authToken = authHeader();

    return axios.post(url, null, {headers: authToken}).then(response => {
    }).catch(error => {
    });
}

export {getUserNotificationsFromBackend,setUserNotificationsSeenInBackend}