import React from "react";
import { Redirect } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPasswordPage from "../pages/Authentication/ResetPassword";

  // Dashboard
import Dashboard from "../pages/Dashboard/index";

// Claims
import ClaimsOverview from "../pages/Claims/claims-overview";
import ClaimShow from "../pages/Claims/claim-show";
import ClaimAdd from "../pages/Claims/claim-add";

// Customers
import CustomerOverview from "../pages/Customers/customers-overview";
import CustomerShow from "../pages/Customers/customer-show";
import CustomerAdd from "../pages/Customers/customer-add";

// Dispatch
import DispatchOverview from "../pages/Dispatch/dispatch-overview";

// Services
import ServicesOverview from "../pages/Services/services-overview";
import ServiceAdd from "../pages/Services/service-add";

// Dealers
import DealersOverview from "../pages/Dealers/dealers-overview";
import DealerShow from "../pages/Dealers/dealer-show";
import DealerAdd from "../pages/Dealers/dealer-add";

// Engineers
import EngineersOverview from "../pages/Engineers/engineers-overview";
import EngineerShow from "../pages/Engineers/engineer-show";
import EngineerAdd from "../pages/Engineers/engineer-add";

// Printers
import PrintersOverview from "../pages/Printers/printers-overview";
import PrinterShow from "../pages/Printers/printer-show";
import PrinterAdd from "../pages/Printers/printer-add";
import PrinterOvertake from "../pages/Printers/printer-overtake";

// Overtake
import OvertakesOverview from "../pages/Overtakes/overtakes-overview";

// Helpcenter
import Helpcenter from "../pages/Helpcenter/helpcenter";

// Helpcenter
import NotificationsOverview from "../pages/Notifications/notifications-overview";
import NotificationAdd from "../pages/Notifications/notification-add";


//Pages
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import DownloadArea from "../pages/DownloadArea/downloadArea";

const userRoutes = [

	{ path: "/dashboard", component: Dashboard },

	//claims
	{ path: "/claims", component: ClaimsOverview, requiredRoles: "manager,staff,engineer,sales,dealer" },
	{ path: "/claim/:id", component: ClaimShow, requiredRoles: "manager,staff,engineer,sales,dealer" },
	{ path: "/add-claim", component: ClaimAdd, requiredRoles: "manager,staff,engineer" },

	//printers
	{ path: "/printers", component: PrintersOverview, requiredRoles: "manager,staff,engineer,sales,dealer" },
	{ path: "/printer/:serial", component: PrinterShow, requiredRoles: "manager,staff,engineer,sales,dealer" },
	{ path: "/add-printer", component: PrinterAdd, requiredRoles: "manager,staff,engineer" },
	{ path: "/overtake-printer", component: PrinterOvertake, requiredRoles: "manager,staff,engineer" },

	//customers
	{ path: "/customers", component: CustomerOverview, requiredRoles: "manager,staff,engineer,sales,dealer" },
	{ path: "/customer/:id", component: CustomerShow, requiredRoles: "manager,staff,engineer,sales,dealer" },
	{ path: "/add-customer", component: CustomerAdd, requiredRoles: "manager,staff,engineer" },

	// Dealer
	{ path: "/dealers", component: DealersOverview, requiredRoles: "manager,staff,sales" },
	{ path: "/dealer/:id", component: DealerShow, requiredRoles: "manager,staff,sales" },
	{ path: "/add-dealer", component: DealerAdd, requiredRoles: "manager,staff" },

	// Members
	{ path: "/members", component: EngineersOverview, requiredRoles: "manager,staff,sales" },
	{ path: "/member/:id", component: EngineerShow, requiredRoles: "manager,staff,sales" },
	{ path: "/add-member", component: EngineerAdd, requiredRoles: "manager,staff" },

	// Dispatch
	{ path: "/dispatch", component: DispatchOverview, requiredRoles: "manager,staff,dispatcher" },

	// Services
	{ path: "/services", component: ServicesOverview, requiredRoles: "manager,staff,engineer" },
	{ path: "/add-service", component: ServiceAdd, requiredRoles: "manager,staff,engineer" },

	// Overtake
	{ path: "/overtakes", component: OvertakesOverview, requiredRoles: "manager,staff,dispatcher" },

	// Overtake
	{ path: "/notifications", component: NotificationsOverview, requiredRoles: "manager" },
	{ path: "/add-notification", component: NotificationAdd, requiredRoles: "manager" },

	// Helpcenter
	{ path: "/helpcenter", component: Helpcenter, requiredRoles: "manager,staff,engineer,sales,dealer" },

	// Download Area
	{ path: "/downloads", component: DownloadArea, requiredRoles: "manager,staff,engineer,sales,dealer" },

	//profile
	{ path: "/profile", component: UserProfile },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const authRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/reset-password", component: ResetPasswordPage },
	{ path: "/register", component: Register },

	{ path: "/pages-404", component: Pages404 },
	{ path: "/pages-500", component: Pages500 },

	// Authentication Inner
	// { path: "/pages-login", component: Login1 },
	// { path: "/pages-register", component: Register1 },
	// { path: "/pages-forget-pwd", component: ForgetPwd1 },
	// { path : "/auth-lock-screen", component: LockScreen }
];

export { userRoutes, authRoutes };
