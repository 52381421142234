import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardTitle, Button} from "reactstrap";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getPrinters} from "../../store/printers/actions";
import Moment from "react-moment";

const LatestPrinters = (props) => {

    const [printers, setPrinters] = useState([]);

    useEffect(() => {
        props.getPrinters();
    },[])

    useEffect(() => {
        if(props.data) {
            setPrinters(props.data.slice(0,10));
        }
    },[props.data]);

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CardTitle className="text-yellow mb-2">
                        Last Printer Installations
                    </CardTitle>
                    <div className="text-sm-right mb-2">
                        <Link to={{
                            pathname: "/printers",
                        }}>
                            <Button type="button" color="yellow"
                                    className="btn-rounded waves-effect waves-light mb-2 mr-2">Show All Printers</Button>
                        </Link>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                            <thead className="thead-light">
                            <tr>
                                <th>Serial number</th>
                                <th>Dealer</th>
                                <th>Customer</th>
                                <th>Installation</th>
                                <th>Status</th>
                                <th>View Details</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                printers && printers.map((printer, key) =>
                                    <tr key={"_tr_" + key}>
                                        <td>{printer.printer_serial_no}</td>
                                        <td>{printer.dealer && printer.dealer.name}</td>
                                        <td>
                                            {printer.customer && printer.customer.name}
                                        </td>
                                        <td><Moment format="DD-MM-YYYY">{printer.installation_date }</Moment></td>
                                        <td>
                                            {
                                                printer.guarantee_state === 1 ? <span
                                                        className="badge badge-pill badge-soft-success">In guarantee</span> :
                                                    <span className="badge badge-pill badge-soft-danger">Not in guarantee</span>
                                            }
                                        </td>
                                        <td>
                                            <Link to={{
                                                pathname: "/printer/"+printer.id,
                                                state: { printer: printer.id }
                                            }}>
                                                <Button type="button" color="primary" size="sm"
                                                        className="btn-rounded waves-effect waves-light">
                                                    View Details
                                                </Button>
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {data} = state.Printers;
    return {data};
};

export default connect(mapStatetoProps, {
    getPrinters
})(LatestPrinters);
