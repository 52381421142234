import React, {useState} from 'react';
import {Button, Row, Col, Modal} from "reactstrap";
import {AvForm} from "availity-reactstrap-validation";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import SweetAlert from "react-bootstrap-sweetalert";

import axios from "axios";
import arrowImg from "../../assets/images/icon-arrow.svg";

const ModalConfirmOvertake = (props) => {

    const [modal, setModal] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const overtake = props.overtake;

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function toggleModal() {
        setModal(!modal);
        removeBodyCss();
    }

    function handleValidSubmit(event, values) {

        const confirmedOvertake = {
            overtake_id: overtake.id,
        }
        setIsLoading(true);
        confirmOvertake(confirmedOvertake);
    }

    function confirmOvertake(overtake) {
        const url = process.env.REACT_APP_APIURL + 'overtake/confirm';
        const authToken = authHeader();

        const formData = new FormData();
        formData.append('data', JSON.stringify(overtake));

        return axios.post(url, formData, {headers: authToken}).then(response => {
            setIsLoading(false);
            setSuccessMsg(true);
        }).catch(error => {
            setIsLoading(false);
            setErrorMsg(true);
        });
    }

    return (
        <React.Fragment>
            <button className="mr-1 waves-effect waves-light btn btn-yellow text-dark font-weight-bold"
                    type="button"
                    onClick={() => {
                        toggleModal()
                    }}
                    data-toggle="modal"
                    data-target=".bs-example-modal-center"
            >
                Confirm
            </button>
            <Modal
                size="lg"
                isOpen={modal}
                toggle={() => {
                    toggleModal()
                }}
                centered={true}
            >
                <div className="modal-body">
                    <button
                        type="button"
                        onClick={() => {
                            setModal(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Row>
                        <Col md={1}>
                            <div className="avatar-sm">
                                <span
                                    className="avatar-title rounded-circle bg-primary text-white">
                                    <i className="bx bx-check-circle bx-sm"></i>
                                </span>
                            </div>
                        </Col>
                        <Col md={11}>
                            <div className="mb-2">
                                <h5 className="modal-title mt-0">Confirmation for Printer Overtake</h5>
                                <p>Please confirm the following printer overtake</p>
                                <AvForm className="form-horizontal mt-4"
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v)
                                        }}>
                                    <Row className="mb-4">
                                        <Col md="12">
                                            <h5>{overtake.printer_serial_no}</h5>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col md="4">
                                            <h6>Current Engineer</h6>
                                            <strong>{overtake.current_engineer && (overtake.current_engineer.firstname + " " + overtake.current_engineer.lastname)}</strong>
                                        </Col>
                                        <Col md="2"><img className="px-2 mb-1" src={arrowImg}/></Col>
                                        <Col md="4" className="text-yellow">
                                            <h6>New Engineer</h6>
                                            <strong>{overtake.new_engineer && (overtake.new_engineer.firstname + " " + overtake.new_engineer.lastname)}</strong>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col md="4">
                                            <h6>Current Dealer</h6>
                                            <strong>{overtake.current_dealer.name}</strong>
                                        </Col>
                                        <Col md="2"><img className="px-2 mb-1" src={arrowImg}/></Col>
                                        <Col md="4" className="text-yellow">
                                            <h6>New Dealer</h6>
                                            <strong>{overtake.new_dealer.name}</strong>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col md="4">
                                            <h6>Current Customer</h6>
                                            <strong>{overtake.current_customer.name}</strong>
                                        </Col>
                                        <Col md="2"><img className="px-2 mb-1" src={arrowImg}/></Col>
                                        <Col md="4" className="text-yellow">
                                            <h6>New Customer</h6>
                                            <strong>{overtake.new_customer.name}</strong>
                                        </Col>
                                    </Row>
                                    <div className="text-sm-right">
                                        {isLoading ?
                                            <Button className="btn btn-yellow" type="submit" disabled={true}>
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i> Confirm
                                            </Button> :
                                            <Button className="btn btn-yellow" type="submit">
                                                <i className="mdi mdi-check mr-1"></i> Confirm
                                            </Button>
                                        }
                                    </div>
                                </AvForm>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
            {successMsg ? <SweetAlert
                title="Good job!"
                success
                showConfirm={true}
                showCancel={false}
                confirmBtnBsStyle="yellow"
                onConfirm={() => {
                    // setSuccessMsg(false);
                    // setModal(false);
                    window.location.reload();
                }}
            >
                Overtake was confirmed successfully.
            </SweetAlert> : null}
            {errorMsg ? <SweetAlert
                title="Request could not be submitted!"
                warning
                showConfirm={true}
                showCancel={false}
                confirmBtnBsStyle="yellow"
                onConfirm={() => {
                    setErrorMsg(false);
                }}
            >
                There was an error while submitting your request. Please try again or get in contact with our
                team.
            </SweetAlert> : null}
        </React.Fragment>
    );
}

export default ModalConfirmOvertake;