import authHeader from "../helpers/jwt-token-access/auth-token-header";
import axios from "axios";

const getClaimFromBackend = (claimId) => {
    const url = process.env.REACT_APP_APIURL + 'claim/' + claimId;
    const authToken = authHeader();

    return axios.get(url, {headers: authToken}).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

const createClaimInBackend = (claim) => {
    const url = process.env.REACT_APP_APIURL + 'claim';
    const authToken = authHeader();
    const formData = new FormData();
    claim.files.forEach((file) => {
        formData.append('file[]', file);
    });
    formData.append('data', JSON.stringify(claim));

    return axios.post(url, formData,{headers: authToken}).then(response => {
        // if (response.status === 422 || response.status === 500)
        //     return response.data;
        return response.data;
    }).catch(error => {
        // throw err[1];
        return error.response.data;
    });
}

export {getClaimFromBackend, createClaimInBackend}