import './style.scss';
import React, { FC, useMemo } from 'react';
import { MDBBadge } from 'mdbreact';
import PrinterType from '../../../pages/Printers/printerType';
import Moment from 'react-moment';
import { MuiToolbarThemeWithHover } from '../../../helpers/themes/MuiThemes';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { MuiThemeProvider, Typography } from '@material-ui/core';
import { LoadingIndicator } from '../../LoadingIndicator';
import { useHistory } from 'react-router-dom';

interface PrinterListProps {
    isLoading: boolean,
    printers: Printer[]
}

export const PrinterList: FC<PrinterListProps> = ({
    isLoading,
    printers
}) => {

    const history = useHistory();

    const columns: MUIDataTableColumn[] = [
        {
            label: "ID",
            name: "id",
            options: {
                filter: true,
                sort: true,
                download: false,
            }
        },
        {
            label: "SN",
            name: "printer_serial_no",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Model",
            name: "printer_type",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (printer_type) => <PrinterType printer_type={printer_type}/>
            }
        },
        {
            label: "Dealer",
            name: "dealer_name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Customer",
            name: "customer_name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Installation",
            name: "installation_date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (installation_date) => <Moment format="DD-MM-YYYY">{installation_date}</Moment>
            }
        },
        {
            label: "Input",
            name: "created_at",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (created_at) => <Moment format="DD-MM-YYYY">{created_at}</Moment>
            }
        },
        {
            label: "Status",
            name: "guarantee_state",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (guarantee_state) =>
                    <MDBBadge
                        pill
                        color={guarantee_state === 1 ? 'soft-success' : 'soft-danger'}
                    >
                        {guarantee_state === 1 ? 'In guarantee' : 'Not in guarantee'}
                    </MDBBadge>
            }
        }
    ];

    const dataList = useMemo(
        () => ({
            columns: [...columns],
            rows: [...printers.map(
                printer => ({
                    id: printer.id,
                    printer_serial_no: printer.printer_serial_no,
                    dealer_name: (printer.dealer && printer.dealer.name),
                    customer_name: (printer.customer && printer.customer.name),
                    printer_type: printer.printer_type,
                    installation_date: printer.installation_date,
                    created_at: printer.created_at,
                    guarantee_state: printer.guarantee_state,
                    // @TODO: delete this?
                    //    <Link to={{
                    //        pathname: "/printer/" + printer.id + "/delete",
                    //        state: {printerId: printer.id}
                    //    }} className="text-danger">
                    //        <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
                    //        <UncontrolledTooltip placement="top" target="deletetooltip">
                    //            Delete
                    //        </UncontrolledTooltip>
                    //    </Link>
                })
            )]
        }), [printers, columns],
    );
    
    const options: MUIDataTableOptions = {
        download: true,
        downloadOptions: {
            filename: 'printer.csv'
        },
        filter: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        rowsPerPage: 50,
        rowsPerPageOptions: [],
        selectableRows: 'none',
        onRowClick: (rowData) => history.push('/printer/' + rowData[0]),
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LoadingIndicator fontSize={24}/> : "Sorry, no matching records found"
            }
        },
    }

    return (
        <MuiThemeProvider theme={MuiToolbarThemeWithHover}>
            <MUIDataTable
                title={!isLoading ? 'Printers' : <Typography variant="h6">Printers <LoadingIndicator /></Typography>}
                data={dataList.rows}
                columns={dataList.columns}
                options={options}
            />
        </MuiThemeProvider>
    );
}
