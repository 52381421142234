import authHeader from "../helpers/jwt-token-access/auth-token-header";
import axios from "axios";

const getNotificationFromBackend = (notificationId: number) => {
    const url = process.env.REACT_APP_APIURL + 'notification/'+ notificationId;
    const authToken = authHeader();

    return axios.get(url, {headers: authToken}).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

const createNotificationInBackend = (notification: CreateAPINotification) => {
    const url = process.env.REACT_APP_APIURL + 'notification';
    const authToken = authHeader();

    const formData = new FormData();
    formData.append('data', JSON.stringify(notification));

    return axios.post(url, formData,{headers: authToken}).then(response => {
        // if (response.status === 400 || response.status === 500)
        //     throw response.data;
        return response.data;
    }).catch(error => {
        return error.response.data;
    });
}

const editNotificationInBackend = (notification: EditAPINotification) => {
    const url = process.env.REACT_APP_APIURL + 'notification/edit/' + notification.id;
    const authToken = authHeader();

    const formData = new FormData();
    formData.append('data', JSON.stringify(notification));

    return axios.post(url, formData,{headers: authToken}).then(response => {
        return response.data;
    }).catch(error => {
        return error.response.data;
    });
}

const deleteNotificationInBackend = (notification: DeleteAPINotification) => {
    const url = process.env.REACT_APP_APIURL + 'notification/' + notification.id;
    const authToken = authHeader();

    const formData = new FormData();
    formData.append('data', JSON.stringify(notification));

    return axios.delete(url,{headers: authToken}).then(response => {
        return response.data;
    }).catch(error => {
        return error.response.data;
    });
}

export {getNotificationFromBackend, createNotificationInBackend, editNotificationInBackend, deleteNotificationInBackend}
