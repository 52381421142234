import { createMuiTheme } from '@material-ui/core';

export const MuiToolbarThemeWithHover = createMuiTheme({
        overrides: {
            MuiToolbar: {
                gutters: {
                    padding: '16px !important'
                }
            },
            MUIDataTableBodyRow: {
                root: {
                    "&:hover": {
                        cursor: 'pointer'
                    },
                }
            },
        }
    }
);

export const MuiToolbarTheme = createMuiTheme({
        overrides: {
            MuiToolbar: {
                gutters: {
                    padding: '16px !important'
                }
            },
        }
    }
);
