import axios from 'axios';
import authHeader from '../helpers/jwt-token-access/auth-token-header';

// Retrieve Claims
const getClaimsFromBackend = () => {
    const url = process.env.REACT_APP_APIURL+'claims';
    const authToken = authHeader();

    return axios.get(url,{ headers: authToken }).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

// const getClaimsFromBackend = async () => {
//     try {
//         const response = await fetch(process.env.REACT_APP_APIURL+'claims');
//         const data = await response.json();
//         return data;
//     } catch (e) {
//         console.log(e);
//     }
//
// }

export { getClaimsFromBackend}