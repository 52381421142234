import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Table
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
    getCustomer
} from '../../store/actions';
import QuickNavigation from "../Dashboard/QuickNavigation";
import { Link } from "react-router-dom";
import { getCustomerByIdSelector } from '../../selectors/customer';
import { CustomerCard } from '../../components/Customer/CustomerCard';
import { createLoadingSelector } from '../../selectors/loading';
import { GET_CUSTOMER } from '../../store/customer/actionTypes';
import { InfoCard } from '../../components/Common/InfoCard';
import { PrintersTable } from '../Printers/printers-table';

const CustomerShow: FC = () => {

    const location = useLocation();
    const dispatch = useDispatch();

    const customerId = location.pathname.split("/").pop() || 0;

    const customerSelector = useSelector(state => {
        return getCustomerByIdSelector(state, +customerId);
    })

    const customerLoadingSelector = useSelector(createLoadingSelector([GET_CUSTOMER]));

    const [customerLoading, setCustomerLoading] = useState(true);
    const [customer, setCustomer] = useState<FullCustomer>();

    useEffect(() => {
        dispatch(getCustomer(+customerId));
    }, [dispatch]);

    useEffect(() => {
        setCustomer(customerSelector);
    }, [customerSelector]);

    useEffect(() => {
        setCustomerLoading(customerLoadingSelector);
    }, [customerLoadingSelector]);

    const totalPrintersCount = customer?.printer.length || 0;
    const inGuaranteeCount = customer?.printer.filter(printer => printer.guarantee_state === 1).length || 0;
    const outGuaranteeCount = customer?.printer.filter(printer => printer.guarantee_state === 0).length || 0;

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs parent="/customers" title="Customer" breadcrumbItem={customer?.name}/>
                <Row>
                    <Col xl="4">
                        <CustomerCard
                            customer={customer}
                            isLoading={customerLoading}
                        />
                        <QuickNavigation/>
                    </Col>

                    <Col xl="8">
                        <Row className="mb-4">
                            <Col md="4">
                                <InfoCard
                                    title="Printer in total"
                                    value={totalPrintersCount}
                                    isLoading={customerLoading}
                                />
                            </Col>
                            <Col md="4">
                                <InfoCard
                                    title="Printer in guarantee"
                                    value={inGuaranteeCount}
                                    isLoading={customerLoading}
                                />
                            </Col>
                            <Col md="4">
                                <InfoCard
                                    title="Printers out of guarantee"
                                    value={outGuaranteeCount}
                                    isLoading={customerLoading}
                                />
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <PrintersTable
                                    printers={customer?.printer || []}
                                    isLoading={customerLoading}
                                />
                            </CardBody>
                        </Card>
                        {customer && customer.business_information ?
                            // @TODO in own component
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Customer Business Information</h4>
                                    <div className="table-responsive">
                                        <Table className="table table-centered table-nowrap">
                                            <thead className="thead-light">
                                            <tr>
                                                <th>
                                                    Information
                                                </th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Type of Business</td>
                                                <td>
                                                    {customer.business_information.type_of_business && customer.business_information.type_of_business.split(',').map((item, key) =>
                                                        <span key={key}
                                                              className="badge badge-pill badge-soft-dark mr-1">{item}</span>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>No of Staff</td>
                                                <td><span
                                                    className="badge badge-pill badge-soft-dark">{customer.business_information.no_of_staff}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Media to be printed with GT/GTX</td>
                                                <td>
                                                    {customer.business_information.printed_media && customer.business_information.printed_media.split(',').map((item, key) =>
                                                        <span key={key}
                                                              className="badge badge-pill badge-soft-dark mr-1">{item}</span>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Projected print amount per month</td>
                                                <td><span
                                                    className="badge badge-pill badge-soft-dark">{customer.business_information.projected_print_amount}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Environmental Conditions</td>
                                                <td>
                                                    {customer.business_information.environmental_conditions && customer.business_information.environmental_conditions.split(',').map((item, key) =>
                                                        <span key={key}
                                                              className="badge badge-pill badge-soft-dark mr-1">{item}</span>
                                                    )}
                                                    <span
                                                        className="badge badge-pill badge-soft-dark mr-1">Temperature {customer.business_information.environmental_conditions_temperature}°C</span>
                                                    <span
                                                        className="badge badge-pill badge-soft-dark mr-1">Humidity {customer.business_information.environmental_conditions_humidity}%</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Operating System</td>
                                                <td>
                                                    {customer.business_information.operating_system && customer.business_information.operating_system.split(',').map((item, key) =>
                                                        <span key={key}
                                                              className="badge badge-pill badge-soft-dark mr-1">{item}</span>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Pre-Treatment Application</td>
                                                <td>
                                                    {customer.business_information.pre_treatment && customer.business_information.pre_treatment.split(',').map((item, key) =>
                                                        <span key={key}
                                                              className="badge badge-pill badge-soft-dark mr-1">{item}</span>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Heat Press Info</td>
                                                <td><span
                                                    className="badge badge-pill badge-soft-dark">{customer.business_information.heatpress_brand}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Conveyor Dryer Info (if applicable)</td>
                                                <td><span
                                                    className="badge badge-pill badge-soft-dark">{customer.business_information.dryer_brand}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Software related</td>
                                                <td>
                                                    {customer.business_information.pre_treatment && customer.business_information.graphics_software.split(',').map((item, key) =>
                                                        <span key={key}
                                                              className="badge badge-pill badge-soft-dark mr-1">{item}</span>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>White Shaking Tools</td>
                                                <td>
                                                    {customer.business_information.white_shaking_tools && customer.business_information.white_shaking_tools.split(',').map((item, key) =>
                                                        <span key={key}
                                                              className="badge badge-pill badge-soft-dark mr-1">{item}</span>
                                                    )}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card> : ''}
                    </Col>
                </Row>
                <Row className="my-4">
                    <Col sm="6">
                        <Link
                            to="/customers"
                            className="btn d-none d-sm-inline-block btn-link pl-0"
                        >
                            <i className="mdi mdi-arrow-left mr-1"/> Back to Customers Overview
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default CustomerShow
