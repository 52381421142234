import React, {useEffect, useState} from "react";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import axios from "axios";
import {Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";
import parse from 'html-react-parser';

const Helpcenter = (props) => {

    const [currentArticles, setCurrentArticles] = useState(null);
    const [currentArticle, setCurrentArticle] = useState(null);
    const [successMsg, setSuccessMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (currentArticle === null && currentArticles === null) {
            getArticles();
        }
    }, []);

    function getArticles() {
        const url = process.env.REACT_APP_APIURL + 'helpcenter/articles';
        const authToken = authHeader();

        return axios.get(url, {headers: authToken}).then(response => {
            // setIsLoading(false);
            setCurrentArticles(response.data.articles);
            setCurrentArticle({...response.data.articles[0], id: 0});
        }).catch(error => {
            // setIsLoading(false);
        });
    }

    return (
        <React.Fragment>
            <Row className="page-content">
                <Container fluid>

                    {/* Render Breadcrumb */}
                    <Row>
                        <Col xl="3">
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-yellow mb-4">
                                        Categories
                                    </CardTitle>
                                    {currentArticles && currentArticles.map((article, index) => <div
                                        className={"pb-2 mb-2 w-75 border-bottom border-light border-bottom-1 cursor-pointer" + (currentArticle && (currentArticle.id === index) ? " font-weight-bold" : "")}
                                        key={"article-" + index} onClick={() => setCurrentArticle({
                                        ...article,
                                        id: index
                                    })}>{article.title}</div>)}
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-yellow mb-4">
                                        Quick navigation
                                    </CardTitle>
                                    <a href="mailto:support@brother.digital">
                                        <div className="text-white-50 card bg-yellow card-quicknav">
                                            <div className="card-body">
                                                <div className="mb-4 text-primary card-title">
                                                    Submit a<br/> Support request
                                                </div>
                                                <i className="mdi mdi-arrow-right text-primary"></i>
                                            </div>
                                        </div>
                                    </a>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl="9">
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-yellow mb-4">
                                        Helpcenter
                                    </CardTitle>
                                    <h3>{currentArticle && currentArticle.title}</h3>
                                    <div className="helpcenter-content">
                                        {currentArticle && parse(currentArticle.body)}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </React.Fragment>
    );
}

export default Helpcenter;