import React, { FC, useMemo } from 'react';
import Moment from 'react-moment';
import { MuiToolbarTheme } from '../../../helpers/themes/MuiThemes';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { MuiThemeProvider, Typography } from '@material-ui/core';
import { LoadingIndicator } from '../../LoadingIndicator';
import { actionColumn } from '../../../helpers/Table/TableAction';
import ModalConfirmOvertake from '../../Modals/modal-confirmovertake';

interface OvertakeListProps {
    isLoading?: boolean, // @TODO make this not optional
    overtakes: Overtake[]
}

export const OvertakeList: FC<OvertakeListProps> = ({
    isLoading, // @TODO define something that shows its loading
    overtakes
}) => {

    const columns = [
        {
            label: "Date + Time",
            name: "created_at",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (created_at) => <Moment format="DD-MM-YYYY">{created_at}</Moment>
            }
        },
        {
            label: "Printer Serial",
            name: "printer_serial_no",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Engineer",
            name: "engineer",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Dealer",
            name: "current_dealer_name",
            options: {
                filter: true,
                sort: true,
            }
        },
        // @TODO: delete this?
        // {
        //     label: "New Dealer",
        //     name: "new_dealer_name",
        //     width: 109
        // },
        {
            label: "New Customer",
            name: "new_customer_name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Old Customer",
            name: "current_customer_name",
            options: {
                filter: true,
                sort: true,
            }
        },
        actionColumn
    ];
    
    const dataList = useMemo(
    () => ({
        columns: [...columns],
        rows: [...overtakes.map(
            overtake => ({
                created_at: overtake.created_at,
                printer_serial_no: overtake.printer_serial_no,
                engineer: overtake.new_engineer ? overtake.new_engineer.firstname + " " + overtake.new_engineer.lastname : '',
                new_dealer_name: overtake?.new_dealer?.name,
                new_customer_name: overtake.new_customer && overtake.new_customer.name,
                current_dealer_name: overtake.current_dealer && overtake.current_dealer.name,
                current_customer_name: overtake.current_customer && overtake.current_customer.name,
                actions: [
                    {
                        type: 'Modal',
                        value: (overtake.confirmed === 0 ?
                            <ModalConfirmOvertake overtake={overtake}/> : ''),
                    }
                ],
            })
        )]
    }), [overtakes, columns],
  );

    const options: MUIDataTableOptions = {
        download: false,
        filter: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        rowsPerPage: 50,
        rowsPerPageOptions: [],
        selectableRows: 'none',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LoadingIndicator fontSize={24}/> : "Sorry, no matching records found"
            }
        }
    }

    return (
        <MuiThemeProvider theme={MuiToolbarTheme}>
            <MUIDataTable
                title={!isLoading ? 'Overtakes' : <Typography variant="h6">Overtakes <LoadingIndicator /></Typography>}
                data={dataList.rows}
                columns={dataList.columns}
                options={options}
            />
        </MuiThemeProvider>
    );
}
