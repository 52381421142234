// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
    GET_DEALERS_REQUEST
} from "./actionTypes";
import { getDealersFromBackend } from '../../api/dealers';
import { getDealersError, getDealersSuccess } from './actions';

/**
 * Retrieving Dealer
 */
function* getDealers() {
    try {
        const data = yield call(getDealersFromBackend);
        yield put(getDealersSuccess(data));

    } catch (error) {
        yield put(getDealersError(error));
    }
}

/**
 * Watchers
 */
export function* watchActions() {
    yield takeEvery(GET_DEALERS_REQUEST, getDealers);
}

function* DealersSaga() {
    yield all([
        fork(watchActions),
    ]);
}

export default DealersSaga;
