import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getPrinters} from "../../store/printers/actions";
import AvSelect from "../Common/AvSelect";
import {AvFeedback, AvGroup} from "availity-reactstrap-validation";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import axios from "axios";

const ListPrinters = (props) => {

    const [printers, setPrinters] = useState([]);
    const [selectedPrinter, setSelectedPrinter] = useState([]);

    const location = useLocation();
    const params = queryString.parse(location.search);

    useEffect(() => {
        params.id && getPrinter(params.id);
    },[params.id]);

    useEffect(() => {
        props.getPrinters();
    },[]);

    function getPrinter(id) {
        const url = process.env.REACT_APP_APIURL + 'printer/'+id;
        const authToken = authHeader();

        return axios.get(url, {headers: authToken}).then(response => {
            const printer = response.data.data;
            setSelectedPrinter({
                ...printer,
                value: printer.id,
                label: printer.printer_serial_no
            });
            props.onChange(printer);
        }).catch(err => {
            throw err[1];
        });
    }

    useEffect(() => {
        if (props.data) {

            let printers = props.data;

            if(props.filter){
                printers = printers.filter(printer => props.filter.includes(printer.printer_type));
            }

            setPrinters(printers.map(printer => ({
                ...printer,
                value: printer.id,
                label: printer.printer_serial_no
            })));
        }
    }, [props.data, props.filter]);

    function handleSelectPrinter(printer) {
        setSelectedPrinter(printer);
        props.onChange(printer);
    }

    return (
        <AvGroup>
            <AvSelect
                name="printer"
                options={printers}
                helpMessage="Select a printer."
                validate={{required: {value: props.required ?? true}}}
                value={selectedPrinter}
                onChange={(printer) => {
                    handleSelectPrinter(printer)
                }}
                raw={true}
            />
            <AvFeedback>Please choose a valid option.</AvFeedback>
        </AvGroup>
    );
}

const mapStatetoProps = state => {
    const {data} = state.Printers;
    return {data};
};

export default connect(mapStatetoProps, {
    getPrinters
})(ListPrinters);