import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardTitle, Button} from "reactstrap";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getCustomers} from "../../store/customers/actions";
import Moment from "react-moment";
import {getLoggedInUser} from "../../helpers/fakebackend_helper";

const LatestServices = (props) => {

    const [customers, setCustomers] = useState([]);

    const authUser = getLoggedInUser();

    useEffect(() => {
        props.getCustomers();
    }, [])

    useEffect(() => {
        if (props.data) {
            setCustomers(props.data.slice(0, 5));
        }
    }, [props.data]);

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CardTitle className="text-yellow mb-2">
                        Latest Customers
                    </CardTitle>

                    <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                            <thead className="thead-light">
                            <tr>
                                <th>Name</th>
                                <th>Printer Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                customers && customers.map((customer, key) =>
                                    <tr key={"_tr_" + key}>
                                        <td>{customer.name}</td>
                                        <td>{customer.printers_total}</td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {data} = state.Customers;
    return {data};
};

export default connect(mapStatetoProps, {
    getCustomers
})(LatestServices);