import React, {useEffect, useState} from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    FormGroup,
    Label,
    CardBody,
    Button
} from "reactstrap";

import {Link, useHistory} from 'react-router-dom';
import {AvField, AvForm, AvRadioGroup, AvRadio} from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import axios from "axios";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Images
import ListPrinters from "../../components/Lists/ListPrinters";
import GtxServices from "./gtx-services";
import GtxProServices from "./gtxpro-services";
import GtxProBulkServices from "./gtxprobulk-services";
import moment from "moment";
import Gtx600Services from "./gtx600-services";
import {getLoggedInUser} from "../../helpers/fakebackend_helper";

const ServiceAdd = (props) => {

    const authUser = getLoggedInUser();

    const [selectedPrinter, setSelectedPrinter] = useState(null);
    const [errorMsg, setErrorMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();

    function handleValidSubmit(event, values) {
        const service = {
            printer_serial_no: selectedPrinter.printer_serial_no,
            service_type: values.service_type,
            number_of_prints: values.number_of_prints,
            date_of_visit: values.date_of_visit,
        }
        setIsLoading(true);
        addService(service);
    }

    function addService(service) {
        const url = process.env.REACT_APP_APIURL + 'service';
        const authToken = authHeader();

        const formData = new FormData();
        formData.append('data', JSON.stringify(service));

        return axios.post(url, formData, {headers: authToken}).then(response => {
            setIsLoading(false);
            setSuccessMsg(true);
        }).catch(error => {
            setIsLoading(false);
            setErrorMsg(true);
        });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Maintenance Service"/>

                    {errorMsg ? <SweetAlert
                        title="Request could not be submitted!"
                        warning
                        showConfirm={true}
                        showCancel={false}
                        confirmBtnBsStyle="success"
                        onConfirm={() => {
                            setErrorMsg(false);
                        }}
                    >
                        There was an error while submitting your request. Please try again or get in contact with our
                        team.
                    </SweetAlert> : null}
                    {successMsg ? <SweetAlert
                        title="Good job!"
                        success
                        showConfirm={true}
                        showCancel={false}
                        confirmBtnBsStyle="yellow"
                        onConfirm={() => {
                            setSuccessMsg(false);
                            history.push('/printers');
                        }}
                    >
                        The service has been submitted.
                    </SweetAlert> : ''
                    }

                    <Row>
                        <Col xs="12">
                            <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}>
                                <Card>
                                    <CardBody>
                                        <FormGroup className="mb-4" row>
                                            <Label htmlFor="printer-serialno" md="2"
                                                   className="col-form-label">Printer SERIAL No.*<br/><small>GTX,
                                                GTXpro, GTXpro Bulk, GTX600</small></Label>
                                            <Col md="10">
                                                <ListPrinters filter={['gtx', 'gtxpro', 'gtxprobulk', 'gtx600']}
                                                              onChange={(printer) => {
                                                                  setSelectedPrinter(printer);
                                                              }}/>
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <FormGroup>
                                            {selectedPrinter && selectedPrinter.printer_type === "gtx" &&
                                            <GtxServices selectedPrinter={selectedPrinter}/>}
                                            {selectedPrinter && selectedPrinter.printer_type === "gtxpro" &&
                                            <GtxProServices selectedPrinter={selectedPrinter}/>}
                                            {selectedPrinter && selectedPrinter.printer_type === "gtxprobulk" &&
                                            <GtxProBulkServices selectedPrinter={selectedPrinter}/>}
                                            {authUser.user.can_see_gtx600 === 1 && selectedPrinter && selectedPrinter.printer_type === "gtx600" &&
                                            <Gtx600Services selectedPrinter={selectedPrinter}/>}
                                        </FormGroup>
                                        <FormGroup className="mb-4" row>
                                            <Label htmlFor="date_of_visit" md="2"
                                                   className="col-form-label">Date of visit *</Label>
                                            <Col md="6">
                                                <AvField
                                                    name="date_of_visit"
                                                    className="form-control"
                                                    placeholder="Enter date of visit *"
                                                    type="date"
                                                    max={moment().format("YYYY-MM-DD")}
                                                    validate={{required: {value: true}}}
                                                    errorMessage="Please provide a valid date."
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-4" row>
                                            <Label htmlFor="number_of_prints" md="2"
                                                   className="col-form-label">Number of prints *</Label>
                                            <Col md="6">
                                                <AvField
                                                    name="number_of_prints"
                                                    className="form-control"
                                                    placeholder="Number of prints"
                                                    type="number"
                                                    validate={{required: {value: true}}}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                                <Row className="my-4">
                                    <Col sm="6">
                                        <Link to="/dashboard"
                                              className="btn text-muted d-none d-sm-inline-block btn-link">
                                            <i className="mdi mdi-arrow-left mr-1"></i> Back to Dashboard
                                        </Link>
                                    </Col>
                                    <Col sm="6">
                                        <div className="text-sm-right">
                                            {isLoading ?
                                                <Button className="btn btn-yellow" type="submit" disabled={true}>
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i> Add
                                                    Maintenance Service
                                                </Button> :
                                                <Button className="btn btn-yellow" type="submit">
                                                    <i className="mdi mdi-plus-thick mr-1"></i> Add Maintenance Service
                                                </Button>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default ServiceAdd;