import React, {Component} from "react";
import {Alert, Col, FormGroup, Label, Row} from "reactstrap";
import {AvField} from "availity-reactstrap-validation";
import Printhead from "./printhead";
import Vrate from "./vrate";

class GtxProBulk extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <FormGroup className="mb-4" row>
                    <Label htmlFor="ink_supply_serial" md="2"
                           className="col-form-label">Ink Supply Serial No. *</Label>
                    <Col md="10">
                        <AvField
                            name="ink_supply_serial"
                            value=""
                            className="form-control"
                            placeholder="Ink Supply Serial No. *"
                            type="text"
                            validate={
                                {
                                    required: {value: true},
                                    pattern: {value: '^[A-Z][0-9]+$'},
                                    minLength: {value: 8},
                                    maxLength: {value: 8},
                                }
                            }
                            errorMessage="Please provide a valid serial."
                            helpMessage="Example: A1234567."
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-4">
                    <Label className="col-form-label">Print Head Serials</Label>
                    <Alert color="danger" role="alert">
                        Please note: the following print head serial numbers are the original print heads that are installed with the installation
                    </Alert>
                    <table style={{width: "100%"}}>
                        <tbody>
                        <tr>
                            <td>
                                <Row className="mb-2 px-2">
                                    <Label md="2"
                                           className="col-form-label">PH
                                        Serial CMYK *</Label>
                                    <Col md="4">
                                        <Printhead example="GXA1234" name="print_heads[gtxprobulk][0][serial]" placeholder="PH Serial CMYK *" />
                                        <AvField
                                            name="print_heads[gtxprobulk][0][position]"
                                            value="1"
                                            type="text"
                                            hidden={true}
                                        />
                                        <AvField
                                            name="print_heads[gtxprobulk][0][color]"
                                            value="color"
                                            type="text"
                                            hidden={true}
                                        />
                                    </Col>

                                    <Label md="2"
                                           className="col-form-label">V.Rating
                                        *</Label>
                                    <Col md="4">
                                        <Vrate name="print_heads[gtxprobulk][0][vrate]"/>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Row className="mb-2 px-2">
                                    <Label md="2"
                                           className="col-form-label">PH
                                        Serial white *</Label>
                                    <Col md="4">
                                        <Printhead example="XAA1234" name="print_heads[gtxprobulk][1][serial]" placeholder="PH Serial white *" />
                                        <AvField
                                            name="print_heads[gtxprobulk][1][position]"
                                            value="2"
                                            type="text"
                                            hidden={true}
                                        />
                                        <AvField
                                            name="print_heads[gtxprobulk][1][color]"
                                            value="white"
                                            type="text"
                                            hidden={true}
                                        />
                                    </Col>
                                    <Label md="2"
                                           className="col-form-label">V.Rating
                                        *</Label>
                                    <Col md="4">
                                        <Vrate name="print_heads[gtxprobulk][1][vrate]"/>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </FormGroup>
            </React.Fragment>
        );
    }
}

export default GtxProBulk;
