import React from 'react';
import AvSelect from "../Common/AvSelect";
import { AvFeedback, AvGroup } from "availity-reactstrap-validation";

const ListPrinterType = ({onChange, required}) => {

    const printerTypes = [
        {
            options: [
                {label: "GTX", value: "gtx"},
                {label: "GTXpro", value: "gtxpro"},
                {label: "GTXpro Bulk", value: "gtxprobulk"},
                {label: "GTX600", value: "gtx600"},
                {label: "GT-341", value: "gt341"},
                {label: "GT-361", value: "gt361"},
                {label: "GT-381", value: "gt381"}
            ]
        }
    ];

    function handleSelectPrinterType(selectedPrinterType) {
        onChange(selectedPrinterType);
    }

    return (
        <AvGroup>
            <AvSelect
                name="printer_type"
                options={printerTypes}
                helpMessage="Select a printer type."
                validate={{required: {value: required}}}
                onChange={(printerType) => {
                    handleSelectPrinterType(printerType)
                }}
                raw={true}
            />
            <AvFeedback>Please choose a valid option.</AvFeedback>
        </AvGroup>
    );
}

export default ListPrinterType;
