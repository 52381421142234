import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardTitle, Button} from "reactstrap";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getServices} from "../../store/services/actions";
import Moment from "react-moment";
import {getLoggedInUser} from "../../helpers/fakebackend_helper";

const LatestServices = (props) => {

    const [services, setServices] = useState([]);

    const authUser = getLoggedInUser();

    useEffect(() => {
        props.getServices();
    }, [])

    useEffect(() => {
        if (props.data) {
            setServices(props.data.slice(0, 10));
        }
    }, [props.data]);

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CardTitle className="text-yellow mb-2">
                        Services Overview
                    </CardTitle>
                    {['manager', 'staff', 'engineer'].includes(authUser.user.role) &&
                    <React.Fragment>
                        <div className="text-sm-right mb-2">
                            <Link to={{
                                pathname: "/add-service",
                            }}>
                                <Button type="button" color="yellow"
                                        className="btn-rounded waves-effect waves-light mb-2 mr-2">Add Maintenance
                                    Service</Button>
                            </Link>
                        </div>
                    </React.Fragment>
                    }

                    <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                            <thead className="thead-light">
                            <tr>
                                <th>Printer Serial</th>
                                <th>Service Type</th>
                                <th>Number of Prints</th>
                                <th>Date of Visit</th>
                                <th>Input</th>
                                <th>Engineer</th>
                                <th>Dealer</th>
                                <th>View details</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                services && services.map((service, key) =>
                                    <tr key={"_tr_" + key}>
                                        <td>{service.printer_serial}</td>
                                        <td>{service.service_type}</td>
                                        <td>{service.number_of_prints}</td>
                                        <td><Moment format="DD-MM-YYYY">{service.date_of_visit}</Moment></td>
                                        <td><Moment format="DD-MM-YYYY">{service.created_at}</Moment></td>
                                        <td>{service.engineer && service.engineer.firstname} {service.engineer && service.engineer.lastname}</td>
                                        <td>{service.dealer && service.dealer.name}</td>
                                        <td>
                                            <Link to={{
                                                pathname: "/printer/"+(service.printer && service.printer.id),
                                            }}>
                                                <Button type="button" color="primary" size="sm"
                                                        className="btn-rounded waves-effect waves-light">
                                                    View Details
                                                </Button>
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {data} = state.Services;
    return {data};
};

export default connect(mapStatetoProps, {
    getServices
})(LatestServices);