// @flow

import { GET_ENGINEERS_SUCCESS } from './actionTypes';

const initialState = {

}

const Engineers = (state = initialState, action) => {
	switch (action.type) {
		case GET_ENGINEERS_SUCCESS:
			return action.payload

		default:
			return state;
	}
};

export default Engineers;
