import {
	CREATE_NOTIFICATION_ERROR,
	CREATE_NOTIFICATION_REQUEST,
	CREATE_NOTIFICATION_SUCCESS,
	EDIT_NOTIFICATION_ERROR,
	EDIT_NOTIFICATION_REQUEST,
	EDIT_NOTIFICATION_SUCCESS,
	DELETE_NOTIFICATION_ERROR,
	DELETE_NOTIFICATION_REQUEST,
	DELETE_NOTIFICATION_SUCCESS,
	GET_NOTIFICATION_ERROR,
	GET_NOTIFICATION_REQUEST,
	GET_NOTIFICATION_SUCCESS
} from "./actionTypes";

export const getNotification = (notificationId: number) => ({
	type: GET_NOTIFICATION_REQUEST,
	payload: notificationId
});

export const getNotificationSuccess = (data) => {
	return{
		type: GET_NOTIFICATION_SUCCESS,
		payload: data
	}
};

export const getNotificationError = (error) => {
	return{
		type: GET_NOTIFICATION_ERROR,
		payload: error
	}
};

export const createNotification = (notification: CreateAPINotification) => ({
	type: CREATE_NOTIFICATION_REQUEST,
	payload: notification
});

export const createNotificationSuccess = (notification: CreateAPINotification) => {
	return {
		type: CREATE_NOTIFICATION_SUCCESS,
		payload: notification
	}
};

export const createNotificationError = (error) => {
	return {
		type: CREATE_NOTIFICATION_ERROR,
		payload: error
	}
};

export const editNotification = (notification: EditAPINotification) => ({
	type: EDIT_NOTIFICATION_REQUEST,
	payload: notification
})

export const editNotificationSuccess = (notification: EditAPINotification) => {
	return {
		type: EDIT_NOTIFICATION_SUCCESS,
		payload: notification
	}
};

export const editNotificationError = (error) => {
	return {
		type: EDIT_NOTIFICATION_ERROR,
		payload: error
	}
};

export const deleteNotification = (notification: DeleteAPINotification) => ({
	type: DELETE_NOTIFICATION_REQUEST,
	payload: notification
})

export const deleteNotificationSuccess = (success) => {
	return {
		type: DELETE_NOTIFICATION_SUCCESS,
		payload: success
	}
};

export const deleteNotificationError = (error) => {
	return {
		type: DELETE_NOTIFICATION_ERROR,
		payload: error
	}
};
