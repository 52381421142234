import React, {Component} from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Table,
    CardTitle
} from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
    getClaim
} from '../../store/actions';
import {connect} from "react-redux";
import Moment from "react-moment";
import ModalInternalCheck from '../../components/Modals/modal-internalcheck';
import ModalFinalCheck from '../../components/Modals/modal-finalcheck';
import {getLoggedInUser} from "../../helpers/fakebackend_helper";
import {Link} from "react-router-dom";
import PrinterType from "../Printers/printerType";
import arrowImg from "../../assets/images/icon-arrow.svg";
import ClaimHistory from "./claim-history";
import ModalAddComment from "../../components/Modals/modal-addcomment";
import ModalAddPartsOrderNo from "../../components/Modals/modal-addpartsorderno";
import ClaimStatus from '../../components/Claim/ClaimStatus';
import ModalClaimAddFile from "../../components/Modals/modal-claimaddfile";
import PrintheadStatus from "../Printers/printhead-status";

class ClaimShow extends Component {

    componentDidMount() {
        const claimId = window.location.pathname.split("/").pop();
        this.props.getClaim(claimId);
    }

    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    render() {

        const claim = this.props.data && this.props.data.id ? this.props.data : {
            id: "",
            information: "",
            state: "",
            printer: {
                printHeads: []
            },
            dealer: {},
            customer: {},
            engineer: {},
            printHead_replacements: [],
            files: []
        };

        const authUser = getLoggedInUser();

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        <Breadcrumbs parent="/claims" title="Claims" breadcrumbItem={claim.fault_report_no}/>
                        <Row>
                            <Col xl="4">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex mb-3">
                                            <div className="avatar-sm mr-3">
                                                <span
                                                    className="avatar-title rounded-circle bg-primary text-white font-size-20">
                                                    <i className="bx bxs-printer"/>
                                                </span>
                                            </div>
                                            <div className="mb-2">
                                                <div className="d-flex mb-1">
                                                    {
                                                        claim.printer && claim.printer.guarantee_state === 1 ?
                                                            <span className="badge badge-success font-size-14 pt-1">
                                                                <i className="bx bx-check"/>
                                                            </span> :
                                                            <span className="badge badge-danger font-size-14 pt-1">
                                                                <i className="bx bx-x"/>
                                                            </span>
                                                    }
                                                    <span
                                                        className="ml-2 text-truncate border rounded px-1">
                                                        <PrinterType printer_type={claim.printer.printer_type}/></span>
                                                </div>
                                                <div className="d-flex mb-1">
                                                    <span
                                                        className="badge badge-dark font-size-14 text-yellow align-self-center">SN</span>
                                                    <span
                                                        className="ml-2 text-truncate"><Link
                                                        className="font-size-18 text-yellow font-weight-bold"
                                                        to={"/printer/" + claim.printer.id}>{claim.printer.printer_serial_no}</Link></span>
                                                </div>
                                                {claim.printer.ink_supply_serial &&
                                                <div className="d-flex mb-1">
                                                    <span
                                                        className="badge badge-dark font-size-14 text-yellow align-self-center">ISN</span>
                                                    <span
                                                        className="ml-2 text-truncate"><Link
                                                        className="font-size-18 text-yellow font-weight-bold"
                                                        to={"/printer/" + claim.printer.id}>{claim.printer.ink_supply_serial}</Link></span>
                                                </div>
                                                }
                                                <div className="d-flex mb-3">
                                                    <i className='bx bx-power-off bx-xs mr-2'/>
                                                    <span className="text-truncate font-size-12"><Moment
                                                        format="DD-MM-YYYY">{claim.printer.installation_date}</Moment></span>
                                                </div>
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col sm="6">
                                                            <div className="d-flex mb-1">
                                                                <div className="badge badge-dark text-yellow"> PH
                                                                    Colour
                                                                </div>
                                                            </div>
                                                            {
                                                                claim.printer.print_heads && claim.printer.print_heads.filter(print_head => print_head.installed === 1 && print_head.color !== "white").map((print_head, key) =>
                                                                    <div key={key} className="d-flex mb-1">
                                                                        <PrintheadStatus
                                                                            printer_type={claim.printer.printer_type}
                                                                            print_head={print_head}/>
                                                                    </div>
                                                                )
                                                            }
                                                        </Col>
                                                        <Col sm="6">
                                                            <div className="d-flex mb-1">
                                                                <div className="badge badge-dark text-yellow"> PH
                                                                    White
                                                                </div>
                                                            </div>
                                                            {
                                                                claim.printer.print_heads && claim.printer.print_heads.filter(print_head => print_head.installed === 1 && print_head.color === "white").map((print_head, key) =>
                                                                    <div key={key} className="d-flex mb-1">
                                                                        <PrintheadStatus
                                                                            printer_type={claim.printer.printer_type}
                                                                            print_head={print_head}/>
                                                                    </div>
                                                                )
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardBody className="border-top">
                                        <div className="d-flex">
                                            <div className="avatar-sm mr-3"/>
                                            <div className="mr-3">
                                                <i className="bx bx-user-circle font-size-24"/>
                                            </div>
                                            <div className="text-truncate">
                                                <p className="mb-1 font-size-18 font-bold">{claim.dealer.name}</p>
                                                <p className="mb-0">{claim.engineer.firstname} {claim.engineer.lastname}</p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                                {((authUser.user.role === "manager" || authUser.user.role === "staff" || authUser.user.role === "sales") && claim.status === 3 && (claim.final_check_status === 1 || claim.final_check_status === 2)) ?
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-3">
                                                BIL / CN / FOC
                                            </CardTitle>
                                            <Row className="mb-3">
                                                {claim.parts_order_no &&
                                                <Col>
                                                    <div
                                                        className="d-inline-block border border-yellow rounded py-2 px-4 mb-1">
                                                        <strong>{claim.parts_order_no ? claim.parts_order_no : "/"}</strong>
                                                    </div>
                                                    <h6>BIMH CN / FOC</h6>
                                                </Col>
                                                }
                                                {claim.final_check_status === 1 &&
                                                <Col>
                                                    <div
                                                        className="d-inline-block border border-yellow rounded py-2 px-4 mb-1">
                                                        {
                                                            claim.salesforce_id !== "" ?
                                                                <a href={"https://ap.salesforce.com/" + claim.salesforce_id}
                                                                   target="_blank"
                                                                   rel="noopener noreferrer"><strong>{claim.r_value ? claim.r_value : "/"}</strong></a> :
                                                                <strong>{claim.r_value ? claim.r_value : "/"}</strong>
                                                        }

                                                    </div>
                                                    <h6>Salesforce</h6>
                                                </Col>}
                                            </Row>
                                            {
                                                !claim.parts_order_no && <ModalAddPartsOrderNo claim={claim}/>
                                            }
                                        </CardBody>
                                    </Card> : ''
                                }

                                <ClaimHistory claim={claim}/>

                                {['manager', 'staff', 'sales', 'engineer'].includes(authUser.user.role) && claim.status === 3 && claim.individual_reply !== "" ?
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-3">
                                                Warranty not granted reply
                                            </CardTitle>
                                            <p>{claim.individual_reply}</p>
                                        </CardBody>
                                    </Card> : ''
                                }

                                {['manager', 'staff'].includes(authUser.user.role) && claim.status === 2 && (claim.internal_check_status === 0 || claim.internal_check_status === null) ?
                                    <Card className="bg-yellow">
                                        <CardBody>
                                            <h4 className="card-title text-dark">Internal Guarantee<br/>Check
                                                for {claim.fault_report_no}</h4>
                                            <p>The internal check needs to be confirmed by the Technical Manager</p>
                                            <ModalInternalCheck claim={claim}/>
                                        </CardBody>
                                    </Card> : ''
                                }

                                {(authUser.user.role === "manager") && claim.status === 2 && claim.internal_check_status !== null && claim.internal_check_status > 0 ?
                                    <Card className="bg-yellow">
                                        <CardBody>
                                            <h4 className="card-title text-dark">Final Warranty<br/>Check
                                                for {claim.fault_report_no}</h4>
                                            <p>The warranty check triggers e-mails and establishes a connection to
                                                Salesforce</p>
                                            <ModalFinalCheck claim={claim}/>
                                        </CardBody>
                                    </Card> : ''
                                }

                            </Col>
                            <Col xl="8">
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-4">Claim Details</h4>
                                        <Row>
                                            <Col lg="4">
                                                <div className="border p-3 rounded full-height">
                                                    <div className="mt-3">
                                                        <h5>{claim.fault_report_no}</h5>
                                                    </div>
                                                    <div className="mt-3">
                                                        <ClaimStatus status={claim.status}
                                                                     final_check_status={claim.final_check_status}
                                                                     internal_check_status={claim.internal_check_status}
                                                                     fontsize="font-size-14"/>
                                                    </div>
                                                    <div className="mt-3">
                                                        <p><strong className="text-yellow">Machine prints</strong></p>
                                                        <div
                                                            className="d-inline-block border border-yellow rounded py-2 px-4">
                                                            <strong>{claim.number_of_prints}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg="4">
                                                <div className="border p-3 rounded full-height">
                                                    <div className="mt-3">
                                                        <h6>Dealer & Engineer</h6>
                                                        <h5>{claim.dealer.name}</h5>
                                                        <p className="mb-2">{claim.engineer.firstname + " " + claim.engineer.lastname}<br/>{claim.dealer.street} {claim.dealer.street_no}<br/>{claim.dealer.zip_code} {claim.dealer.city}<br/>{claim.dealer.country}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>
                                            {claim.customer ?
                                                <Col lg="4">
                                                    <div className="border p-3 rounded full-height">
                                                        <div className="mt-3">
                                                            <h6>Customer</h6>
                                                            <h5>{claim.customer.name}</h5>
                                                            <p className="mb-2">{claim.customer.street} {claim.customer.street_no}<br/>{claim.customer.zip_code} {claim.customer.city}<br/>{claim.customer.country}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                : ''
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={3}>
                                                <h4 className="card-title mb-2">Date of replacement</h4>
                                                <div className="d-inline-block border border-yellow rounded p-2">
                                                    <div className="d-flex">
                                                        <i className="bx bx-refresh bx-sm mr-2"/>
                                                        <Moment format="DD-MM-YYYY">{claim.date_of_visit}</Moment>
                                                    </div>
                                                </div>
                                            </Col>
                                            {claim.print_head_replacements && claim.print_head_replacements.length > 0 &&
                                            <Col md={6}>
                                                <h4 className="card-title mb-2">Print heads replaced</h4>
                                                {
                                                    claim.print_head_replacements && claim.print_head_replacements.map((print_head_replacement, key) =>
                                                        <div className="mb-2 d-flex" key={key}>
                                                            <div>
                                                                <div
                                                                    className="border border-yellow rounded py-2 px-4">
                                                                    {claim.printer.printer_type === "gtx600" ? `(${print_head_replacement.ph_position}) ` : ''}{print_head_replacement.original_serial_no}
                                                                </div>
                                                                <div
                                                                    className="badge badge-dark text-yellow"> PH {print_head_replacement.color === "color" ? "Colour" : "White"}</div>
                                                                {print_head_replacement.original_article_no && <div
                                                                    className="badge badge-dark text-yellow"> {print_head_replacement.original_article_no}</div>}
                                                            </div>
                                                            <img className="px-2 mb-4" src={arrowImg} alt={'→'}/>
                                                            <div>
                                                                <div
                                                                    className="border border-yellow rounded py-2 px-4">
                                                                    <strong>{claim.printer.printer_type === "gtx600" ? `(${print_head_replacement.ph_position}) ` : ''}{print_head_replacement.replaced_serial_no}</strong>
                                                                </div>
                                                                <div
                                                                    className="badge badge-dark text-yellow"> PH {print_head_replacement.color === "color" ? "Colour" : "White"}</div>
                                                                {print_head_replacement.replaced_article_no && <div
                                                                    className="badge badge-dark text-yellow"> {print_head_replacement.replaced_article_no}</div>}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </Col>}
                                            <Col md={3}>
                                                <h4 className="card-title mb-2">Error Code</h4>
                                                {claim.err_code &&
                                                <div
                                                    className="d-inline-block border border-yellow rounded py-2 px-4">
                                                    <strong>{claim.err_code}</strong>
                                                </div>
                                                }
                                            </Col>
                                        </Row>
                                        {claim.other_part_replacements && claim.other_part_replacements.length > 0 &&
                                        <Row className="mt-2">
                                            <Col md={8}>
                                                <h4 className="card-title mb-2">Other parts replaced</h4>
                                                {
                                                    claim.other_part_replacements && claim.other_part_replacements.map((other_part_replacement, key) =>
                                                        <div key={key}
                                                             className="d-inline-block border border-yellow rounded py-2 px-4 mr-2">
                                                            {other_part_replacement.number &&
                                                            <p className="mb-0">Number: {other_part_replacement.number}</p>}
                                                            {other_part_replacement.name &&
                                                            <p className="mb-0">Name: {other_part_replacement.name}</p>}
                                                            {other_part_replacement.quantity &&
                                                            <p className="mb-0">Quantity: {other_part_replacement.quantity}</p>}
                                                        </div>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                        }
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Detailed explanation</h4><p>{claim.information}</p>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-4">File Manager</h4>
                                        <ModalClaimAddFile claim={claim}/>
                                        <div className="table-responsive">
                                            <Table className="table table-centered table-nowrap">
                                                <thead className="thead-light">
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Date modified</th>
                                                    <th>Size</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    claim.files.map((file, key) =>
                                                        <tr key={"_file_" + key}>
                                                            <td>
                                                                <a href={file.link} target="_blank"
                                                                   rel="noopener noreferrer">
                                                                    <i className={"mdi " + file.iconClass + " font-size-16 align-middle text-primary mr-2"}/> {file.name.length > 50 ? `${file.name.substring(0, 50)}...` : file.name}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <Moment
                                                                    format="DD-MM-YYYY, HH:mm">{file.created_at}</Moment>
                                                            </td>
                                                            <td>
                                                                {this.formatBytes(file.size)}
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                                {(authUser.user.role === "manager" || authUser.user.role === "staff") &&
                                <Card>
                                    <CardBody>
                                        <Row>
                                            {claim.package_damaged &&
                                            <Col md={3}>
                                                <h4 className="card-title">Package damaged</h4>
                                                <div
                                                    className="d-inline-block border border-yellow rounded py-2 px-4 mb-1">
                                                    {claim.package_damaged}
                                                </div>
                                                {claim.package_damaged_file &&
                                                <a target="blank" className="btn btn-yellow"
                                                   href={claim.package_damaged_file.link}>Show picture</a>
                                                }
                                            </Col>
                                            }
                                            {claim.compensation &&
                                            <Col md={3}>
                                                <h4 className="card-title">Compensation</h4>
                                                <div
                                                    className="d-inline-block border border-yellow rounded py-2 px-4 mb-1">
                                                    {claim.compensation}
                                                </div>
                                            </Col>
                                            }
                                            {claim.storage_location &&
                                            <Col md={3}>
                                                <h4 className="card-title">Location</h4>
                                                <div
                                                    className="d-inline-block border border-yellow rounded py-2 px-4 mb-1">
                                                    {claim.storage_location}
                                                </div>
                                            </Col>
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                                }
                                {claim.comments && (authUser.user.role === "manager" || authUser.user.role === "staff") &&
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-between">
                                            <h4 className="card-title mb-4">Brother Comments</h4>
                                            <div><ModalAddComment claim={claim}/></div>
                                        </div>
                                        {
                                            // claim.comments.sort((a, b) => (a.created_at > b.created_at) ? 1 : -1).slice(1).map((comment, index) =>
                                            claim.comments.map((comment, index) =>
                                                <div className="p-2"
                                                     key={"comment-" + index}>
                                                    <strong>{comment.author_name} (<Moment
                                                        format="DD-MM-YYYY">{comment.created_at}</Moment>)</strong>
                                                    <p>{comment.content}</p>
                                                </div>)
                                        }
                                    </CardBody>
                                </Card>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {data} = state.Claim;
    return {data};
};

export default connect(mapStatetoProps, {
    getClaim
})(ClaimShow);
