import React from "react";

const DownloadFile = (props) => {

    const download = props.download;
    const index = props.index;

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    return (
        <React.Fragment>
            {download.extension === "mp4" ?
                <div
                    className={index > 0 ? "mt-2 pt-4 border-top border-light border-top-1" : ""}>
                    <h3>
                        <i className="bx bxl-youtube mr-2"></i> {download.name}
                    </h3>
                    <p>{download.description}</p>
                    <video height="400" controls>
                        <source src={download.url} type="video/mp4"/>
                    </video>
                </div> :
                <div
                    className={index > 0 ? "mt-2 pt-4 border-top border-light border-top-1" : ""}>
                    <h3>{download.extension === "pdf" ?
                        <i className="bx bxs-file-pdf mr-2"></i> :
                        <i className="bx bxs-file-blank mr-2"></i>} {download.name}
                    </h3>
                    <p>{download.description}</p>
                    {/*<a className="d-flex align-items-end" href={process.env.REACT_APP_APIURL + 'download/' + download.id}><i className="bx bx-right-arrow-alt font-size-24 mr-2"></i> Download (<span className="text-uppercase">{download.extension}</span>/{formatBytes(download.size)})</a>*/}
                    <a className="d-flex align-items-end" href={download.url}><i
                        className="bx bx-right-arrow-alt font-size-24 mr-2"></i> Download (<span
                        className="text-uppercase">{download.extension}</span>/{formatBytes(download.size)})</a>
                </div>
            }
        </React.Fragment>
    );
}

export default DownloadFile;
