import React, { FC } from 'react';
import { MDBBadge } from 'mdbreact';
import {
    Card,
    CardBody,
} from "reactstrap";
import { LoadingIndicator } from '../../LoadingIndicator';

interface EngineerCardProps {
    engineer?: FullEngineer,
    isLoading?: boolean
}

export const EngineerCard: FC<EngineerCardProps> = (
    {
        engineer,
        isLoading
    }) => {

    return (
        <Card>
            <CardBody>
                {engineer && !isLoading &&
                <div>
                    <h5>{engineer.firstname} {engineer.lastname}</h5>
                    <p>{engineer.dealer.name}</p>
                    <p><a href={"mailto:" + engineer.email}>{engineer.email}</a>
                    </p>
                    {engineer.street &&
                    <p className="text-muted mb-1">{engineer.street} {engineer.street_no}</p>}
                    {engineer.city &&
                    <p className="text-muted mb-1">{engineer.zip_code} {engineer.city}</p>}
                    {engineer.country &&
                    <p className="text-muted mb-1">{engineer.country}</p>}
                    {engineer.activation !== "1" ?
                        <MDBBadge pill
                                  color={'soft-warning'}>Unconfirmed</MDBBadge> :
                        <MDBBadge pill color={'soft-success'}>Confirmed</MDBBadge>
                    }
                </div>
                }
                {isLoading && <LoadingIndicator/>}
            </CardBody>
        </Card>
    );
}

