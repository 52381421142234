import React, {useState} from 'react';
import {Button, Row, Col, Modal} from "reactstrap";
import {AvField, AvForm, AvRadioGroup, AvRadio} from "availity-reactstrap-validation";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";

const ModalAddPartsOrderNo = (props) => {

    const claim = props.claim;

    const [modal, setModal] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const model = {};

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function toggleModal() {
        setModal(!modal);
        removeBodyCss();
    }

    function handleValidSubmit(event, values) {
        const partOrderNo = {
            type: values.type,
            number: values.number,
        }
        setIsLoading(true);
        sendPartsOrderNo(partOrderNo);
    }

    function sendPartsOrderNo(partOrderNo) {
        const url = process.env.REACT_APP_APIURL + 'claim/' + claim.id + '/partsorderno';
        const authToken = authHeader();

        const formData = new FormData();
        formData.append('data', JSON.stringify(partOrderNo));

        return axios.post(url, formData, {headers: authToken}).then(response => {
            setIsLoading(false);
            setSuccessMsg(true);
        }).catch(error => {
            setIsLoading(false);
            setErrorMsg(true);
        });
    }

    return (
        <React.Fragment>
            <button className="position-absolute top-15 right-15 p-0 waves-effect waves-light btn btn-transparent text-yellow"
                    type="button"
                    onClick={() => {
                        toggleModal()
                    }}
                    data-toggle="modal"
                    data-target=".bs-example-modal-center"
            >
                <i className="font-size-20 mdi mdi-square-edit-outline"></i>
            </button>
            <Modal
                size="lg"
                isOpen={modal}
                toggle={() => {
                    toggleModal()
                }}
                centered={true}
            >
                <div className="modal-body">
                    <button
                        type="button"
                        onClick={() => {
                            setModal(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Row>
                        <Col md={1}>
                            <div className="avatar-sm">
                                <span
                                    className="avatar-title rounded-circle bg-primary text-white">
                                    <i className="bx bx-refresh bx-sm rotateInUpLeft"></i>
                                </span>
                            </div>
                        </Col>
                        <Col md={11}>
                            <div className="mb-2">
                                <h5 className="modal-title mt-0">Add Parts Order No</h5>
                            </div>
                            <div className="mb-3">
                                <AvForm className="form-horizontal mt-4" model={model} onValidSubmit={(e, v) => {
                                    handleValidSubmit(e, v)
                                }}>
                                    <Row>
                                        <Col md="12">
                                            <AvRadioGroup inline name="type" required>
                                                <AvRadio customInput label="CN" value="CN"/>
                                                <AvRadio customInput label="FOC" value="FOC"/>
                                            </AvRadioGroup>
                                        </Col>
                                        <Col md="12">
                                            <AvField rows="5" type="text" name="number" label="Number"
                                                     required
                                                     errorMessage="Please provide a valid number."/>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md="8"></Col>
                                        <Col md="4">
                                            <div className="text-sm-right">
                                                {isLoading ?
                                                    <Button className="btn btn-yellow" type="submit" disabled={true}>
                                                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i> Add
                                                        Parts Order No
                                                    </Button> :
                                                    <Button className="btn btn-yellow" type="submit">
                                                        <i className="bx bx-plus pt-1"></i> Add
                                                        Parts Order No
                                                    </Button>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </AvForm>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
            {successMsg ? <SweetAlert
                title="Good job!"
                success
                showConfirm={true}
                showCancel={false}
                confirmBtnBsStyle="yellow"
                onConfirm={() => {
                    window.location.reload();
                }}
            >
                Parts Order Number was submitted successfully.
            </SweetAlert> : null}
            {errorMsg ? <SweetAlert
                title="Request could not be submitted!"
                warning
                showConfirm={true}
                showCancel={false}
                confirmBtnBsStyle="yellow"
                onConfirm={() => {
                    setErrorMsg(false);
                }}
            >
                There was an error while submitting your request. Please try again or get in contact with our
                team.
            </SweetAlert> : null}
        </React.Fragment>
    );
}

export default ModalAddPartsOrderNo;