import React, { FC } from "react";
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { MuiThemeProvider } from '@material-ui/core';
import { MuiToolbarTheme } from '../../helpers/themes/MuiThemes';
import Moment from 'react-moment';
import PrinterType from './printerType';
import { actionColumn, DataList, TableAction } from '../../helpers/Table/TableAction';
import { MDBBadge } from 'mdbreact';

interface PrintersTableProps {
    printers: Printer[],
    isLoading?: boolean,
}

interface PrinterRow {
    printer_serial_no: string,
    printer_type: string,
    installation_date: string,
    guarantee_state: number,
    actions: TableAction[],
}

export const PrintersTable: FC<PrintersTableProps> = (
    {
        printers,
        isLoading
    }
) => {

    const dataList: DataList = {
        columns: [
            {
                label: "Serial Number",
                name: "printer_serial_no",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                label: "Model",
                name: "printer_type",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (printerType) => <PrinterType printer_type={printerType}/>
                }
            },
            {
                label: "Installation",
                name: "installation_date",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (installation_date) => <Moment format="DD-MM-YYYY">{installation_date}</Moment>
                }
            },
            {
                label: "Printer Status",
                name: "guarantee_state",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (guarantee_state) =>
                        <MDBBadge
                            pill
                            color={guarantee_state === 1 ? 'soft-success' : 'soft-danger'}
                        >
                            {guarantee_state === 1 ? 'In guarantee' : 'Not in guarantee'}
                        </MDBBadge>
                }
            },
            actionColumn
        ],
        rows: [...printers.map((printer) => ({
            printer_serial_no: printer.printer_serial_no,
            printer_type: printer.printer_type,
            installation_date: printer.installation_date,
            guarantee_state: printer.guarantee_state,
            actions: [
                {
                    type: 'Link',
                    value: {
                        pathname: "/printer/" + printer.id,
                        state: {printerId: printer.id}
                    }
                }
            ]
        }))]
    };

    const options: MUIDataTableOptions = {
        download: false,
        filter: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        rowsPerPage: 10,
        rowsPerPageOptions: [],
        selectableRows: 'none',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LoadingIndicator fontSize={24}/> : "Sorry, no matching records found"
            }
        }
    }

    return (
        <MuiThemeProvider theme={MuiToolbarTheme}>
            <MUIDataTable
                title={<h4 className="card-title">Printer Overview</h4>}
                data={isLoading ? [] : dataList.rows}
                columns={dataList.columns}
                options={options}
            />
        </MuiThemeProvider>
    );
}
