export enum CLAIM_STATUS {
    NONE,
    OPEN,
    IN_PROGRESS,
    FINAL_CHECK
}

export enum CLAIM_FINAL_CHECK_STATUS {
    NONE,
    GRANTED,
    INTERNAL_GRANTED,
    NOT_GRANTED
}

export enum CLAIM_REPLACEMENT_TYPE {
    NONE,
    PRINT_HEAD,
    OTHER_PART
}

export enum CLAIM_INTERNAL_CHECK_STATUS {
    NONE,
    GRANTED,
    INTERNAL_GRANTED,
    NOT_GRANTED
}
