import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Button,
    Row,
    Col,
    Modal,
    Label
} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import EditIcon from "@material-ui/icons/Edit";
import {IconButton} from "@material-ui/core";
import ListPrinterTypes from "../../components/Lists/ListPrinterTypes";
import {Editor} from "react-draft-wysiwyg";
import {EditorState, ContentState, convertFromHTML} from 'draft-js';
import {convertToHTML} from 'draft-convert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {editNotification} from "../../store/notification/actions";
import {createLoadingSelector} from "../../selectors/loading";
import {createErrorSelector} from "../../selectors/error";
import {EDIT_NOTIFICATION} from "../../store/notification/actionTypes";

interface NotificationEditProps {
    notification: OverviewNotification,
}

const NotificationEdit: FC<NotificationEditProps> = ({
    notification
}) => {
    const dispatch = useDispatch();

    const loadingSelector = useSelector(createLoadingSelector([EDIT_NOTIFICATION]));
    const errorSelector = useSelector(createErrorSelector([EDIT_NOTIFICATION]));

    const [modal, setModal] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(
        convertFromHTML(notification.message)
    )));
    const [selectedPrinterType, setSelectedPrinterType] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const model = {};

    useEffect(() => {
        if (submitted) {
            setSuccessMsg(!loadingSelector && errorSelector.length === 0);
            setErrorMsg(!loadingSelector && errorSelector.length > 0);
        }
    }, [loadingSelector, errorSelector, submitted]);

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function toggleModal() {
        setModal(!modal);
        removeBodyCss();
    }

    function handleSelectPrinterType(selectedPrinterType) {
        setSelectedPrinterType(selectedPrinterType.value);
    }

    function handleEditorStateChange(editorState) {
        setEditorState(editorState);
    }

    function handleValidSubmit(event, values) {

        setSubmitted(true);

        const notificationTemp = {
            id: notification.id,
            subject: values.subject,
            message: convertToHTML(editorState.getCurrentContent()),
        }

        dispatch(editNotification(notificationTemp));
    }

    return (
        <>
            <IconButton
                aria-label="edit"
                onClick={() => setModal(true)}
                disabled={isLoading}
            >
                <EditIcon/>
            </IconButton>
            <Modal
                size="lg"
                isOpen={modal}
                toggle={() => {
                    toggleModal()
                }}
                centered={true}
            >
                <div className="modal-body">
                    <button
                        type="button"
                        onClick={() => {
                            setModal(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Row>
                        <Col md={1}>
                            <div className="avatar-sm">
                                <span
                                    className="avatar-title rounded-circle bg-primary text-white">
                                    <i className="bx bxs-cloud-upload bx-sm"></i>
                                </span>
                            </div>
                        </Col>
                        <Col md={11}>
                            <div className="mb-2">
                                <h5 className="modal-title mt-0">Edit Notification</h5>
                            </div>
                            <div className="mb-3">
                                <AvForm className="form-horizontal mt-4" model={model} onValidSubmit={(e, v) => {
                                    handleValidSubmit(e, v)
                                }}>
                                    <Row>
                                        <Col md="6">
                                            <AvField type="text" name="subject" label="Subject"
                                                     value={notification.subject} required/>
                                        </Col>
                                        <Col md="12">
                                            <Editor
                                                editorState={editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={(e) => handleEditorStateChange(e)}
                                                toolbar={{options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'embedded', 'image', 'remove', 'history']}}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md="12">
                                            {isLoading ?
                                                <Button className="btn btn-yellow d-flex align-items-center ml-auto"
                                                        type="submit" disabled={true}>
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                    Submit changes
                                                </Button> :
                                                <Button className="btn btn-yellow d-flex align-items-center ml-auto"
                                                        type="submit">
                                                    <i className="bx bxs-cloud-upload font-size-24 mr-2"></i>
                                                    Submit changes
                                                </Button>
                                            }
                                        </Col>
                                    </Row>
                                </AvForm>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
            {successMsg ? <SweetAlert
                title="Good job!"
                success
                showConfirm={true}
                showCancel={false}
                confirmBtnBsStyle="yellow"
                onConfirm={() => {
                    window.location.reload();
                }}
            >
                Changes submitted successfully.
            </SweetAlert> : null}
            {errorMsg ? <SweetAlert
                title="Request could not be submitted!"
                warning
                showConfirm={true}
                showCancel={false}
                confirmBtnBsStyle="yellow"
                onConfirm={() => {
                    setErrorMsg(false);
                }}
            >
                There was an error while submitting your request. Please try again or get in contact with our
                team.
            </SweetAlert> : null}
        </>
    );
}

export default NotificationEdit;