// @flow
import {
	GET_CUSTOMERS_SUCCESS,
} from "./actionTypes";

const initialState = {

}

const Customers = (state = initialState, action) => {
	switch (action.type) {
		case GET_CUSTOMERS_SUCCESS:
			return action.payload

		default:
			return state;
	}
};

export default Customers;
