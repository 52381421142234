import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import AvSelect from "../Common/AvSelect";
import { AvFeedback, AvGroup } from "availity-reactstrap-validation";
import { getDealers } from '../../store/dealers/actions';

const ListDealers = (props) => {

    const [dealers, setDealers] = useState([]);
    const [selectedDealer, setSelectedDealer] = useState([]);

    useEffect(() => {
        props.getDealers();
    },[]);

    useEffect(() => {
        if (props.data) {
            setDealers(props.data.map(dealer => ({
                ...dealer,
                value: dealer.id,
                label: dealer.name
            })));
        }
    }, [props.data]);

    useEffect(() => {
        if(props.initialSelect) {
            handleSelectDealer(dealers.find(dealer => dealer.id === props.initialSelect.id));
        }
    }, [props.initialSelect, dealers]);

    function handleSelectDealer(dealer) {
        setSelectedDealer(dealer);
        props.onChange(dealer);
    }

    return (
        <AvGroup>
            <AvSelect
                name="dealer"
                value={selectedDealer}
                options={dealers}
                helpMessage="Select a company."
                validate={{required: {value: true}}}
                onChange={(dealer) => {
                    handleSelectDealer(dealer)
                }}
                raw={true}
            />
            <AvFeedback>Please choose a valid option.</AvFeedback>
        </AvGroup>
    );
}

const mapStateToProps = state => {
    const {data} = state.Dealers;
    return {data};
};

export default connect(mapStateToProps, {
    getDealers
})(ListDealers);
