import {combineReducers} from "redux";
import Layout from "./layout/reducer";
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import ResetPassword from "./auth/resetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Claims from "./claims/reducer";
import Claim from "./claim/reducer";
import Printers from "./printers/reducer";
import Printer from "./printer/reducer";
import Customers from "./customers/reducer";
import Customer from "./customer/reducer";
import Dispatches from "./dispatches/reducer";
import Dealers from "./dealers/reducer";
import Dealer from "./dealer/reducer";
import Engineers from "./engineers/reducer";
import Engineer from "./engineer/reducer";
import Services from "./services/reducer";
import Overtakes from './overtakes/reducer';
import Loading from './loading/reducer';
import Error from './error/reducer';
import Notifications from './notifications/reducer';
import Notification from './notification/reducer';
import UserNotifications from './user_notifications/reducer';

const rootReducer = combineReducers({
    Layout,
    Login,
    Account,
    ForgetPassword,
    ResetPassword,
    Profile,
    Claims,
    Claim,
    Customers,
    Customer,
    Dispatches,
    Dealers,
    Dealer,
    Engineers,
    Engineer,
    Error,
    Loading,
    Notifications,
    Notification,
    Services,
    Printers,
    Printer,
    Overtakes,
    UserNotifications,
});

export default rootReducer;
