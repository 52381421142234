import React from "react";
import {Alert, Col, FormGroup, Label, Row} from "reactstrap";
import {AvField} from "availity-reactstrap-validation";
import Printhead from "./printhead";
import Vrate from "./vrate";

const Gtx600 = (props) => {

    return (
        <React.Fragment>
            <FormGroup className="mb-4">
                <Label className="col-form-label">Print Head Serials</Label>
                <Alert color="danger" role="alert">
                    Please note: the following print head serial numbers are the original print heads that are installed
                    with the installation
                </Alert>
                <table style={{width: "100%"}}>
                    <tbody>
                    <tr>
                        <td>
                            <Row className="mb-2 px-2">
                                <Label md="2"
                                       className="col-form-label">PH
                                    Serial white *</Label>
                                <Col md="4">
                                    <Printhead example="6WA1234" name="print_heads[gtx600][0][serial]"
                                               placeholder="White PH (A)*" pattern="^[0-9]{1}[A-Z]{2}[0-9]+$"/>
                                    <AvField
                                        name="print_heads[gtx600][0][position]"
                                        value="A"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gtx600][0][color]"
                                        value="white"
                                        type="text"
                                        hidden={true}
                                    />
                                </Col>

                                <Label md="2"
                                       className="col-form-label">W(A) V.Rating
                                    *</Label>
                                <Col md="4">
                                    <Vrate name="print_heads[gtx600][0][vrate]"/>
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Row className="mb-2 px-2">
                                <Label md="2"
                                       className="col-form-label">PH
                                    Serial white *</Label>
                                <Col md="4">
                                    <Printhead example="6WA1234" name="print_heads[gtx600][1][serial]"
                                               placeholder="White PH (B)*" pattern="^[0-9]{1}[A-Z]{2}[0-9]+$"/>
                                    <AvField
                                        name="print_heads[gtx600][1][position]"
                                        value="B"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gtx600][1][color]"
                                        value="white"
                                        type="text"
                                        hidden={true}
                                    />
                                </Col>

                                <Label md="2"
                                       className="col-form-label">W(B) V.Rating
                                    *</Label>
                                <Col md="4">
                                    <Vrate name="print_heads[gtx600][1][vrate]"/>
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Row className="mb-2 px-2">
                                <Label md="2"
                                       className="col-form-label">PH
                                    Serial colors *</Label>
                                <Col md="4">
                                    <Printhead example="6CA1234" name="print_heads[gtx600][2][serial]"
                                               placeholder="Colour PH (A)*" pattern="^[0-9]{1}[A-Z]{2}[0-9]+$"/>
                                    <AvField
                                        name="print_heads[gtx600][2][position]"
                                        value="A"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gtx600][2][color]"
                                        value="color"
                                        type="text"
                                        hidden={true}
                                    />
                                </Col>

                                <Label md="2"
                                       className="col-form-label">C(A) V.Rating
                                    *</Label>
                                <Col md="4">
                                    <Vrate name="print_heads[gtx600][2][vrate]"/>
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Row className="mb-2 px-2">
                                <Label md="2"
                                       className="col-form-label">PH
                                    Serial colors *</Label>
                                <Col md="4">
                                    <Printhead example="6CA1234" name="print_heads[gtx600][3][serial]"
                                               placeholder="Colour PH (B)*" pattern="^[0-9]{1}[A-Z]{2}[0-9]+$"/>
                                    <AvField
                                        name="print_heads[gtx600][3][position]"
                                        value="B"
                                        type="text"
                                        hidden={true}
                                    />
                                    <AvField
                                        name="print_heads[gtx600][3][color]"
                                        value="color"
                                        type="text"
                                        hidden={true}
                                    />
                                </Col>

                                <Label md="2"
                                       className="col-form-label">C(B) V.Rating
                                    *</Label>
                                <Col md="4">
                                    <Vrate name="print_heads[gtx600][3][vrate]"/>
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </FormGroup>
        </React.Fragment>
    );
}

export default Gtx600;
