import React, {useState} from 'react';
import {Button, Row, Col, Label, Modal} from "reactstrap";
import {AvFeedback, AvForm, AvGroup} from "availity-reactstrap-validation";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import SweetAlert from "react-bootstrap-sweetalert";

import AvSelect from "../Common/AvSelect";
import axios from "axios";
import {getLoggedInUser} from "../../helpers/fakebackend_helper";

const roleListAdmin = [
    {
        options: [
            {label: "Brother Manager", value: 1},
            {label: "Brother Staff", value: 2},
            {label: "Brother Sales", value: 3},
            {label: "Dealer Sales", value: 4},
            {label: "Dealer Engineer", value: 5},
            {label: "Dispatcher", value: 6},
        ]
    }
];

const roleListOthers = [
    {
        options: [
            {label: "Brother Sales", value: 3},
            {label: "Dealer Sales", value: 4},
            {label: "Dealer Engineer", value: 5},
        ]
    }
];

const ModalConfirmEngineer = (props) => {

    const [modal, setModal] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const engineer = props.engineer;
    const authUser = getLoggedInUser();

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function toggleModal() {
        setModal(!modal);
        removeBodyCss();
    }

    function handleValidSubmit(event, values) {
        const confirmedEngineer = {
            id: engineer.id,
            role: values.role.value,
        }
        setIsLoading(true);
        confirmEngineer(confirmedEngineer);
    }

    function confirmEngineer(engineer) {
        const url = process.env.REACT_APP_APIURL + 'engineer/confirm';
        const authToken = authHeader();

        const formData = new FormData();
        formData.append('data', JSON.stringify(engineer));

        return axios.post(url, formData,{headers: authToken}).then(response => {
            setIsLoading(false);
            setSuccessMsg(true);
        }).catch(error => {
            setIsLoading(false);
            setErrorMsg(true);
        });
    }

    return (
        <React.Fragment>
            <button className="mr-1 waves-effect waves-light btn btn-dark"
                    type="button"
                    onClick={() => {
                        toggleModal()
                    }}
                    data-toggle="modal"
                    data-target=".bs-example-modal-center"
            >
                Confirm Registration
            </button>
            <Modal
                size="lg"
                isOpen={modal}
                toggle={() => {
                    toggleModal()
                }}
                centered={true}
            >
                <div className="modal-body">
                    <button
                        type="button"
                        onClick={() => {
                            setModal(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Row className="d-flex mb-3">
                        <Col md={1}>
                            <div className="avatar-sm">
                                <span
                                    className="avatar-title rounded-circle bg-primary text-white">
                                    <i className="bx bx-check-circle bx-sm"></i>
                                </span>
                            </div>
                        </Col>
                        <Col md={11}>
                            <div className="mb-2">
                                <h5 className="modal-title mt-0">Confirmation of registration</h5>
                                <p>Please confirm the following registration</p>
                                <AvForm className="form-horizontal mt-4"
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v)
                                        }}>
                                    <Row>
                                        <Col md="12">
                                            <h5>{engineer.firstname} {engineer.lastname}</h5>
                                            <p>{engineer.dealer.name}</p>
                                            <p>
                                                {engineer.street} {engineer.street_no}<br/>
                                                {engineer.zip_code} {engineer.city}<br/>
                                                {engineer.country}
                                            </p>
                                        </Col>
                                        <Col md="12">
                                            <AvGroup>
                                                <Label for="reply">Choose a role</Label>
                                                <AvSelect
                                                    name="role"
                                                    options={authUser.user && ['manager'].includes(authUser.user.role) ? roleListAdmin : roleListOthers}
                                                    helpMessage="Select a role."
                                                    raw={true}
                                                    validate={{required: {value: true}}}
                                                />
                                                <AvFeedback>Please choose a valid option.</AvFeedback>
                                            </AvGroup>
                                        </Col>

                                    </Row>
                                    <div className="text-sm-right">
                                        {isLoading ?
                                            <Button className="btn btn-yellow" type="submit" disabled={true}>
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i> Confirm
                                                Registration
                                            </Button> :
                                            <Button className="btn btn-yellow" type="submit">
                                                <i className="mdi mdi-check mr-1"></i> Confirm Registration
                                            </Button>
                                        }
                                    </div>
                                </AvForm>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
            {successMsg ? <SweetAlert
                title="Good job!"
                success
                showConfirm={true}
                showCancel={false}
                confirmBtnBsStyle="yellow"
                onConfirm={() => {
                    // setSuccessMsg(false);
                    // setModal(false);
                    window.location.reload();
                }}
            >
                Confirmation was submitted successfully.
            </SweetAlert> : null}
            {errorMsg ? <SweetAlert
                title="Request could not be submitted!"
                warning
                showConfirm={true}
                showCancel={false}
                confirmBtnBsStyle="yellow"
                onConfirm={() => {
                    setErrorMsg(false);
                }}
            >
                There was an error while submitting your request. Please try again or get in contact with our
                team.
            </SweetAlert> : null}
        </React.Fragment>
    );
}

export default ModalConfirmEngineer;