// @flow
import {all, call, fork, takeEvery, put} from "redux-saga/effects";

import {
    GET_USER_NOTIFICATIONS_REQUEST, SET_USER_NOTIFICATIONS_SEEN
} from "./actionTypes";
import {getUserNotificationsFromBackend,setUserNotificationsSeenInBackend} from '../../api/user_notifications';
import {getUserNotificationsError, getUserNotificationsSuccess} from './actions';
import {setUserNotificationsSeenError, setUserNotificationsSeenSuccess} from './actions';

/**
 * Retrieving User Notifications
 */
function* getUserNotifications() {
    try {
        const data = yield call(getUserNotificationsFromBackend);
        yield put(getUserNotificationsSuccess(data));

    } catch (error) {
        yield put(getUserNotificationsError(error));
    }
}

/**
 * Set User Notifications as seen
 */
function* setUserNotificationsSeen() {
    try {
        const data = yield call(setUserNotificationsSeenInBackend);
        yield put(setUserNotificationsSeenSuccess(data));

    } catch (error) {
        yield put(setUserNotificationsSeenError(error));
    }
}

/**
 * Watchers
 */
export function* watchActions() {
    yield takeEvery(GET_USER_NOTIFICATIONS_REQUEST, getUserNotifications);
    yield takeEvery(SET_USER_NOTIFICATIONS_SEEN, setUserNotificationsSeen);
}

function* UserNotificationsSaga() {
    yield all([
        fork(watchActions),
    ]);
}

export default UserNotificationsSaga;
