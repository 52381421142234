// @flow
import {all, call, fork, takeEvery, put} from "redux-saga/effects";

import {
    GET_PRINTERS_REQUEST,
} from "./actionTypes";
import { getPrintersError, getPrintersSuccess } from './actions';

import { getPrintersFromBackend } from '../../api/printers';

/**
 * Retrieving Printers
 * @param {*} param0
 */
function* getPrinters({payload: user}) {
    try {
        const data = yield call(getPrintersFromBackend);
        yield put(getPrintersSuccess(data));
    } catch (error) {
        yield put(getPrintersError(error));
    }
}

/**
 * Watchers
 */
export function* watchGetPrinters() {
    yield takeEvery(GET_PRINTERS_REQUEST, getPrinters);
}

function* PrintersSaga() {
    yield all([
        fork(watchGetPrinters),
    ]);
}

export default PrintersSaga;
