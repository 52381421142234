import React, { FC, useMemo } from 'react';
import { DataList } from '../../../helpers/Table/TableAction';
import { MuiThemeProvider, Typography } from '@material-ui/core';
import { MuiToolbarThemeWithHover } from '../../../helpers/themes/MuiThemes';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { LoadingIndicator } from '../../LoadingIndicator';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';

interface ServiceListProps {
    isLoading: boolean,
    services: OverviewService[]
}

export const ServiceList: FC<ServiceListProps> = ({
    isLoading,
    services
}) => {

    const history = useHistory();

    const columns = [
        {
            label: "Printer ID",
            name: "printer_id",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Printer Serial",
            name: "printer_serial",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Service Type",
            name: "service_type",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Number of Prints",
            name: "number_of_prints",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Date of Visit",
            name: "date_of_visit",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (date_of_visit) => <Moment format="DD-MM-YYYY">{date_of_visit}</Moment>,
            }
        },
        {
            label: "Input",
            name: "created_at",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (created_at) => <Moment format="DD-MM-YYYY">{created_at}</Moment>,
            }
        },
        {
            label: "Engineer",
            name: "engineer_name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Dealer",
            name: "dealer_name",
            options: {
                filter: true,
                sort: true,
            }
        },
    ];

    const dataList: DataList = useMemo(
    () => ({
        columns: [...columns],
        rows: [...services.map(
            service => ({
                printer_id: service.printer.id,
                printer_serial: service.printer_serial,
                service_type: service.service_type,
                number_of_prints: service.number_of_prints,
                date_of_visit: service.date_of_visit,
                created_at: service.created_at,
                engineer_name: service.engineer ? service.engineer.firstname + " " + service.engineer.lastname : "",
                dealer_name: service.dealer ? service.dealer.name : "", // @TODO currently no dealer
            })
        )]
    }), [services, columns],
  );

    const options: MUIDataTableOptions = {
        download: false,
        filter: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        rowsPerPage: 50,
        rowsPerPageOptions: [],
        selectableRows: 'none',
        onRowClick: (rowData) => history.push('/printer/' + rowData[0]),
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <LoadingIndicator fontSize={24}/> : "Sorry, no matching records found"
            }
        }
    }

    return (
        <MuiThemeProvider theme={MuiToolbarThemeWithHover}>
            <MUIDataTable
                title={!isLoading ? 'Services' : <Typography variant="h6">Services <LoadingIndicator /></Typography>}
                data={dataList.rows}
                columns={dataList.columns}
                options={options}
            />
        </MuiThemeProvider>
    );
}
