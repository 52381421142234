// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
    GET_NOTIFICATIONS_REQUEST
} from "./actionTypes";
import { getNotificationsFromBackend } from '../../api/notifications';
import { getNotificationsError, getNotificationsSuccess } from './actions';

/**
 * Retrieving Dealer
 */
function* getNotifications() {
    try {
        const data = yield call(getNotificationsFromBackend);
        yield put(getNotificationsSuccess(data));

    } catch (error) {
        yield put(getNotificationsError(error));
    }
}

/**
 * Watchers
 */
export function* watchActions() {
    yield takeEvery(GET_NOTIFICATIONS_REQUEST, getNotifications);
}

function* NotificationsSaga() {
    yield all([
        fork(watchActions),
    ]);
}

export default NotificationsSaga;
