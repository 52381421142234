import React from 'react';
import { Container, Row, Col } from "reactstrap";

const Footer = (props) => {
  return (
   <React.Fragment>
            <footer className="footer">
                <Container fluid={true}>
                    <Row>
                        <Col>
                            {new Date().getFullYear()} © Brother Digital
                        </Col>
                        <Col>
                            <div className="text-sm-right d-none d-sm-block">
                                <a className="px-1" href="https://base.brother.digital/imprint/">Imprint</a>
                                <a className="px-1" href="https://base.brother.digital/imprint/">Contact</a>
                                <a className="px-1" href="https://base.brother.digital/dataprivacy/">Dataprivacy</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
  );
}

export default Footer;