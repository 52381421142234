import {
	GET_SERVICES_ERROR,
	GET_SERVICES_REQUEST,
	GET_SERVICES_SUCCESS,
} from "./actionTypes";

export const getServices = () => ({
	type: GET_SERVICES_REQUEST
});

export const getServicesSuccess = (data) => {
	return{
		type: GET_SERVICES_SUCCESS,
		payload: data
	}
};

export const getServicesError = (error) => {
	return{
		type: GET_SERVICES_ERROR,
		payload: error
	}
};




