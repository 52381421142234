export enum PRINTER_TYPE {
    NONE,
    GT3,
    GTX,
    GTXPRO,
    GTXPRO_BULK,
    GTX600
}

export const PrinterTypeMap = new Map<string, PRINTER_TYPE>([
    ['gt341', PRINTER_TYPE.GT3],
    ['gt361', PRINTER_TYPE.GT3],
    ['gt381', PRINTER_TYPE.GT3],
    ['gt-3', PRINTER_TYPE.GT3],
    ['gtx', PRINTER_TYPE.GTX],
    ['gtx', PRINTER_TYPE.GTX],
    ['gtxpro', PRINTER_TYPE.GTXPRO],
    ['gtxprobulk', PRINTER_TYPE.GTXPRO_BULK],
    ['gtx600', PRINTER_TYPE.GTX600],
]);

export enum PRINTER_GUARANTEE_STATUS {

}
