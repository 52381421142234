// @flow
import {all, call, fork, takeEvery, put} from "redux-saga/effects";

import {
    GET_DISPATCHES
} from "./actionTypes";
import { dispatchesReceived } from './actions';

import { getDispatchesFromBackend } from '../../api/dispatch';
import {apiError} from "../auth/login/actions";

/**
 * Retrieving Claims
 * @param {*} param0
 */
function* getDispatches({payload: user}) {
    try {
        const data = yield call(getDispatchesFromBackend);
        yield put(dispatchesReceived(data));

    } catch (error) {
        yield put(apiError(error));
    }
}

/**
 * Watchers
 */
export function* watchActions() {
    yield takeEvery(GET_DISPATCHES, getDispatches);
}

function* DispatchesSaga() {
    yield all([
        fork(watchActions),
    ]);
}

export default DispatchesSaga;
