// @flow
import {all, call, fork, takeEvery, put} from "redux-saga/effects";

import {
    GET_CUSTOMERS_REQUEST
} from "./actionTypes";

import { getCustomersFromBackend } from '../../api/customers';
import { getCustomersError, getCustomersSuccess } from './actions';

/**
 * Retrieving Customer
 */
function* getCustomers() {
    try {
        const data = yield call(getCustomersFromBackend);
        yield put(getCustomersSuccess(data));

    } catch (error) {
        yield put(getCustomersError(error));
    }
}

/**
 * Watchers
 */
export function* watchActions() {
    yield takeEvery(GET_CUSTOMERS_REQUEST, getCustomers);
}

function* CustomersSaga() {
    yield all([
        fork(watchActions),
    ]);
}

export default CustomersSaga;
