import React, { useEffect, useState } from 'react';
import AvSelect from "../Common/AvSelect";
import { AvFeedback, AvGroup } from "availity-reactstrap-validation";

const ListCountries = ({onChange, initialSelect, required}) => {

    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState();

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        if (initialSelect && !country) {
            handleSelectCountry(countries.find(country => country.name === initialSelect));
        }
    }, [countries, country, initialSelect]);

    // @TODO rewrite this, no direct fetch in component
    function getCountries() {
        const url = process.env.REACT_APP_APIURL + 'countries';

        fetch(url, {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
        }).then(response => {
            return response.json();
        }).then(result => {
            setCountries(result.data.map(country => ({
                ...country,
                value: country.id,
                label: country.name
            })));
        });
    }

    function handleSelectCountry(selectedCountry) {
        setCountry(selectedCountry);
        onChange(selectedCountry);
    }

    return (
        <AvGroup>
            <AvSelect
                name="country"
                value={country}
                options={countries}
                helpMessage="Select a country."
                validate={{required: {value: required}}}
                onChange={(country) => {
                    handleSelectCountry(country)
                }}
                raw={true}
            />
            <AvFeedback>Please choose a valid option.</AvFeedback>
        </AvGroup>
    );
}

export default ListCountries;
