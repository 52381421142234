import React from 'react';
import {Link} from "react-router-dom";
import {Row, Col, BreadcrumbItem} from "reactstrap";

const Breadcrumb = (props) => {
    return (
        <Row>
            <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <BreadcrumbItem>
                                <Link to={props.parent ? props.parent : '#'}>{props.title}</Link>
                            </BreadcrumbItem>
                            {props.breadcrumbItem &&
                            <BreadcrumbItem active>
                                <Link to="#">{props.breadcrumbItem}</Link>
                            </BreadcrumbItem>
                            }
                        </ol>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default Breadcrumb;
