import React from "react";
import {AvRadio, AvRadioGroup} from "availity-reactstrap-validation";
import {Label} from "reactstrap";

const GtxServices = (props) => {

    const selectedPrinter = props.selectedPrinter;

    return (
        <React.Fragment>
            <Label htmlFor="service_type" className="col-form-label">Choose a
                service *</Label>
            <AvRadioGroup
                name="service_type"
                required
                validate={{required: {value: true}}}
                errorMessage="Please choose a valid option."
            >
                {
                    !selectedPrinter || (selectedPrinter && selectedPrinter.services.find(service => ["25k", "50k", "75k", "100k"].includes(service.service_type))) ?
                        <div
                            className={"service-option mb-2 form-control is-disabled"}>
                            <AvRadio customInput label="25k Service" value="25k" disabled={true}/>
                            <p className="mb-0 pl-4">GTX422 PREVENT. MAINTENANCE KIT<br/><span
                                className="text-yellow">N400014700</span></p>
                        </div> :
                        <div
                            className={"service-option mb-2 form-control"}>
                            <AvRadio customInput label="25k Service" value="25k"/>
                            <p className="mb-0 pl-4">GTX422 PREVENT. MAINTENANCE KIT<br/><span
                                className="text-yellow">N400014700</span></p>
                        </div>
                }
                {
                    !selectedPrinter || (selectedPrinter && selectedPrinter.services.find(service => ["50k", "75k", "100k"].includes(service.service_type))) ?
                        <div
                            className={"service-option mb-2 form-control is-disabled"}>
                            <AvRadio customInput label="50k Service" value="50k" disabled={true}/>
                            <p className="mb-0 pl-4">GTX422 PREVENT. MAINTENANCE KIT<br/><span
                                className="text-yellow">N400014700</span></p>
                        </div> :
                        <div
                            className={"service-option mb-2 form-control"}>
                            <AvRadio customInput label="50k Service" value="50k"/>
                            <p className="mb-0 pl-4">GTX422 PREVENT. MAINTENANCE KIT<br/><span
                                className="text-yellow">N400014700</span></p>
                        </div>
                }
                {
                    !selectedPrinter || (selectedPrinter && selectedPrinter.services.find(service => ["75k", "100k"].includes(service.service_type))) ?
                        <div
                            className={"service-option mb-2 form-control is-disabled"}>
                            <AvRadio customInput label="75k Service" value="75k" disabled={true}/>
                            <p className="mb-0 pl-4">GTX422 PREVENT. MAINTENANCE KIT<br/><span
                                className="text-yellow">N400014700</span></p>
                        </div> :
                        <div
                            className={"service-option mb-2 form-control"}>
                            <AvRadio customInput label="75k Service" value="75k"/>
                            <p className="mb-0 pl-4">GTX422 PREVENT. MAINTENANCE KIT<br/><span
                                className="text-yellow">N400014700</span></p>
                        </div>
                }
                {
                    !selectedPrinter || (selectedPrinter && selectedPrinter.services.find(service => ["100k"].includes(service.service_type))) ?
                        <div
                            className={"service-option mb-2 form-control is-disabled"}>
                            <AvRadio customInput label="100k Service" value="100k" disabled={true}/>
                            <p className="mb-0 pl-4">GTX422 PREVENT. MAINTENANCE KIT<br/><span
                                className="text-yellow">N400014700</span></p>
                        </div> :
                        <div
                            className={"service-option mb-2 form-control"}>
                            <AvRadio customInput label="100k Service" value="100k"/>
                            <p className="mb-0 pl-4">GTX422 PREVENT. MAINTENANCE KIT<br/><span
                                className="text-yellow">N400014700</span></p>
                        </div>
                }
            </AvRadioGroup>
        </React.Fragment>
    );
}

export default GtxServices;
