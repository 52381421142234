import React, {Component} from "react";

import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import {getLoggedInUser} from "../../helpers/fakebackend_helper";
import {renewUserToken} from "../../api/auth";


class SessionTimeout extends Component {
    constructor(props) {
        super(props);
        this.state = {timeralert: null, timerswitch: false, seconds: 0};
        this.tick = this.tick.bind(this);
    }

    tick() {
        this.interval = setInterval(() => {
            this.setState(prevState => ({seconds: prevState.seconds + 1}));
        }, 1000);
    }

    componentDidMount() {
        this.main_function();
    }

    hideAlert() {
        localStorage.removeItem("authUser");
        window.location = "/login";
    }

    confirmAlert() {

        renewUserToken().then(response => {
            localStorage.setItem("authUser", JSON.stringify(response));
        });

        this.setState(state => ({seconds: 0, timeralert: null}))
    }

    main_function() {
        // this.tick();
        const nextmsg = () => (
            <SweetAlert
                showCancel
                confirmBtnText="Stay Connected"
                cancelBtnText="Logout"
                confirmBtnBsStyle="yellow"
                cancelBtnBsStyle="danger"
                title="Your Session is About to Expire!"
                onCancel={() => this.hideAlert()}
                onConfirm={() => this.confirmAlert()}
            >
                Redirecting in {this.state.seconds < 10 ? 10 - this.state.seconds : 0}s seconds.<br></br>
            </SweetAlert>
        );

        const authUser = getLoggedInUser();

        this.interval = setInterval(() => {

            // 60 = 1 Minute left till session is invalid
            if (authUser.expires_in - this.state.seconds < 60) {
                this.setState({timeralert: nextmsg()});

                if(this.state.seconds === 10){
                    this.hideAlert();
                }
            }

            this.setState(prevState => ({seconds: prevState.seconds + 1}));
        }, 1000);
    }

    render() {
        return (
            <React.Fragment>
                {this.state.timeralert}
            </React.Fragment>
        );
    }
}

export default SessionTimeout;
