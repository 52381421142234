import React, { FC } from 'react';
import {
    Card,
    CardBody,
    Media,
} from "reactstrap";
import { LoadingIndicator } from '../LoadingIndicator';

interface InfoCardProps {
    title: string,
    value: string|number,
    isLoading?: boolean,
}

export const InfoCard: FC<InfoCardProps> = (
    {
        title,
        value,
        isLoading
    }) =>
    (
        <Card className="mini-stats-wid full-height">
            <CardBody>
                <Media>
                    <Media body>
                        <p className="card-title">
                            {title}
                        </p>
                        <h4 className="mb-0">{!isLoading ? value : <LoadingIndicator />}</h4>
                    </Media>
                    <div
                        className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                         <span className="avatar-title">
                              <i className={"bx bx-copy-alt font-size-24"}/>
                         </span>
                    </div>
                </Media>
            </CardBody>
        </Card>
    );
