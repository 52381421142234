import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import "./datatables.scss";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
    getDealers
} from '../../store/actions';
import { Link } from "react-router-dom";
import { getLoggedInUser } from "../../helpers/fakebackend_helper";
import { ROLE, RoleNameMap } from '../../components/Role/constants';
import { createLoadingSelector } from '../../selectors/loading';
import { GET_DEALERS } from '../../store/dealers/actionTypes';
import { DealerList } from '../../components/Dealer/DealerList';
import { getDealersSelector } from '../../selectors/dealer';

const DealersOverview: FC = () => {
    const allDealers = useSelector(getDealersSelector);
    const dispatch = useDispatch();

    const authUser = getLoggedInUser();
    const userRole = RoleNameMap.get(authUser.user.role) || ROLE.NONE;

    const [dealers, setDealers] = useState<OverviewDealer[]>(allDealers);
    const dealersLoadingSelector = useSelector(createLoadingSelector([GET_DEALERS]));

    const [dealersLoading, setDealersLoading] = useState(true);

    useEffect(() => {
        dispatch(getDealers());
    }, [dispatch]);

    // Could be done in the other useEffect, but when Filters are used separate are needed
    useEffect(() => {
        setDealers(allDealers);
    }, [allDealers]);

    useEffect(() => {
        setDealersLoading(dealersLoadingSelector);
    }, [dealersLoadingSelector]);

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Dealers" breadcrumbItem="Overview"/>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                {[ROLE.BROTHER_MANAGER, ROLE.BROTHER_STAFF, ROLE.DEALER_ENGINEER].includes(userRole) &&
                                <div className="text-sm-right">
                                    <Link to={{
                                        pathname: "/add-dealer",
                                    }}>
                                        <Button
                                            type="button"
                                            color="yellow"
                                            className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                        >
                                            <i className="mdi mdi-plus mr-1"/>
                                            Add New Dealer
                                        </Button>
                                    </Link>
                                </div>
                                }
                                <DealerList
                                    dealers={dealers}
                                    isLoading={dealersLoading}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default DealersOverview;
