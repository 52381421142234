import {
	GET_CUSTOMERS_ERROR,
	GET_CUSTOMERS_REQUEST,
	GET_CUSTOMERS_SUCCESS,
} from './actionTypes';

export const getCustomers = () => ({
	type: GET_CUSTOMERS_REQUEST
});

export const getCustomersSuccess = (data) => {
	return{
		type: GET_CUSTOMERS_SUCCESS,
		payload: data
	}
};

export const getCustomersError = (error) => {
	return{
		type: GET_CUSTOMERS_ERROR,
		payload: error
	}
};
