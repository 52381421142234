import {
	GET_OVERTAKES_ERROR,
	GET_OVERTAKES_REQUEST,
	GET_OVERTAKES_SUCCESS
} from "./actionTypes";

export const getOvertakes = () => ({
	type: GET_OVERTAKES_REQUEST
});

export const getOvertakesSuccess = (data) => {
	return{
		type: GET_OVERTAKES_SUCCESS,
		payload: data
	}
};

export const getOvertakesError = (error) => {
	return{
		type: GET_OVERTAKES_ERROR,
		payload: error
	}
};
